import { ServicesElems as S } from './constants';

export const MALYAR_SHUT = [
  {
    type: S.withImg,
    img: 'malyarno-shut-1.jpg'
  },
  {
    type: S.withLine,
    text:
      'Во время малярно-штукатурных работ происходит устранение разных дефектов на потолке и стенах, а также производится подготовка поверхностей для финишной отделки (нанесению фактурной штукатурки, поклейке обоев, покраске).'
  },
  {
    type: S.withTitle,
    text:
      'Главная задача маляра-штукатура — провести качественное выравнивание всех поверхностей помещения, а также идеально подготовить эти поверхности к финальной отделке.'
  },
  {
    type: S.withList,
    title: 'Комплекс малярно-штукатурных работ включает в себя:',
    list: [
      'Штукатурку потолка и стен. Штукатурка необходима для заделки швов, штроб, выравнивания рабочей плоскости и т.д.',
      'Шпатлевание потолка и стен. Делается для устранения дефектов, неровностей, шероховатости, для придания поверхностям ровного состояния. Каждый слой шпатлевки после полного высыхания зашлифовывается вручную или при помощи специальных инструментов.',
      'Грунтование. Перед тем как начать штукатурку, шпатлевание или перед покраской, поверхность грунтуется специальным составом. Грунтовка образует на поверхности защитный слой, обеспечивая качественную укладываемость красок. Грунтование необходимо для того, чтобы окрашиваемая поверхность имела возможность одинаково впитывать лакокрасочные составы.',
      'Нанесение декоративной штукатурки, покраска, поклейка обоев.'
    ]
  },
  {
    type: S.withTitle,
    title: 'ШТУКАТУРКА'
  },
  {
    type: S.withList,
    title: 'Все штукатурки можно разделить на три вида:',
    list: [
      'Простые (выравнивание поверхностей).',
      'Специальные (специальные составы с добавками, чтобы улучшить отдельные характеристики).',
      'Декоративные (финишные покрытия, которые не требуют дополнительной обработки после нанесения).'
    ]
  },
  {
    type: S.withTitle,
    text: `<p>Внешняя штукатурка стен в помещении не требует, чтобы смесь была безопасной и идеально разглаживалась. При наружной штукатурке состав должен быть устойчивым к неблагоприятным факторам окружающей среды.</p><p>Штукатурные работы в Минске нашими специалистами проводятся 2-мя способами: штукатурка по маякам и без маяков.</p><p>При штукатурке без маяков предполагается нанесение и выравнивание состава шпателем, правилом или полутерком. Этот вариант используется, если на поверхности есть небольшие перепады (2-3 см). Штукатурка по маякам является более точным и надежным методом. При его выполнении на поверхности заранее задается плоскость из специальных профилей.</p><p>Определить перепады можно с помощью строительного уровня или отвеса, после чего выбрать, какая из технологий штукатурки стен является оптимальной.</p><p>Если Вам нужна штукатурка стен под ключ, наши квалифицированные специалисты помогут Вам в этом и оштукатурят стены квартиры или дома быстро и качественно.</p>`
  },
  {
    type: S.withImg,
    img: 'malyarno-shut-2.jpg'
  },
  {
    type: S.withTitle,
    title: 'ШПАТЛЕВАНИЕ ПОТОЛКА И СТЕН',
    text:
      'Шпатлевку используют для косметических целей. Ее наносят тонким слоем (1,5 см). При помощи шпатлевания можно устранить дефекты и неровности на потолке и стенах, а также сделать идеально ровной основу, перед нанесением последнего декоративного слоя.'
  },
  {
    type: S.withList,
    text: 'Существуют такие виды шпатлевки как:',
    list: [
      'Цементная — в ее составе два главных элемента: песок и цемент. Этот вид шпатлевки обладает хорошей водостойкостью. Цементная шпатлевка используется во время отделки наружных и внутренних стен.',
      'Гипсовая — этот вид шпатлевки применяется для отделки внутренних помещений, в которых нет влажности, потому что гипс приходит в негодность во влажном помещении',
      'Водостойкая — используется во время отделки бетонных, цементных и оштукатуренных поверхностей. Шпатлевка имеет хорошую влагоустойчивость.',
      'Акриловая — в состав шпатлевки входят акриловые смолы, вода и мел. Данный вид шпатлевки используется во время внешних и внутренних строительных работ.',
      'Полимерная — этот вид шпатлевки применяется во время финальной отделки поверхностей. С ее помощью заделываются трещины, щели и стыки.',
      'Универсальная — эта шпатлевка обладает высокой прочностью. Материал шлифуется легко. Стены после шпатлевания становятся ровными и белыми.',
      'Финишная — шпатлевка наносится тонким слоем и применяется для финишной отделки.'
    ]
  },
  {
    type: S.withImg,
    img: 'malyarno-shut-3.jpg'
  },
  {
    type: S.withTitle,
    title: 'ГРУНТОВАНИЕ',
    text:
      'Грунтовка — это строительная смесь, которая используется для проведения подготовительной обработки основания. За счет ее нанесения удается достигнуть максимального сцепления материала и основания.'
  },
  {
    type: S.withList,
    title: 'Плюсы обработки грунтовкой:',
    list: [
      'краска (или другое вещество) наносится легче;',
      'грунтование придает крепости и прочности поверхности;',
      'дает возможность получить идеально гладкую поверхность'
    ]
  },
  {
    type: S.withImg,
    img: 'malyarno-shut-4.jpg'
  }
];
