import { ServicesElems as S } from './constants';

export const UKLADKA_PLITKI = [
  {
    type: S.withImg,
    img: 'plitka-1.jpeg'
  },
  {
    type: S.withLine,
    text:
      'Плитка — это наиболее практичный вариант для отделки полов в: туалете, ванной комнате, кухне, коридоре и в других местах, которые наиболее подвержены влажности. Плитка является экологически безопасным и экономически эффективным материалом напольного покрытия.'
  },
  {
    type: S.withList,
    title: 'Существует 2 основных вида этого материала:',
    list: [
      'Плитка из натуральных компонентов,',
      'Композитная плитка (искусственная). '
    ]
  },
  {
    type: S.withList,
    title:
      'К первой категории относится та продукция, которую добывают открытым/закрытым способом и распиливают на элементы, доводя то товарного вида. В качестве материала для этого вида плитки выступают:',
    list: [
      'оникс;',
      'песчаник;',
      'жадеита;',
      'мрамор; ',
      'вулканический туф;',
      'мрамор;',
      'травертина;',
      'сланцы;',
      'гранит и др.'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Одним из наиболее древних отделочных материалов являются пластины из камня. Их строгая красота и уникальная текстура выглядят привлекательно и статусно. Плитка производится на специальном оборудовании, при помощи которого заготовки доводят до нужных размеров и вида. '
  },
  {
    type: S.withImg,
    img: 'plitka-2.jpg'
  },
  {
    type: S.withList,
    title:
      'Ко второй категории относятся изделия, в состав которых входит смесь нерудных компонентов:',
    list: [
      'синтетический гранит;',
      'кафель;',
      'гипсовая плитка;',
      'керамогранит;',
      'искусственный мрамор;',
      'терракота;',
      'терраццо;',
      'полимербетонная и бетонная плитка.'
    ]
  },
  {
    type: S.withImg,
    img: 'plitka-3.jpeg'
  },
  {
    type: S.withTitle,
    title: 'КЕРАМИЧЕСКАЯ ПЛИТКА',
    text:
      '<p>Наибольший ассортимент плитки приходится на керамическую плитку, а также ее вариации. Это совсем не удивительно, так как керамическая плитка долговечна, прочна, а также открывает широкий спектр возможностей в дизайне интерьера.</p> <p>Керамическая плитка — это смесь минералов, песка и глины, которая обожжена и покрыта глазурью. Глазурь дает возможность получить плитку с любой фактурой, текстурой и цветом, с любым орнаментом и рисунком, матовую и глянцевую. </p>'
  },
  {
    type: S.withList,
    title: 'Основные керамические облицовочные материалы:',
    list: [
      'Кафель — обычный кафель делают из смеси белых или красных каолинов, известняка, пегматитов или др. нерудных компонентов.',
      'Керамогранит — производится из высшего сорта белой глины, за счет чего имеет прочную структуру.',
      'Мозаика — материал является аналогом предыдущего, однако производится не только лишь из керамической смеси, а и из металла, стекла и древесины.',
      'Майолика — художественная майолика (итальянская плитка для отделки) или изразцы (российский аналог), производится из цветной глины. Благодаря специальной технологии производства имеет крупнопористую структуру, за счет чего на нее хорошо ложится глазурь.',
      'Терракота из красных сортов глины — это красивая и качественная продукция. Часто может выглядеть как покрытый узорами камень, состаренный кирпич и т.д.',
      'Клинкерная плитка. У этой плитки минимальный коэффициент теплопроводности, поэтому она часто используется для декоративной отделки внутренних стен и облицовки фасадов.'
    ]
  },
  {
    type: S.withImg,
    img: 'plitka-4.jpeg'
  },
  {
    type: S.withList,
    title: 'По формату бывают:',
    list: [
      'Мелкая плитка мозаичного типа.',
      'Стандартная плитка небольших размеров.',
      'Большая плитка, ширина которой может достигать трех метров.'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Плитка мозаичного типа очень трудоемка в монтаже, а последняя требует максимально аккуратности и осторожности во время укладки. Самыми удобными в укладке и использовании являются плитки среднего размера.'
  },
  {
    type: S.withImg,
    img: 'plitka-5.jpg'
  },
  {
    type: S.withImg,
    img: 'plitka-6.jpeg'
  },
  {
    type: S.withTitle,
    text:
      '<p>Если Вас интересует укладка плитки под ключ, наши квалифицированные специалисты помогут Вам в этом и произведут все необходимые работы быстро и качественно. </p><p>Приводим пример еще нескольких объектов, на которых мы укладывали плитку:</p>'
  },
  {
    type: S.withImg,
    img: 'plitka-7.jpg'
  },
  {
    type: S.withImg,
    img: 'plitka-8.jpg'
  },
  {
    type: S.withImg,
    img: 'plitka-9.jpg'
  },
  {
    type: S.withImg,
    img: 'plitka-10.jpg'
  },
  {
    type: S.withImg,
    img: 'plitka-11.jpg'
  },
  {
    type: S.withImg,
    img: 'plitka-12.jpg'
  }
];
