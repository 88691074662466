import React, { Component } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import Main from './pages/Main';
import PricesPage from './pages/PricesPage';
import ServicesPage from './pages/ServicesPage';
import AboutPage from './pages/AboutPage';
import Projects from './pages/Projects';
import IdeasPage from './pages/IdeasPage';
import IteasPageItem from './pages/IteasPageItem';
import ServicesPageItem from './pages/ServicesPageItem';
import ErrorPage from './pages/ErrorPage';
import ModalMobileNav from './containers/ModalMobileNav';
import { getInsagramFotos } from './actions.js';

export const R = {
  prices: '/stoimost-remonta',
  services: '/uslugi',
  about: '/o-kompanii',
  ideas: '/poleznye-stati',
  projects: '/realizovannye-proekty',
  promotions: '#akcii'
};

class Routes extends Component {
  componentDidMount() {
    fetch(
      `https://api.instagram.com/v1/users/self/media/recent/?access_token=13512037182.2983e42.ef22f2452b1c4415a5788200e37d8ab9`
    )
      .then(response => response.json())
      .then(instagram => this.props.getInsagramFotos(instagram));
  }

  render() {
    return (
      <Router>
        <ModalMobileNav />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path={R.prices} exact component={PricesPage} />
          <Route path={R.services} exact component={ServicesPage} />
          <Route path={R.about} exact component={AboutPage} />
          <Route path={R.projects} exact component={Projects} />
          <Route path={R.ideas} exact component={IdeasPage} />
          <Route
            exact
            path={`${R.ideas}/:id`}
            render={({ match }) => {
              const { id } = match.params;
              return <IteasPageItem id={id} />;
            }}
          />
            <Route exact path='/uslugi/remont-kottedzhei'>
                <Redirect to={`${R.services}`} />
            </Route>
            <Route exact path='/uslugi/remont-ofisov'>
                <Redirect to={`${R.services}`} />
            </Route>
            <Route exact path='/uslugi/remont-kvartir'>
                <Redirect to={`${R.services}`} />
            </Route>
            <Route exact path='/uslugi/individualnyi-dizain-proekt'>
                <Redirect to={`${R.services}`} />
            </Route>
            <Route
            exact
            path={`${R.services}/:id`}
            render={({ match }) => {
              const { id } = match.params;
              return <ServicesPageItem id={id} />;
            }}
          />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    );
  }
}

const mapDispachToProps = dispatch => ({
  getInsagramFotos: array => {
    dispatch(getInsagramFotos(array));
  }
});

export default connect(
  null,
  mapDispachToProps
)(Routes);
