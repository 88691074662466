import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PageTitle = props => {
  const { title, addTitle, paragraf, id } = props;
  return (
    <section className="page-title" id={id || null}>
      <Container>
        <Row>
          <Col>
            <h2
              className="text-blue f-w-700 h2-small text-center pt-3  mb-0"
              style={paragraf ? { paddingBottom: 5 } : { paddingBottom: 15 }}
            >
              {title}
              {addTitle && <span className="f-w-600"> {addTitle}</span>}
            </h2>
            {paragraf ? (
              <p className="text-center text-gray" style={{ marginBottom: 25 }}>
                {paragraf}
              </p>
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PageTitle;
