import { ServicesElems as S } from './constants';
export const STENA = [
  {
    type: S.withImg,
    img: 'stena-1.jpg'
  },
  {
    type: S.withLine,
    text:
      'Для внутренней отделки стен применяется большое разнообразие материалов, которые позволяют каждому создать свой индивидуальный, неповторимый интерьер. Рассмотрим наиболее популярные разновидности материалов для отделки, которые применяются для декорирования.'
  },
  {
    type: S.withTitle,
    title: 'ОБОИ'
  },
  {
    type: S.withList,
    title:
      'Обои — это распространённый и доступный вариант отделки. Обои бывают:',
    list: [
      'бумажные;',
      'акриловые;',
      'фотообои;',
      'флизелиновые;',
      'виниловые;',
      'стеклообои;',
      'металлизированные;',
      'жидкие;',
      'пробковые.'
    ]
  },
  {
    type: S.withImg,
    img: 'stena-2.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЕКОРАТИВНЫЙ КИРПИЧ / КАМЕНЬ',
    text:
      'Очень эффектно в отделке стен смотрится кирпич, камень (натуральный, искусственный). Декоративный камень производится из глины, цемента, гипса и др. Преимуществом данного варианта отделки является разнообразие, благодаря которому могут разгуляться даже наиболее смелые фантазии дизайнеров.'
  },
  {
    type: S.withImg,
    img: 'stena-3.jpeg'
  },
  {
    type: S.withImg,
    img: 'stena-4.jpeg'
  },
  {
    type: S.withImg,
    img: 'stena-5.jpg'
  },
  {
    type: S.withImg,
    img: 'stena-6.jpg'
  },
  {
    type: S.withTitle,
    title: 'КРАСКА'
  },
  {
    type: S.withList,
    title:
      'Современные лакокрасочные материалы существенно расширяют возможности дизайнеров при создании ими необычных проектов. Среди основных преимуществ покраски можно отметить:',
    list: [
      'возможность потратив относительно небольшую сумму денег обновить интерьер своего дома/квартиры;',
      'практичность в плане гигиены и чистоты — некоторые виды краски отлично переносят влажную уборку.'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Широкая цветовая гамма красок способствует созданию оригинальных интерьерных решений в любом стиле.'
  },
  {
    type: S.withTitle,
    text:
      'Очень эффектно смотрится художественная роспись стен (могут применяться разные техники)'
  },
  {
    type: S.withImg,
    img: 'stena-7.jpg'
  },
  {
    type: S.withImg,
    img: 'stena-8.jpg'
  },
  {
    type: S.withImg,
    img: 'stena-9.jpg'
  },
  {
    type: S.withTitle,
    title: 'КЕРАМИЧЕСКАЯ ПЛИТКА',
    text:
      'Плитка — это материал, который уже очень давно используется людьми в отделке своих помещений: проходит время, меняются тренды, но помещения как и раньше отделывают кафелем. Такое доверие этот качественный отделочный материал заслужил благодаря таким своим качествам как экологичность, влагостойкость, нетребовательность, гигиеничность, устойчивость перед выгоранием и истиранием, а также неподверженность бактериям и грибкам. А выбор цветов, форматов и фактур, которые имитируют внешний вид благородных камней, кожи крокодила, минералов, и др., делает его очень популярным среди дизайнеров и позволяет создавать интересные интерьеры.'
  },
  {
    type: S.withImg,
    img: 'stena-10.jpeg'
  },
  {
    type: S.withImg,
    img: 'stena-11.jpeg'
  },
  {
    type: S.withTitle,
    title: 'КЕРАМОГРАНИТ',
    text:
      'Для плитки из керамогранита характерны стойкость к низким температурам и сколам, прочность, минимальная степень водопоглощения и экологичность. Первый пункт делает керамогранит одним из лидеров отделки, если отделка планируется на веранде, балконе, лоджии или террасе. Касаемо ассортимента фактур и цветовой гаммы плитки из керамогранита, то он удовлетворит любой вкус. '
  },
  {
    type: S.withImg,
    img: 'stena-12.jpg'
  },
  {
    type: S.withTitle,
    title: 'МДФ ПАНЕЛИ',
    text:
      'Это современный вариант древесно-стружечных плит, больше известных как ДСП. Однако, в процессе производства МДФ панелей не используется фенол, а это делает данный материал более экологичным. МДФ панели легко крепятся, стойко противостоят плесени и грибку, прочны, водостойки, а еще у них высокий коэффициент теплопроводности.'
  },
  {
    type: S.withImg,
    img: 'stena-13.jpg'
  },
  {
    type: S.withTitle,
    title: '3D-ПАНЕЛИ',
    text:
      'Большинство 3-х мерных рельефных изделий сделаны из гипса, хотя также они часто производятся из таких материалов как алюминий, МДФ, стекло, бамбук, пластик, тростник и др. Большой выбор образцов 3-D панелей дает возможность гармонично вписать такой тип отделки в интерьер.'
  },
  {
    type: S.withImg,
    img: 'stena-14.jpg'
  },
  {
    type: S.withTitle,
    title: 'ГИПСОКАРТОН',
    text:
      'Гипсокартон — это хорошая альтернатива штукатурке. Из ГК можно создать подпотолочные, навесные системы, а еще с его помощью можно «спрятать» разные коммуникации.'
  },
  {
    type: S.withImg,
    img: 'stena-15.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЕРЕВО',
    text:
      'Популярность использования в отделке натуральных материалов, связано с их экологичностью и практичностью. Самым распространенным материалом данной категории является дерево, которое отличается хорошей шумо- и теплоизоляцией, а еще интерьер с применением дерева становится теплым и уютным.'
  },
  {
    type: S.withImg,
    img: 'stena-16.jpg'
  },
  {
    type: S.withImg,
    img: 'stena-17.jpg'
  },
  {
    type: S.withTitle,
    title: 'ЗЕРКАЛО',
    text:
      'Дизайнеры часто используют специально обработанные панели, с помощью которых можно выделить каждое зеркало, максимально сгладив акцент на отражении. Аналогичные способы размещения применяются и при создании зеркальных настенных панно. Применение зеркал в интерьере зрительно увеличивает комнату, наполняет светом.'
  },
  {
    type: S.withImg,
    img: 'stena-18.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЕКОРАТИВНАЯ ШТУКАТУРКА',
    text:
      'Это раствор, используемый для финишной отделки. Главная цель использования — декорирование поверхности. В настоящее время есть много видов декоративной штукатурки.'
  },
  {
    type: S.withImg,
    img: 'stena-19.jpeg'
  },
  {
    type: S.withImg,
    img: 'stena-20.jpg'
  },
  {
    type: S.withTitle,
    title: 'ЛИНКРУСТ',
    text:
      'Это рулоны сформированные из древесной муки и смеси из масла льна, воска и канифоли. Поверхность линкруста является узорной и рельефной. Интерьеры с линкрустом смотрятся богато и респектабельно.'
  },
  {
    type: S.withImg,
    img: 'stena-21.jpg'
  }
];
