export const PRICES_ITEMS = [
  {
    id: 0,
    title: 'Косметический',
    price: 25,
    route: 'сosmetic',
    mainImg: 'kosmeticheskiy_remont_kvartiry_skolko_stoit.jpeg',
    addImg: 'prices-standart-add-img',
    text:
      'Косметический ремонт – это обновление помещения. Он является самым простым и наименее затратным, обычно включающим в себя выполнение отделочных работ, не требующих коренного изменения дизайна интерьера, планировки и замены основных коммуникаций.'
  },
  {
    id: 1,
    title: 'Капитальный',
    price: 70,
    route: 'capital',
    mainImg: 'kapitalniy_remont_kvartiry_skolko_stoit.jpeg',
    addImg: 'prices-designer-add-img',
    text:
      'Капитальный ремонт квартиры включает в себя все виды ремонтно-отделочных работ, в том числе реконструкцию и перепланировку квартиры, а также замену инженерных коммуникаций. Этот вид ремонта позволяет полностью преобразовать помещение и сделать его максимально комфортным.'
  },
  {
    id: 2,
    title: 'Дизайнерский',
    price: 125,
    route: 'authors',
    mainImg: 'dizainerskiy_remont_kvartir_skolko_stoit.jpg',
    addImg: 'prices-premium-add-img',
    text:
      'Дизайнерский ремонт - это чудо дизайнерской мысли. Он включает в себя полный цикл ремонтно-отделочных работ, замену либо ремонт инженерных коммуникаций, с возможной перепланировкой. Помимо отделочников в такой работе могут принимать участие такие мастера как: мастера по металлу, витражисты, скульпторы, художники…'
  }
];

export const SERVICES_MAIN_TABS = [
  {
    id: 0,
    activeKey: 'acceptanceOfApartments',
    title: 'ПРИЕМКА',
    text: 'квартиры',
    img: 'priemka_kvartiry.svg'
  },
  {
    id: 1,
    activeKey: 'renovationOfApartments',
    title: 'Ремонт ',
    text: 'КВАРТИР',
    img: 'remont_kvartir.svg'
  },
  {
    id: 2,
    activeKey: 'renovationOfСottage',
    title: 'Ремонт ',
    text: 'КОТТЕДЖЕЙ',
    img: 'remont_kottedzei.svg'
  },
  {
    id: 3,
    activeKey: 'acceptanceOfOffice',
    title: 'Ремонт ',
    text: 'ОФИСОВ',
    img: 'remont_ofisov.svg'
  }
];

export const SERVICES_MAIN_TABS_CONTENT = [
  {
    eventKey: 'acceptanceOfApartments',
    text:
      '<p class="mb-1">Застройщик уже пригласил Вас на первичный осмотр Вашей новой квартиры в новостройке? Наши специалисты, зная все тонкости, помогут Вам принять квартиру у застройщика БЕСПЛАТНО.</p><p class="mb-1">Самое важное на данном этапе определить все строительные дефекты, потому что их должен устранить застройщик за свой счет.</p><p class="mb-2">В ходе <b>приемки квартиры</b> в новостройке мы проверяем: </p>',
    tabImage: 'priemka_kvartir.jpeg',
    tabContent: [
      {
        title: 'железобетонные и каменные конструкции ',
        text:
          '<ol><li>наличие вертикального и горизонтального отклонения плоскостей стен</li><li>устройство деформационных швов</li><li>наличие или отсутствие трещин</li></ol>'
      },
      {
        title: 'оконные и дверные рамы, створки, стеклопакеты',
        text:
          '<ol><li>проверка на горизонтальные и вертикальные отклонения оконных и дверных конструкций, правильность их работы и установки</li><li>проверка целостности запорных устройств, уплотнительных и герметизирующих элементов, сливы</li></ol>'
      },
      {
        title: 'водоснабжение, водоотведение и отопление',
        text:
          '<ol><li>проверка на наличие повреждений и течи в местах соединения трубопроводов</li><li>целостность и правильность установки счетчиков расхода воды, высоты монтажа канализационного вывода</li><li>наличие противопожарных муфт</li></ol>'
      },
      {
        title: 'вентиляция и вытяжка ',
        text: '<p>выявляются отсутствие тяги или обратная тяга в вентиляции</p>'
      },
      {
        title: 'электрическое и силовое оборудование ',
        text:
          '<ol><li>проверка наличия напряжения во всех электроточках</li><li>выявление скрытых обрывов и повреждений электропроводки</li></ol>'
      }
    ]
  },
  {
    eventKey: 'renovationOfApartments',
    secondaryTabs: [
      {
        id: 0,
        title: 'Косметический',
        titleEn: 'сosmetic',
        tabtext:
          'Косметический ремонт – это обновление помещения. Он является самым простым и наименее затратным, обычно включающим в себя выполнение отделочных работ, не требующих коренного изменения дизайна интерьера, планировки и замены основных коммуникаций.',
        tabContent: [
          { title: 'демонтаж', text: 'демонтаж старых покрытий' },
          {
            title: 'сантехнические работы',
            text: 'ремонт сантехнического оборудования'
          },
          {
            title: 'электромонтажные работы',
            text: 'замена светильников, розеток, выключателей'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен по плоскости (углы, линии плинтусов, наличников)</li><li>шпатлевка стен под обои и покраску</li><li>шпатлевка потолков под покраску</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол)</li><li>укладка напольных покрытий (ламината, линолеума, ковролина)</li><li>покраска стен и потолка</li><li>окрашивание труб и радиаторов отопления</li><li>оклейка обоями</li><li>монтаж плинтусов (пол)</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'kosmeticheskiy_remont_kvartiry.jpeg'
      },
      {
        id: 1,
        title: 'Капитальный',
        titleEn: 'capital',
        tabtext:
          'Капитальный ремонт квартиры включает в себя все виды ремонтно-отделочных работ, в том числе реконструкцию и перепланировку квартиры, а также замену инженерных коммуникаций.',
        tabContent: [
          {
            title: 'демонтаж',
            text:
              '<ol><li>демонтаж перегородок</li><li>демонтаж старых покрытий</li></ol>'
          },
          {
            title: 'монтаж',
            text:
              '<ol><li>монтаж перегородок</li><li>устройство дверного проема</li></ol>'
          },
          {
            title: 'сантехнические работы',
            text:
              '<ol><li>монтаж водопровода</li><li>монтаж канализации</li><li>устройство змеевика</li><li>установка инсталляции</li><li>монтаж бойлера</li><li>замена труб и радиаторов отопления</li><li>установка вентелей, смесителей</li><li>установка унитаза</li><li>подключение стиральной машины</li><li>подключение посудомойки</li><li>установка ванны</li></ol>'
          },
          {
            title: 'электромонтажные работы',
            text:
              '<ol><li>устройство электроточки</li><li>электромонтаж разветвительных коробок</li><li>монтаж электрощитка с установкой и подключением</li><li>прокладывание силового, UTP, антенного и телефонного кабеля</li><li>монтаж теплых полов</li><li>установка розеток и выключателей</li><li>установка светильников</li></ol>'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен</li><li>зашивка стен гипсокартоном</li><li>установка скрытого люка</li><li>штукатурка потолков</li><li>устройство потолков из гипсокартона</li><li>шпатлевка потолков под покраску</li><li>стяжка пола</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол)</li><li>запил углов плитки под 45 градусов</li><li>покраска стен и потолка</li><li>укладка напольных покрытий (паркетной доски, ламината, кварц винила, ковровых покрытий)</li><li>оклейка обоями</li><li>подвесные и натяжные потолки</li><li>монтаж плинтусов</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'kapitalniy_remont_kvartiry.jpeg'
      },
      {
        id: 2,
        title: 'Дизайнерский',
        titleEn: 'authors',
        tabtext:
          'Дизайнерский ремонт - это чудо дизайнерской мысли. Он включает в себя полный цикл ремонтно-отделочных работ, замену либо ремонт инженерных коммуникаций, с возможной перепланировкой. Помимо отделочников в такой работе могут принимать участие такие мастера как: мастера по металлу, витражисты, скульпторы, художники…',
        tabContent: [
          {
            title: 'демонтаж',
            text:
              '<ol><li>демонтаж перегородок</li><li>демонтаж старых покрытий</li></ol>'
          },
          {
            title: 'монтаж',
            text:
              '<ol><li>монтаж перегородок</li><li>устройство дверного проема</li><li>устройство дверного проема для нестандартных дверей</li></ol>'
          },
          {
            title: 'сантехнические работы',
            text:
              '<ol><li>монтаж водопровода</li><li>монтаж канализации</li><li>устройство змеевика</li><li>установка инсталляции</li><li>перенос счетчиков</li><li>замена труб и радиаторов отопления</li><li>установка унитаза</li><li>установка бойлера</li><li>монтаж трапа</li><li>установка вентелей, смесителей</li><li>подключение стиральной машины</li><li>подключение посудомойки</li><li>установка ванны</li></ol>'
          },
          {
            title: 'электромонтажные работы',
            text:
              '<ol><li>устройство электроточки</li><li>электромонтаж разветвительных коробок</li><li>монтаж электрощитка с установкой и подключением</li><li>прокладывание силового, UTP, антенного и телефонного кабеля</li><li>монтаж слаботочного щитка</li><li>монтаж теплых полов</li><li>установка розеток и выключателей</li><li>установка светильников</li><li>умный дом</li></ol>'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен</li><li>зашивка стен гипсокартоном</li><li>установка скрытого люка</li><li>изготовление авторских конструкций</li><li>устройство потолков любых видов</li><li>штукатурка потолков</li><li>шпатлевка потолков под покраску</li><li>стяжка пола</li><li>гидроизоляция</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол) - разные размеры и класс плитки</li><li>запил углов плитки под 45 градусов</li><li>декорирование стен всевозможными материалами и элементами</li><li>покраска стен и потолка</li><li>укладка напольных покрытий (любые варианты)</li><li>декорирование откосов (камень, дерево и т.д.)</li><li>оклейка обоями</li><li>монтаж плинтусов (пол, стены, потолок)</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'dizainerskiy_remont_kvartir.jpg'
      }
    ],
    tabImage: 'project-1-bg.jpg'
  },
  {
    eventKey: 'renovationOfСottage',
    secondaryTabs: [
      {
        id: 0,
        title: 'Косметический',
        titleEn: 'сosmetic',
        tabtext:
          'Косметические ремонтные работы предназначены для обновления обстановки в целом, без кардинальных изменений планировки дома. Этот вид ремонта позволяет существенно преобразить интерьер в короткий период, вкладывая минимум финансов.',
        tabContent: [
          { title: 'демонтаж', text: 'демонтаж старых покрытий' },
          {
            title: 'сантехнические работы',
            text: 'ремонт сантехнического оборудования'
          },
          {
            title: 'электромонтажные работы',
            text: 'замена светильников, розеток, выключателей'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен по плоскости (углы, линии плинтусов, наличников)</li><li>шпатлевка стен под обои и покраску</li><li>шпатлевка потолков под покраску</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол)</li><li>укладка напольных покрытий (ламината, линолеума, ковролина)</li><li>покраска стен и потолка</li><li>окрашивание труб и радиаторов отопления</li><li>оклейка обоями</li><li>монтаж плинтусов (пол)</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'kosmeticheskiy_remon_kottedgei.jpg'
      },
      {
        id: 1,
        title: 'Капитальный',
        titleEn: 'capital',
        tabtext:
          'Капитальный ремонт дома предусматривает его полную реконструкцию, ремонт всех инженерных коммуникаций, изменение внешнего и внутреннего вида дома.',
        tabContent: [
          {
            title: 'наружные работы',
            text:
              '<ol><li>монтаж нового фасада</li><li>замена кровли</li><li>усиление или переделка фундамента</li><li>тепло-, звуко- и гидроизоляция стен и кровли</li><li>укрепление стеновых конструкций</li><li>теплоизоляция веранд, террас и балконов</li></ol>'
          },
          {
            title: 'демонтаж',
            text:
              '<ol><li>демонтаж перегородок</li><li>демонтаж старых покрытий</li></ol>'
          },
          {
            title: 'монтаж',
            text:
              '<ol><li>монтаж перегородок</li><li>устройство дверного проема</li><li>укрепление межэтажных перекрытий</li></ol>'
          },
          {
            title: 'сантехнические работы',
            text:
              '<ol><li>монтаж водопровода</li><li>отопительная система</li><li>канализационная система</li><li>устройство змеевика</li><li>установка инсталляции</li><li>монтаж бойлера</li><li>замена труб и радиаторов отопления</li><li>установка вентелей, смесителей</li><li>установка унитаза</li><li>подключение стиральной машины</li><li>подключение посудомойки</li><li>установка ванны</li></ol>'
          },
          {
            title: 'электромонтажные работы',
            text:
              '<ol><li>устройство электроточки</li><li>электромонтаж разветвительных коробок</li><li>монтаж электрощитка с установкой и подключением</li><li>прокладывание силового, UTP, антенного и телефонного кабеля</li><li>монтаж теплых полов</li><li>установка розеток и выключателей</li><li>установка светильников</li><li>система охраны</li></ol>'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен</li><li>зашивка стен гипсокартоном</li><li>установка скрытого люка</li><li>штукатурка потолков</li><li>устройство потолков из гипсокартона</li><li>шпатлевка потолков под покраску</li><li>стяжка пола</li><li>гидроизоляция душевой кабины</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол)</li><li>запил углов плитки под 45 градусов</li><li>покраска стен и потолка</li><li>укладка напольных покрытий (паркетной доски, ламината, кварц винила, ковровых покрытий)</li><li>подвесные, натяжные, многоуровневые, комбинированные, с декоративными элементами потолки</li><li>оклейка обоями</li><li>монтаж плинтусов</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'kapitalny_remont_kottedgey.jpg'
      },
      {
        id: 2,
        title: 'Дизайнерский',
        titleEn: 'authors',
        tabtext:
          'Дизайнерский ремонт коттеджа – это прежде всего индивидуальный стиль, отражающий вкус хозяев, неповторимое чувство стиля и образа жизни. Он включает в себя полный цикл ремонтно-отелочных работ как внутри дома, так и снаружи. В ходе ремонта применяются инновационные технологии, современные материалы, воплощаются в жизнь любые дизайнерские задумки и идеи.',
        tabContent: [
          {
            title: 'наружные работы',
            text:
              '<ol><li>монтаж нового фасада</li><li>замена кровли</li><li>усиление или переделка фундамента</li><li>создание прилежащей к дому новой постройки</li><li>создание еще одного этажа, террасы</li><li>тепло-, звуко- и гидроизоляция стен и кровли</li><li>укрепление стеновых конструкций</li><li>теплоизоляция веранд, террас и балконов</li></ol>'
          },
          {
            title: 'демонтаж',
            text:
              '<ol><li>демонтаж перегородок</li><li>демонтаж старых покрытий</li></ol>'
          },
          {
            title: 'монтаж',
            text:
              '<ol><li>монтаж перегородок</li><li>устройство дверного проема</li><li>устройство дверного проема для нестандартных дверей</li><li>укрепление межэтажных перекрытий</li></ol>'
          },
          {
            title: 'сантехнические работы',
            text:
              '<ol><li>монтаж водопровода</li><li>отопительная система</li><li>канализационная система</li><li>устройство змеевика</li><li>установка инсталляции</li><li>перенос счетчиков</li><li>установка бойлера</li><li>замена труб и радиаторов отопления</li><li>установка вентелей, смесителей</li><li>установка унитаза</li><li>подключение стиральной машины</li><li>подключение посудомойки</li><li>монтаж трапа</li><li>установка ванны</li></ol>'
          },
          {
            title: 'электромонтажные работы',
            text:
              '<ol><li>устройство электроточки</li><li>электромонтаж разветвительных коробок</li><li>монтаж электрощитка с установкой и подключением</li><li>прокладывание силового, UTP, антенного и телефонного кабеля</li><li>монтаж слаботочного щитка</li><li>монтаж теплых полов</li><li>установка розеток и выключателей</li><li>установка светильников</li><li>система охраны</li><li>умный дом</li></ol>'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен</li><li>зашивка стен гипсокартоном</li><li>установка скрытого люка</li><li>изготовление авторских конструкций</li><li>устройство потолков любых видов</li><li>штукатурка потолков</li><li>шпатлевка потолков под покраску</li><li>стяжка пола</li><li>гидроизоляция</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки - разные размеры и класс плитки</li><li>запил углов плитки под 45 градусов</li><li>декорирование стен всевозможными материалами и элементами</li><li>покраска стен и потолка</li><li>укладка напольных покрытий (любые варианты)</li><li>декорирование откосов (камень, дерево и т.д.)</li><li>оклейка обоями</li><li>подвесные и натяжные потолки</li><li>монтаж плинтусов (пол, стены, потолок)</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'dizainerskiy_remont_kottedgey.jpg'
      }
    ],
    tabImage: 'project-2-bg.jpg'
  },
  {
    eventKey: 'acceptanceOfOffice',
    secondaryTabs: [
      {
        id: 0,
        title: 'Косметический',
        titleEn: 'сosmetic',
        tabtext:
          'Мы выполняем ремонт таких нежилых помещений как: офисы, кафе, рестораны, торговые объекты, административные здания, гостиницы, учреждения здравоохранения и образования, автосервисы, складские и производственные помещения и т.д. Косметический ремонт помещения включает в себя следующие работы:',
        tabContent: [
          { title: 'демонтаж', text: 'демонтаж старых покрытий' },
          {
            title: 'сантехнические работы',
            text: 'ремонт сантехнического оборудования'
          },
          {
            title: 'электромонтажные работы',
            text: 'замена светильников, розеток, выключателей'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен по плоскости (углы, линии плинтусов, наличников)</li><li>шпатлевка стен под обои и покраску</li><li>шпатлевка потолков под покраску</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки</li><li>укладка напольных покрытий (ламината, линолеума, ковролина)</li><li>покраска стен и потолка</li><li>окрашивание труб и радиаторов отопления</li><li>оклейка обоями</li><li>монтаж плинтусов (пол)</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'kosmeticheskiy_remont_magazina.jpg'
      },
      {
        id: 1,
        title: 'Капитальный',
        titleEn: 'capital',
        tabtext:
          'Мы выполняем ремонт таких нежилых помещений как: офисы, кафе, рестораны, торговые объекты, административные здания, гостиницы, учреждения здравоохранения и образования, автосервисы, складские и производственные помещения и т.д. Капитальный ремонт помещения включает в себя следующие работы:',
        tabContent: [
          {
            title: 'демонтаж',
            text:
              '<ol><li>демонтаж перегородок</li><li>демонтаж старых покрытий</li></ol>'
          },
          {
            title: 'монтаж',
            text:
              '<ol><li>монтаж перегородок</li><li>устройство дверного проема</li></ol>'
          },
          {
            title: 'сантехнические работы',
            text:
              '<ol><li>монтаж водопровода</li><li>монтаж канализации</li><li>устройство змеевика</li><li>установка инсталляции</li><li>замена труб и радиаторов отопления</li><li>установка вентелей, смесителей</li><li>установка унитаза</li></ol>'
          },
          {
            title: 'электромонтажные работы',
            text:
              '<ol><li>устройство электроточки</li><li>электромонтаж разветвительных коробок</li><li>монтаж электрощитка с установкой и подключением</li><li>прокладывание силового, UTP, антенного и телефонного кабеля</li><li>установка вентилятора</li><li>установка розеток и выключателей</li><li>установка светильников</li></ol>'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен</li><li>зашивка стен гипсокартоном</li><li>штукатурка потолков</li><li>устройство потолков из гипсокартона</li><li>шпатлевка потолков под покраску</li><li>стяжка пола</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол)</li><li>запил углов плитки под 45 градусов</li><li>покраска стен и потолка</li><li>укладка напольных покрытий (паркетной доски, ламината, кварц винила, ковровых покрытий)</li><li>оклейка обоями</li><li>подвесные и натяжные потолки</li><li>монтаж плинтусов</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'kapitalny_remont_ofisa.jpg'
      },
      {
        id: 2,
        title: 'Дизайнерский',
        titleEn: 'authors',
        tabtext:
          'Мы выполняем ремонт таких нежилых помещений как: офисы, кафе, рестораны, торговые объекты, административные здания, гостиницы, учреждения здравоохранения и образования, автосервисы, складские и производственные помещения и т.д. Дизайнерский ремонт помещения включает в себя следующие работы:',
        tabContent: [
          {
            title: 'демонтаж',
            text:
              '<ol><li>демонтаж перегородок</li><li>демонтаж старых покрытий</li></ol>'
          },
          {
            title: 'монтаж',
            text:
              '<ol><li>монтаж перегородок</li><li>устройство дверного проема</li><li>устройство дверного проема для нестандартных дверей</li></ol>'
          },
          {
            title: 'сантехнические работы',
            text:
              '<ol><li>монтаж водопровода</li><li>монтаж канализации</li><li>установка инсталляции</li><li>перенос счетчиков</li><li>замена труб и радиаторов отопления</li><li>установка унитаза</li><li>установка вентелей, смесителей</li></ol>'
          },
          {
            title: 'электромонтажные работы',
            text:
              '<ol><li>устройство электроточки</li><li>электромонтаж разветвительных коробок</li><li>можтаж электрощитка с установкой и подключением</li><li>прокладывание силового, UTP, антенного и телефонного кабеля</li><li>установка розеток и выключателей</li><li>установка светильников</li><li>умный дом</li></ol>'
          },
          {
            title: 'черновая отделка',
            text:
              '<ol><li>штукатурка стен</li><li>зашивка стен гипсокартоном</li><li>изготовление авторских конструкций</li><li>устройство потолков любых видов</li><li>штукатурка потолков</li><li>шпатлевка потолков под покраску</li><li>стяжка пола</li><li>гидроизоляция</li></ol>'
          },
          {
            title: 'чистовая отделка',
            text:
              '<ol><li>укладка плитки (стены, пол) - разные размеры и класс плитки</li><li>запил углов плитки под 45 градусов</li><li>декорирование стен всевозможными материалами и элементами</li><li>покраска стен и потолка</li><li>укладка напольных покрытий (любые варианты)</li><li>декорирование откосов (камень, дерево и т.д.)</li><li>оклейка обоями</li><li>монтаж плинтусов (пол, стены, потолок)</li></ol>'
          },
          {
            title: 'уборка объекта',
            text: '<ol><li>вынос мусора</li><li>уборка объекта</li></ol>'
          }
        ],
        tabImage: 'dizainerskiy_remont_kafe.jpg'
      }
    ],
    tabImage: 'project-3-bg.jpg'
  }
];

export const MAIN_PAGE_DROPDOWNS = [
  {
    id: 0,
    title: 'Официальный договор. Гарантия',
    text:
      'Перед началом ремонта заключается официальный договор. Гарантия 3 года. В течение гарантийного срока мы бесплатно исправим любые гарантийные недостатки ремонта',
    img: 'dogovor.svg',
    add_img: 'dogovor.jpg'
  },
  {
    id: 1,
    title: 'Профессиональный ремонт',
    text:
      '<p class="mb-1">Одна из ключевых особенностей нашей компании – эффективный строительный менеджмент:</p> <ol><li>Четкая организация рабочего процесса</li><li>Профессиональные строители</li><li>Выполнение работ в строгом соответствии с таймингом работ</li><li>Использование передовых технологий и профессионального инструмента</li><li>Соблюдение необходимых последовательностей ремонтных работ</li><li>Обучение и профессиональное развитие работников</li><li>Финансовая мотивация сотрудников на высокое качество и соблюдение сроков</li><li>Порядок на объекте</li></ol>',
    img: 'remont.svg'
  },
  {
    id: 2,
    title: 'Поэтапная оплата',
    text:
      'Оплата работ производится за каждый этап ремонта и привязана к таймингу строительных работ. Это позволяет нашим Клиентам удобно планировать финансирование ремонта.',
    img: 'oplata.svg'
  },
  {
    id: 3,
    title: 'Сроки выполнения работ согласно тайминга работ',
    text:
      '<p class="mb-1">Разработка тайминга строительных работ – обязательная часть подготовки к строительным работам на любом объекте. Он необходим для оптимальной организации рабочего процесса и согласованности действий всех участников. </p><p>Тайминг работ – это документ, в котором подробно описывается каждый этап работы и сроки его завершения. Согласованный обеими сторонами, он призван помочь студии комфортно работать, а Клиенту – получить качественный ремонт в обозначенные сроки.<p>',
    img: 'sroki.svg'
  },
  {
    id: 4,
    title: 'Фиксированная смета по честным ценам',
    text:
      '<p class="mb-1">Подписываем фиксированную смету согласно проекта и не превышаем ее. Все дополнительные работы оговариваются дополнительно и выполняются после согласования.<p class="mb-1"><p class="mb-1">Методы получения <b>приятной цены</b> для наших Клиентов:</p><ol><li>Честные расценки на ремонтно-отделочные работы</li><li>Оптимизация процесса ремонта</li><li>Гибкое формирование финальной сметы</li><li>Партнерские договоренности с поставщиками</li><li>Прозрачная отчетность, удобная оплата</li></ol>',
    img: 'smeta.svg'
  },
  {
    id: 5,
    title: 'Квалифицированные работники',
    text:
      'Наши сотрудники – профессионалы высокого уровня. Мы ответственно относимся к своей работе и гарантируем прекрасный результат: выполненный нами ремонт домов и квартир, кафе, офисов полностью соответствует дизайн-проекту и ожиданиям заказчика. Именно поэтому нас привлекают к реализации сложных дизайн-проектов известные дизайнеры и архитекторы Беларуси.',
    img: 'rabotniki.svg',
    add_img: 'otdelotniki_Minsk.jpg'
  },
  {
    id: 6,
    title: 'Тайминги поставки чистовых и черновых материалов',
    text:
      '<p class="mb-1">Тайминг поставки материалов отражает конкретные сроки, в которые поставщики и подрядчики должны доставить на объект черновые и чистовые материалы, выполнить работы (установка кондиционера и т.д.). Он разрабатывается на основании тайминга строительных работ</p>',
    img: 'taimingi.svg'
  },
  {
    id: 7,
    title: 'Приятная коммуникация',
    text:
      '<p class="mb-1">За Вами закреплен прораб, который полностью погружен в Ваш проект и всегда на связи. Делая ремонт с нами, Вы получаете:</p><ul><li>ориентированность на Клиента</li><li>открытость и доброжелательность</li><li>полное сопровождение</li><li>комплексный подход</li><li>консультации специалистов</li><li>рабочие без вредных привычек</li><li>контроль работ на каждом этапе ответственным прорабом</li></ul>',
    img: 'kommunikacia.svg'
  }
];