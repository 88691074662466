import { ServicesElems as S } from './constants';

export const OBOI = [
  {
    type: S.withImg,
    img: 'oboi-1.jpeg'
  },
  {
    type: S.withLine,
    text:
      'Обои — это пользующийся популярностью материал для отделки. Разнообразие обоев поистине поражает, они оптимально подойдут как для отделки «традиционных» простых интерьеров, так и оригинальных дизайнерских.'
  },
  {
    type: S.withTitle,
    title: 'БУМАЖНЫЕ ОБОИ',
    text:
      'Бумажные обои могут быть разнообразны от простых и бюджетных, до дизайнерских. Они экологичны и многообразны (узоры, текстуры, оттенки). '
  },
  {
    type: S.withImg,
    img: 'oboi-2.jpg'
  },
  {
    type: S.withTitle,
    title: 'ВИНИЛОВЫЕ ОБОИ',
    text:
      'Сегодня эти обои пользуются большим спросом, они могут имитировать дерево, камень, штукатурку и т.д. К преимуществам данного покрытия можно отнести высокий уровень шумоизоляции, устойчивость перед влажностью и выгоранием, а также способность скрыть шероховатости. Так как влажность не страшна для этих обоев, их смело можно клеить даже в ванных комнатах. Однако, у обоев существуют также и свои недостатки: их трудно клеить самостоятельно, они воздухонепроницаемы, их стоимость выше средней. Более дешевые образцы виниловых обоев могут неприятно пахнуть.'
  },
  {
    type: S.withImg,
    img: 'oboi-3.jpg'
  },
  {
    type: S.withTitle,
    title: 'ФЛИЗЕЛИНОВЫЕ ОБОИ',
    text:
      'На основе флизелина производятся виниловые и бумажные обои, а также обои из ткани. Это рулоны из волокон целлюлозы, которые скреплены полимерами. Преимущества: воздухопроницаемость, экологичность, большой выбор узоров и возможность перекрашивать обои, а также «маскировать» бугорки и недочеты на стенах небольших размеров.'
  },
  {
    type: S.withImg,
    img: 'oboi-4.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ФОТООБОИ',
    text:
      'Современные фотообои не подвержены выцветанию и имеют пылеотталкивающий эффект. Довольно часто сейчас фотообои производят по индивидуальному заказу – это позволяет создать интересный, индивидуальный интерьер.'
  },
  {
    type: S.withImg,
    img: 'oboi-5.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ТКАНЕВЫЕ ОБОИ',
    text:
      'Обои данного типа производятся на основе бумаги или флизелина, синтетики и бывают шелковыми, льняными, велюровыми и т.п.'
  },
  {
    type: S.withImg,
    img: 'oboi-6.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЖУТОВЫЕ ОБОИ',
    text:
      'Эти обои относятся к большому семейству оформительских материалов, которые имеют натуральное растительное покрытие. Второе их название — обои-циновки. Обои производятся на основе флизелина или бумаги. Среди преимуществ этих обоев можно отметить: стойкость к УФ и механическим нагрузкам, воздухопроницаемость и 100% натуральность. Особая узелковая структура джутовых обоев возводит их из разряда простых обоев в ранг оригинальных дизайнерских инструментов. Чаще всего их используют если необходимо придать интерьеру этнический характер. Среди «минусов» джутовых обоев — достаточно дорого стоят, нужно время от времени чистить, впитывают запахи.'
  },
  {
    type: S.withImg,
    img: 'oboi-7.jpg'
  },
  {
    type: S.withTitle,
    title: 'МЕТАЛЛИЗИРОВАННЫЕ / МЕТАЛЛИЧЕСКИЕ ОБОИ',
    text:
      'Эти обои бывают черненными, тисненными, патинированными, расписанными... За счет декоративного разнообразия, данный тип обоев — это фирменная фишка многих дизайнеров. Сверху на обоях фольга, поэтому они отличаются хорошей теплоизоляцией и износостойкостью. «Минусы» металлизированных обоев — это воздухонепроницаемость, низкая устойчивость перед механическими воздействиями, для их поклейки нужна очень ровная поверхность, высокая стоимость.'
  },
  {
    type: S.withImg,
    img: 'oboi-8.jpg'
  },
  {
    type: S.withTitle,
    title: 'ЖИДКИЕ ОБОИ',
    text:
      'Преимущества жидких обоев — это хорошая прочность, практичность, способность пропускать воздух и то, что поврежденные фрагменты обоев можно очень просто отреставрировать. Недостатки — высокая стоимость, ограниченность в выборе красок и узоров.'
  },
  {
    type: S.withImg,
    img: 'oboi-9.jpg'
  },
  {
    type: S.withTitle,
    title: 'СТЕКЛООБОИ',
    text:
      'Данный тип обоев — это очень тонкая пряжа из стекла, которая скатана в рулоны. В состав стеклообоев входит песок, доломит и подобные добавки, поэтому экологичность — главный плюс этих обоев. У стеклообоев есть и другие преимущества, например, возможность окраски, высокая прочность, устойчивость к влаге и огню. Стеклообои являются достаточно презентабельными и часто ими украшают даже стены ресторанов. К недостаткам относятся сложность монтажа/демонтажа.'
  },
  {
    type: S.withImg,
    img: 'oboi-10.jpg'
  },
  {
    type: S.withTitle,
    title: 'ПРОБКОВЫЕ ОБОИ',
    text:
      'Обои производят из коры средиземноморского дуба. Преимущества этих обоев: создание в помещении оздоравливающего микроклимата, бактерицидные свойства и экологичность. Также пробковым обоям присущи хорошие шумоизоляционные свойства, они не воспламеняются. Бархатистость обоев очень приятна на ощупь, по этой причине ими часто отделывают детские комнаты. Недостатки: цена и небольшой спектр расцветок.'
  },
  {
    type: S.withImg,
    img: 'oboi-11.jpg'
  }
];
