import { ServicesElems as S } from './constants';

export const MONTAZH = [
  {
    type: S.withImg,
    img: 'montazh-1.jpeg'
  },
  {
    type: S.withLine,
    text:
      'Во время ремонта в квартире, практически всегда происходит перепланировка. Межкомнатные перегородки, с помощью которых производится эта перепланировка, могут быть выполнены из разных материалов. Материалы отличаются между собой эксплуатационными характеристиками. Выбор материала зависит от: сложности монтажа, декоративного вида и требуемых функциональных показателей межкомнатных перегородок. Также, для достижения оригинального декоративного оформления, может быть использовано комбинирование материалов.'
  },
  {
    type: S.withTitle,
    title: 'КИРПИЧ',
    text:
      'Преимущество стен из кирпича — это стойкость к повреждениям, прочность, возможность крепить на них тяжелые навесные шкафчики, большие картины и др. предметы. Однако поскольку кирпич имеет высокую плотность, он является тяжелым материалом, это нужно учитывать при расчете нагрузки на перегородки'
  },
  {
    type: S.withQuote,
    text:
      'Из-за большой массы перегородок из кирпича, их возводят только на полу, который укреплен, к примеру, на железобетонных балках, которые опираются на несущие стены.'
  },
  {
    type: S.withTitle,
    text:
      'Кладка перегородки из кирпича может осуществляться из таких его видов как: клинкерный, керамический, силикатный. Кладка кирпича в Минске и области – это услуга, которую мы будем рады выполнить для Вас.По форме кирпичи бывают пустотелыми и цельными'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'межкомнатные перегородки из кирпича имеют высокую стойкость к механическим повреждениям',
      'у стен из кирпича хорошая звукоизоляция',
      'если межкомнатные перегородки возведены из клинкерного кирпича, на них смело можно вешать тяжелые предметы. Также их вешать можно и на стены из пустотелых кирпичей, однако, в таком случае необходимо использовать специальные усиленные крепления',
      'высокая прочность'
    ]
  },
  {
    type: S.withList,
    title: 'Недостатки:',
    list: [
      'у кирпича неровная поверхность и большие отклонения габаритов. После возведения, кирпичную стену нужно штукатурить, а это дополнительные затраты и увеличение продолжительности строительства',
      'кирпичные стены имеют очень большой вес, поэтому нагрузка на пол после их возведения существенно увеличивается',
      'строительство внутренних стен достаточно трудоемкое: для возведения 1 м² межкомнатной перегородки нужно 60 кирпичей и большое количество раствора.'
    ]
  },
  {
    type: S.withImg,
    img: 'montazh-2.jpg'
  },
  {
    type: S.withTitle,
    text: 'Стены из кирпича часто используют в дизайне интерьера.'
  },
  {
    type: S.withImg,
    img: 'montazh-3.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ГАЗОСИЛИКАТНЫЕ БЛОКИ',
    text:
      'Данный стеновой материал производится из ячеистого бетона, за счет чего он является прочным и легким. Внутренняя структура газосиликатного блока является пористой, что очень положительно сказывается на его шумо- и теплоизоляционных свойствах.Газосиликатный блок сразу по нескольким параметрам превосходит натуральную древесину, кирпич, бетон и др. материалы. Производится он из экологического сырья и отличается огнеупорностью, простотой в транспортировке и эксплуатации. Кладка газосиликатных блоков (Минск) может производиться с использованием клея или ЦПС.'
  },
  {
    type: S.withImg,
    img: 'montazh-4.jpg'
  },
  {
    type: S.withTitle,
    title: 'КЕРАМЗИТОБЕТОННЫЕ БЛОКИ',
    text:
      'Перегородки из этого материала возводятся гораздо быстрее, чем из кирпича, так как размеры керамзитобетонных блоков больше. Керамзитобетон является универсальным строительным материалом, который производится на основе керамзита (шарики из обожжённой глины) и портландцемента.'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'доступная цена',
      'небольшие транспортные затраты (если сравнивать с кирпичом)',
      'повышенная теплоизоляция',
      'быстрый монтаж благодаря стандартным габаритам и небольшому весу',
      'долговечность (керамзитобетонные конструкции не теряют своих качественных характеристик как минимум 50 лет)',
      'комфортный микроклимат в сооружениях из керамзитобетона'
    ]
  },
  {
    type: S.withQuote,
    text:
      'Наличие в элементах пустот, дает возможность армировать стены, делая их более прочными и устойчивыми.'
  },
  {
    type: S.withImg,
    img: 'montazh-5.jpg'
  },
  {
    type: S.withTitle,
    title: 'ГИПСОКАРТОН',
    text:
      'Часто в домах или квартирах межкомнатные стены делают именно из этого материала. Гипсокартон при этом крепят на предварительно сконструированный каркас, внутрь которого помещаются тепло- и звукоизоляционные материалы.'
  },
  {
    type: S.withTitle,
    text:
      'Использование гипсокартона характеризуется простотой в монтаже, а также возможностью делать перегородки любой формы. В помещениях с высокой влажностью и повышенным содержанием паров используется влагостойкий гипсокартон. Монтаж перегородок из гипсокартона (Минск) – наши специалисты выполнят для Вас любые перегородки и конструкции из гипсокартона.'
  },
  {
    type: S.withQuote,
    text:
      'Если монтаж перегородок из гипсокартона будет произведен правильно, то конструкция будет иметь небольшой вес, а также достаточную прочность и надежность'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'стены из гипсокартона возводятся очень быстро;',
      'материал обладает средней способностью поглощения звука;',
      'после возведения нет необходимости в оштукатуривании, достаточно шпаклевки;',
      'благодаря тому, что гипс может впитывать или отдавать влагу, которая содержится в воздухе, в помещении всегда будет хороший микроклимат.'
    ]
  },
  {
    type: S.withList,
    title: 'Недостатки:',
    list: [
      'если гипсокартон будет использоваться в ванных комнатах, на него необходимо нанести дополнительный слой изоляции, даже если это водонепроницаемый гипсокартон;',
      'обладает минимальной устойчивостью перед повреждениями и ударами;',
      'стены из гипсокартона прочны не настолько, как кирпичные. Поэтому, если есть необходимость повесить на такой стене тяжелый предмет — это следует делать продуманно. Крепеж нужно зафиксировать на металлическом профиле, который должен находиться внутри перегородки.'
    ]
  },
  {
    type: S.withQuote,
    text:
      'Гипсокартонные каркасные конструкции — это быстровозводимое решение и оптимальный вариант в финансовом плане.'
  },
  {
    type: S.withImg,
    img: 'montazh-6.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЕРЕВО',
    text:
      'Перегородки из дерева могут иметь отличия по конструкции: каркасно-обшивные, двойные с заполненным или воздушным промежутком, сплошные дощатые.'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'можно возвести межкомнатную перегородку с повышенной прочностью материала;',
      'натуральный и экологичный материал.'
    ]
  },
  {
    type: S.withList,
    title: 'Недостатки:',
    list: [
      'пожароопасность',
      'гигроскопичность (неблагоприятное воздействие влаги на материал).'
    ]
  },
  {
    type: S.withImg,
    img: 'montazh-7.jpg'
  },
  {
    type: S.withTitle,
    text:
      'Также межкомнатные перегородки могут быть возведены из таких материалов как: сэндвич-панели, силикатные блоки, поликарбонат, ячеистые бетонные блоки, стекло, бетон и др.'
  },
  {
    type: S.withTitle,
    title: 'УСТРОЙСТВО ДВЕРНЫХ ПРОЕМОВ',
    text:
      'Способы и виды отделки дверных проемов играют важную роль в оформлении интерьера. Данная деталь занимает много места на стене и привлекает внимание. У самих дверных проемов практически нет никаких отличительных особенностей. Они просто являются местом, в котором устанавливаются межкомнатные двери. Играют транзитную роль на границе помещения и комнат. Однако, характер конструкции дома оказывает прямое влияние на особенности этих проемов'
  },
  {
    type: S.withList,
    title: 'Проем можно считать «идеальным», когда:',
    list: [
      'в любом из мест замера расстояние от пола до перемычки одинаковое;',
      'длина от одного до второго откоса будет одинаковой в любой из точек;',
      'толщина будет равномерной по всему периметру проема;',
      'нет угла наклона поверхности пола под проемом;',
      'два откоса составляют параллельные прямые.'
    ]
  },
  {
    type: S.withImg,
    img: 'montazh-8.jpeg'
  }
];
