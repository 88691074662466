import { DEMONTAZH } from './servicesElements/demontazh';
import { MONTAZH } from './servicesElements/MONTAZH';
import { SANTECH } from './servicesElements/SANTECH';
import { ELECTROMONTAZH } from './servicesElements/ELEKTROMONTAZH';
import { MALYAR_SHUT } from './servicesElements/MALYAR_SHUT';
import { GIPS } from './servicesElements/GIPS';
import { UKLADKA_PLITKI } from './servicesElements/UKLADKA_PLITKI';
import { POTOLOK } from './servicesElements/POTOLOK';
import { POKRASKA } from './servicesElements/POKRASKA';
import { UKLADKA } from './servicesElements/UKLADKA';
import { STENA } from './servicesElements/STENA';
import { OBOI } from './servicesElements/OBOI';
import { NARYZHNYE } from './servicesElements/NARYZHNYE';
import { REMONT_MAGAZINOV } from './servicesElements/REMONT_MAGAZINOV';
import { REMONT_OFISOV } from './servicesElements/REMONT_OFISOV';
import { REMONT_CAFE } from './servicesElements/REMONT_CAFE';
import { REMONT_SKLADA } from './servicesElements/REMONT_SKLADA';
import { REMONT_NEZHILYH } from './servicesElements/REMONT_NEZHILYH';

export const SERVICES_TYPE = {
  R_K: 'remont_kvartir',
  R_C: 'remont_сottage',
  R_O: 'remont_offisov'
};

export const MAIN_PAGE_SERVICES_CONTENT = [
  {
    id: 0,
    type: SERVICES_TYPE.R_K,
    navtext: 'демонтаж',
    title: 'Демонтажные работы',
    titleCEO: 'Демонтаж в Минске – быстро и качественно!',
    descCEO:
      'Демонтаж перегородок, стен, оконного и дверного блока, старых покрытий; сбивка стяжки пола и штукатурки со стен.',
    image: 'demontazh-1.jpeg',
    data: DEMONTAZH
  },
  {
    id: 1,
    type: SERVICES_TYPE.R_K,
    navtext: 'монтаж',
    title: 'Монтажные работы',
    titleCEO:
      'Монтаж межкомнатных перегородок в Минске – быстро и качественно!',
    descCEO:
      'Монтаж межкомнатных перегородок (кладка стен) из кирпича, газосиликатных блоков, керамзитобетонных блоков, гипсокартона, дерева и т.д. Устройство дверных проемов.',
    image: 'montazh-1.jpeg',
    data: MONTAZH
  },
  {
    id: 2,
    type: SERVICES_TYPE.R_K,
    navtext: 'сантехнические работы',
    title: 'Сантехнические работы в Минске',
    titleCEO: 'Сантехнические работы в Минске.',
    descCEO:
      'Сантехнические работы «под ключ» в Минске и Минской области. Мы выполняем сантехнические работы любой сложности.',
    image: 'santeh-1.jpg',
    data: SANTECH
  },
  {
    id: 3,
    type: SERVICES_TYPE.R_K,
    navtext: 'электромонтажные работы',
    title: 'Электромонтажные работы в Минске',
    titleCEO: 'Электромонтажные работы в Минске',
    descCEO:
      'Полный спектр услуг по электромонтажным работам в Минске и Минской области. В нашей команде квалифицированные специалисты с большим опытом работы. ',
    image: 'electomon-1.jpg',
    data: ELECTROMONTAZH
  },
  {
    id: 4,
    type: SERVICES_TYPE.R_K,
    navtext: 'малярные работы',
    title: 'Малярно-штукатурные работы в Минске',
    titleCEO: 'Малярные работы в Минске',
    descCEO:
      'Все виды малярных работ. Штукатурка и шпатлевание стен и потолка, грунтование, поклейка обоев, покраска стен и потолка, нанесение декоративной штукатурки.',
    image: 'malyarno-shut-1.jpg',
    data: MALYAR_SHUT
  },
  {
    id: 5,
    type: SERVICES_TYPE.R_K,
    navtext: 'гипсокартонные работы',
    title: 'Гипсокартонные работы в Минске',
    titleCEO: 'Гипсокартонные работы любой сложности в Минске',
    descCEO:
      'Гипсокартонные работы любой сложности. Монтаж любых конструкций из всех видов гипсокартона. Монтаж потолочных и стеновых прямолинейных и криволинейных фигур любой сложности, межкомнатные перегородки, декоративные элементы, откосы.',
    image: 'gips-1.jpg',
    data: GIPS
  },
  {
    id: 6,
    type: SERVICES_TYPE.R_K,
    navtext: 'укладка плитки',
    title: 'Укладка плитки в Минске',
    titleCEO: 'Укладка плитки любой сложности в Минске',
    descCEO:
      'Профессиональная укладка плитки любых видов и размеров в Минске и Минской области.',
    image: 'plitka-1.jpeg',
    data: UKLADKA_PLITKI
  },
  {
    id: 7,
    type: SERVICES_TYPE.R_K,
    navtext: 'устройство потолков',
    title: 'Устройство потолков',
    titleCEO: 'Устройство потолков всех видов в Минске!',
    descCEO:
      'Устройство потолков в Минске: подвесные, натяжные, покраска потолков, декоративная штукатурка, гипсокартонные потолки, деревянные и т.д.',
    image: 'potolok-1.jpeg',
    data: POTOLOK
  },
  {
    id: 8,
    type: SERVICES_TYPE.R_K,
    navtext: 'покраска стен и потолков',
    title: 'Покраска стен и потолков в Минске',
    titleCEO: 'Покраска стен и потолков в Минске!',
    descCEO: 'Качественная покраска стен и потолка в Минске.',
    image: 'pokraska-1.jpg',
    data: POKRASKA
  },
  {
    id: 9,
    type: SERVICES_TYPE.R_K,
    navtext: 'укладка напольных покрытий',
    title: 'Укладка напольных покрытий в Минске',
    titleCEO: 'Укладка напольных покрытий! Ремонт квартир в Минске!',
    descCEO:
      'Монтаж пола в Минске. Укладка ламината, паркета, керамической плитки, пробкового пола, ковролина, линолеума, массивной доски, керамогранита.',
    image: 'ukladka-1.jpeg',
    data: UKLADKA
  },
  {
    id: 10,
    type: SERVICES_TYPE.R_K,
    navtext: 'отделка стен',
    title: 'Внутренняя отделка стен',
    titleCEO: 'Все виды внутренней отделки стен!',
    descCEO:
      'Все виды внутренней отделки стен: оклейка обоями, покраска, декоративный кирпич, керамогранит, декоративная штукатурка, керамическая плитка, отделка древом, декоративные панели и т.д.',
    image: 'stena-1.jpg',
    data: STENA
  },
  {
    id: 11,
    type: SERVICES_TYPE.R_K,
    navtext: 'поклейка обоев',
    title: 'Поклейка обоев в Минске',
    titleCEO: 'Поклейка обоев в Минске.',
    descCEO:
      'Выполним оклейку обоями любого уровня сложности в Минске и Минской области.',
    image: 'oboi-1.jpeg',
    data: OBOI
  },
  {
    id: 12,
    type: SERVICES_TYPE.R_C,
    navtext: 'наружные работы',
    title: 'Наружные работы',
    titleCEO: 'Наружные работы в ремонте коттеджей.',
    descCEO:
      'Оперативно и качественно выполним наружные работы в ремонте дома: монтаж нового фасада, замену кровли, усиление фундамента, создание прилегающей новой постройки, создание еще одного этажа, террасы, тепло-, звукоизоляция стен и кровли, укрепление стеновых конструкций, теплоизоляция веранд, террас в Минской области.',
    image: 'naryzhnye-1.jpg',
    data: NARYZHNYE
  },
  {
    id: 13,
    type: SERVICES_TYPE.R_C,
    navtext: 'демонтаж',
    title: 'Демонтажные работы',
    titleCEO: 'Демонтаж в Минске – быстро и качественно!',
    descCEO:
      'Демонтаж перегородок, стен, оконного и дверного блока, старых покрытий; сбивка стяжки пола и штукатурки со стен.',
    image: 'demontazh-1.jpg',
    data: DEMONTAZH
  },
  {
    id: 14,
    type: SERVICES_TYPE.R_C,
    navtext: 'монтаж',
    title: 'Монтажные работы',
    titleCEO:
      'Монтаж межкомнатных перегородок в Минске – быстро и качественно!',
    descCEO:
      'Монтаж межкомнатных перегородок (кладка стен) из кирпича, газосиликатных блоков, керамзитобетонных блоков, гипсокартона, дерева и т.д. Устройство дверных проемов.',
    image: 'montazh-1.jpeg',
    data: MONTAZH
  },
  {
    id: 15,
    type: SERVICES_TYPE.R_C,
    navtext: 'сантехнические работы',
    title: 'Сантехнические работы в Минске',
    titleCEO: 'Сантехнические работы в Минске.',
    descCEO:
      'Сантехнические работы «под ключ» в Минске и Минской области. Мы выполняем сантехнические работы любой сложности.',
    image: 'santeh-1.jpg',
    data: SANTECH
  },
  {
    id: 16,
    type: SERVICES_TYPE.R_C,
    navtext: 'электромонтажные работы',
    title: 'Электромонтажные работы в Минске',
    titleCEO: 'Электромонтажные работы в Минске',
    descCEO:
      'Полный спектр услуг по электромонтажным работам в Минске и Минской области. В нашей команде квалифицированные специалисты с большим опытом работы. ',
    image: 'electomon-1.jpg',
    data: ELECTROMONTAZH
  },
  {
    id: 17,
    type: SERVICES_TYPE.R_C,
    navtext: 'малярные работы',
    title: 'Малярно-штукатурные работы в Минске',
    titleCEO: 'Малярные работы в Минске',
    descCEO:
      'Все виды малярных работ. Штукатурка и шпатлевание стен и потолка, грунтование, поклейка обоев, покраска стен и потолка, нанесение декоративной штукатурки.',
    image: 'malyarno-shut-1.jpg',
    data: MALYAR_SHUT
  },
  {
    id: 18,
    type: SERVICES_TYPE.R_C,
    navtext: 'гипсокартонные работы',
    title: 'Гипсокартонные работы в Минске',
    titleCEO: 'Гипсокартонные работы любой сложности в Минске',
    descCEO:
      'Гипсокартонные работы любой сложности. Монтаж любых конструкций из всех видов гипсокартона. Монтаж потолочных и стеновых прямолинейных и криволинейных фигур любой сложности, межкомнатные перегородки, декоративные элементы, откосы.',
    image: 'gips-1.jpg',
    data: GIPS
  },
  {
    id: 19,
    type: SERVICES_TYPE.R_C,
    navtext: 'укладка плитки',
    title: 'Укладка плитки в Минске',
    titleCEO: 'Укладка плитки любой сложности в Минске',
    descCEO:
      'Профессиональная укладка плитки любых видов и размеров в Минске и Минской области.',
    image: 'plitka-1.jpeg',
    data: UKLADKA_PLITKI
  },
  {
    id: 20,
    type: SERVICES_TYPE.R_C,
    navtext: 'устройство потолков',
    title: 'Устройство потолков',
    titleCEO: 'Устройство потолков всех видов в Минске!',
    descCEO:
      'Устройство потолков в Минске: подвесные, натяжные, покраска потолков, декоративная штукатурка, гипсокартонные потолки, деревянные и т.д.',
    image: 'potolok-1.jpeg',
    data: POTOLOK
  },
  {
    id: 21,
    type: SERVICES_TYPE.R_C,
    navtext: 'покраска стен и потолков',
    title: 'Покраска стен и потолков в Минске',
    titleCEO: 'Покраска стен и потолков в Минске!',
    descCEO: 'Качественная покраска стен и потолка в Минске.',
    image: 'pokraska-1.jpg',
    data: POKRASKA
  },
  {
    id: 22,
    type: SERVICES_TYPE.R_C,
    navtext: 'укладка напольных покрытий',
    title: 'Укладка напольных покрытий в Минске',
    titleCEO: 'Укладка напольных покрытий! Ремонт квартир в Минске!',
    descCEO:
      'Монтаж пола в Минске. Укладка ламината, паркета, керамической плитки, пробкового пола, ковролина, линолеума, массивной доски, керамогранита.',
    image: 'ukladka-1.jpeg',
    data: UKLADKA
  },
  {
    id: 23,
    type: SERVICES_TYPE.R_C,
    navtext: 'отделка стен',
    title: 'Внутренняя отделка стен',
    titleCEO: 'Все виды внутренней отделки стен!',
    descCEO:
      'Все виды внутренней отделки стен: оклейка обоями, покраска, декоративный кирпич, керамогранит, декоративная штукатурка, керамическая плитка, отделка древом, декоративные панели и т.д.',
    image: 'stena-1.jpg',
    data: STENA
  },
  {
    id: 24,
    type: SERVICES_TYPE.R_C,
    navtext: 'поклейка обоев',
    title: 'Поклейка обоев в Минске',
    titleCEO: 'Поклейка обоев в Минске.',
    descCEO:
      'Выполним оклейку обоями любого уровня сложности в Минске и Минской области.',
    image: 'oboi-1.jpeg',
    data: OBOI
  },
  {
    id: 25,
    type: SERVICES_TYPE.R_O,
    navtext: 'ремонт магазинов',
    title: 'Ремонт магазинов «под ключ» в Минске',
    titleCEO: 'Ремонт магазинов под ключ в Минске!',
    descCEO:
      'Комплексный ремонт магазинов в Минске и области любой площади и дизайна.',
    image: 'remont_magazinov.jpg',
    data: REMONT_MAGAZINOV
  },
  {
    id: 26,
    type: SERVICES_TYPE.R_O,
    navtext: 'ремонт офисов',
    title: 'Ремонт офисов в Минске',
    titleCEO: 'Ремонт офисов под ключ в Минске!',
    descCEO:
      'Комплексный ремонт офисов в Минске и области, любой площади и дизайна. ',
    image: 'remont-ofisov-1.jpg',
    data: REMONT_OFISOV
  },
  {
    id: 27,
    type: SERVICES_TYPE.R_O,
    navtext: 'ремонт кафе и ресторанов',
    title: 'Ремонт кафе, ресторанов в Минске',
    titleCEO: 'Ремонт кафе, ресторанов под ключ в Минске!',
    descCEO:
      'Комплексный ремонт кафе и ресторанов в Минске и области, любой площади и дизайна.',
    image: 'remont-cafe-1.jpg',
    data: REMONT_CAFE
  },
  {
    id: 28,
    type: SERVICES_TYPE.R_O,
    navtext: 'ремонт складских и промышленных помещений',
    title:
      'Ремонт складских и производственных помещений в Минске и Минской области',
    titleCEO:
      'Ремонт складских и производственных помещений под ключ в Минске!',
    descCEO:
      'Комплексный ремонт складских и производственных помещений в Минске и области.',
    image: 'remont-sklada.jpg',
    data: REMONT_SKLADA
  },
  {
    id: 29,
    type: SERVICES_TYPE.R_O,
    navtext: 'ремонт нежилых помещений',
    title: 'Ремонт нежилых помещений в Минске',
    titleCEO: 'Ремонт нежилых помещений под ключ в Минске!',
    descCEO:
      'Комплексный ремонт нежилых помещений в Минске и области «под ключ». По договору! Гарантия!',
    image: 'remont-nezhilyh-1.jpg',
    data: REMONT_NEZHILYH
  }
];
