import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import InfoBlock from '../containers/InfoBlock';
import {
  InfoBlockItemProjects,
  InfoBlockItemAboutCompany,
  InfoBlockItemPromo
} from '../components/InfoBlockItem';
import { MAIN_PAGE_IDEAS_CONTENT } from '../lovkoContent/Ideas/ideas';
import IdeasItem from '../containers/IdeasItem';
import ErrorPage from './ErrorPage';
import { url_slug } from 'cyrillic-slug';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';

class IteasPageItem extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { id } = this.props;
    const idea = MAIN_PAGE_IDEAS_CONTENT.find(
      i => url_slug(i.title.toLowerCase(), '_') === id
    );
    if (!idea) return <ErrorPage />;
    return (
      <Fragment>
        <Helmet>
          <title>{idea.title}</title>
          <meta name="description" content={TitleAndDesc.ideasPage.desc} />
        </Helmet>
        <Header />
        <IdeasItem idea={idea} />
        <InfoBlock>
          <InfoBlockItemProjects />
          <InfoBlockItemAboutCompany />
          <InfoBlockItemPromo />
        </InfoBlock>
        <Footer />
      </Fragment>
    );
  }
}

export default IteasPageItem;
