
import { ServicesElems as S } from './constants';
export const REMONT_CAFE = [{
  type: S.withImg,
  img: 'remont-cafe-1.jpg'
},
{
  type: S.withLine,
  text:
    'Рестораны, кафе, бары ежедневно посещают сотни людей. Самое важное – это, конечно, кухня и обслуживание, а еще концепция, атмосфера и интерьер. Поэтому качественная отделка помещения требует ответственного, профессионального подхода.'
},
{
  type: S.withTitle,
  text:
    'Мы выполним для Вас весь комплекс отделочных работ любой сложности по ремонту кафе, ресторанов в Минске, чтобы помочь Вам создать интерьер, в который посетители захотят возвращаться снова и снова. Оборудуем рабочие места вашего заведения согласно требованиям эргономики, техники безопасности и санитарным нормам. При необходимости заново проложим инженерные коммуникации.'
},
{
  type: S.withList,
  title:
    'Полный комплекс ремонтно-отделочных работ ресторанов, кафе, баров, включает:',
  list: ['Демонтирование старых перегородок и покрытий',
    'Устройство новых перегородок и дверных проемов дверей разных размеров, видов',
    'Монтаж водопровода и канализации, установка инсталляции / унитаза, замена труб и радиаторов отопления, установка вентилей и смесителей',
    'Монтаж электрощитка, электромонтаж разветвительных коробок, прокладывание кабелей, установка выключателей и розеток',
    'Штукатурка стен, зашивка стен гипсокартоном, изготовление авторских конструкций, штукатурка потолка, шпатлевка потолков и стен под покраску, стяжка пола, гидроизоляция',
    'Устройство стен и потолков любых видов, укладка плитки (разные размеры и класс плитки), декорирование стен любыми материалами и элементами, покраска стен и потолка, укладка напольных покрытий (любые варианты), декорирование откосов, оклейка обоями, монтаж плинтусов',
    'уборка объекта'
  ]
}
]