export const IDEA_3 = [
  {
    type: 'idea_with_img',
    img: '1_stil-barokko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОСОБЕННОСТИ',
    text: [
      'Интерьеры этого стиля можно описать такими словами: по-королевски, пышно, праздно. По-другому и быть не может, ведь этот стиль относится к концу XVI века (Италия). Для этого стиля характерно то, что все предметы подчеркивают социальный статус и достаток владельца, но несмотря на это его нужно делать очень функциональным.',
      'Характерна симметричность и применение только той мебели, которая необходима, но она, конечно, должна быть красивой и с шикарными украшениями.'
    ]
  },

  {
    type: 'idea_with_title',
    title: 'ЦВЕТА',
    text: [
      'Можно использовать все «дорогие» цвета (такие как бордово-красный, насыщенный зеленый, сочетайте синий с золотом, зеленый с коричневым), но самым главным цветом должен быть, конечно, золотой.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2_stil-barokko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Для этого стиля характерно применение большого количество текстиля. Ткани должны быть натуральными, тяжелыми, с изысканными узорами, богатые (бархат, шел, атлас, уместно использование гобелена на обивке мебели и портьерах). А вот кожу в этом интерьере лучше не использовать. Порчей можно оформить мебель, стены, оконные проемы, двери. Прекрасно будут смотреться портьеры до пола с красивыми лабрекенами, бантами, оборками. Также из дорогих тканей следует отшивать такие элементы декора, как скатерти, валики и подушки, покрывала.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3_stil-barokko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'Используйте антикварную мебель или выполненную «под старину», украшенную инкрустациями меди и слоновой кости, с тканями с цветочным орнаментом, птицами или пейзажами.',
      'Будет хорошо, если в интерьере будет присутствовать массивный комод и, конечно, большой обеденный стол.',
      'Во времена барокко кровать была предметом интерьера, который наиболее пышно украшали, потому что гостей-то время от времени принимали лежа в постели, лежа в шикарной позе. Она имела форму шатра с большим количеством драпированной ткани и украшений.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4_stil-barokko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОСВЕЩЕНИЕ',
    text: [
      'Освещение должно быть рассеянным и неярким (источники света в форме свечей).'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5_stil-barokko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ, СТЕНЫ, ПОТОЛОК',
    text: [
      'На пол укладывают мрамор или же дерево (натуральное).',
      'Стены декорируют тканью либо обоями, фресками, коврами. Стены можно окрасить в однотонный цвет (пастельный), но в этом случае их желательно украсить лепниной.',
      'Что же касается потолка, то он тоже должен быт шикарным, его можно украсить лепниной с витиеватыми узорами и позолотой.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5_stil-barokko.png'
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'Можно украсить помещений зеркалами в дорогих, красивых рамах (они могут быть украшены позолоченной липниной), антикварными напольными бронзовыми часами, картинами в стиле барокко (копии Рубенса, Караваджо, Рембранта, ну или оригиналы, конечно тоже), скульптурами, шкатулками, канделябрами. В то время на картинах изображали портреты, море, житейские сцены на форе барочных дворцов). В кабинете можно разместить антикварный глобус и повесить на стену старинную карту.',
      'Однако в этом интерьере не должно быть много аксессуаров.'
    ]
  },
];
