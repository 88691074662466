import React, { useState } from 'react';
import { Tab, Row, Col } from 'react-bootstrap';
import {
  COMPLETED_PROJECTS,
  TABS_NAV_ITEMS
} from '../lovkoContent/completedProjects';
//import { Link } from 'react-router-dom';
import CompleteProjectElementModal from './CompleteProjectElementModal';

const ProjectsTabContent = props => {
  const [modal, changeModalVisibility] = useState(null);
  let modalClose = () => changeModalVisibility(null);
  const { projectsCount } = props;

  return (
    <Tab.Content>
      {TABS_NAV_ITEMS.map(item => (
        <Tab.Pane key={item.id} eventKey={item.id}>
          <Row className="mobile-minus-margin">
            {COMPLETED_PROJECTS.filter(project =>
              item.id === 'all' ? project : project.tab === item.id
            )
              .reverse()
              .slice(0, projectsCount)
              .map(elem => (
                <Col xs={12} md={4} key={elem.id}>
                  <div
                    className="project-image-item"
                    style={{
                      minHeight: 250,
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/projects/${elem.id}/${elem.smallImg})`
                    }}
                  >
                    <div
                      className="project-image-item-blue-bg"
                      onClick={() => {
                        changeModalVisibility(elem.id);
                      }}
                    >
                      <h4 className="text-white text-center">{elem.title}</h4>
                      <span>подробнее</span>
                    </div>
                  </div>
                  <CompleteProjectElementModal
                    project={elem}
                    show={
                      modal === elem.id && item.id === elem.tab ? true : false
                    }
                    onHide={modalClose}
                  />
                </Col>
              ))}
          </Row>
        </Tab.Pane>
      ))}
    </Tab.Content>
  );
};

export default ProjectsTabContent;
