import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Picture } from 'react-responsive-picture';
import Button from '../components/Button';
import { HashLink as Link } from 'react-router-hash-link';
import { R } from '../Routes';

const Ceo = props => {
  return (
    <section className='ceo'>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div className='img-container mw-350 m-auto'>
              <Picture
                className={'img-circle'}
                srcSet={process.env.PUBLIC_URL + '/assets/images/boss.jpg'}
                sources={[
                  { srcSet: process.env.PUBLIC_URL + '/assets/images/boss.jpf', type: 'image/jpm' },
                  { srcSet: process.env.PUBLIC_URL + '/assets/images/boss.webp', type: 'image/webp' }
                ]}
                alt='boss'
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            {props.text}
            <div className='ceo-footer'>
              <Row className='flex-grow-1'>
                <Col>
                  <div className='ceo-footer-text'>
                    <p className='mb-0 text-gray  text-small-14'>
                      мой телефон{' '}
                      <a href='tel:+375295610288' className='text-blue'>
                        +375 (29) 561 02 88
                      </a>
                    </p>
                    <p className='mb-0 text-gray  text-small-14'>
                      Звоните, буду рад ответить на интересующие Вас вопросы
                    </p>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md='auto'
                  className='flex-shrink-0 d-none d-md-flex'
                >
                  <Button
                    className='mt-auto flex-grow-1 flex-md-grow-0'
                    blue
                    size='small'
                    boxshadow='half'
                  >
                    <Link
                      to={`${R.about}#komanda`}
                      scroll={el =>
                        el.scrollIntoView({
                          behavior: 'instant',
                          block: 'center'
                        })
                      }
                    >
                      наша команда
                    </Link>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Ceo;
