import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import InfoBlock from '../containers/InfoBlock';
import {
  InfoBlockItemProjects,
  InfoBlockItemAboutCompany,
  InfoBlockItemAboutServices
} from '../components/InfoBlockItem';
import { MAIN_PAGE_SERVICES_CONTENT } from '../lovkoContent/services/services'
import { url_slug } from 'cyrillic-slug';
import ErrorPage from './ErrorPage';
import ServiceItem from '../containers/ServiceItem';

class ServicesPageItem extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps() {
    window.scrollTo(0, 0);
  }
  render() {
    const { id } = this.props;
    const service = MAIN_PAGE_SERVICES_CONTENT.find(
      i => url_slug(i.title.toLowerCase(), '_') === id
    );
    if (!service) return <ErrorPage />;
    return (
      <Fragment>
        <Helmet>
          <title>{service.titleCEO}</title>
          <meta name="description" content={service.descCEO} />
        </Helmet>
        <Header />
        <ServiceItem service={service} />
        <InfoBlock>
          <InfoBlockItemAboutServices />
          <InfoBlockItemProjects />
          <InfoBlockItemAboutCompany />
        </InfoBlock>
        <Footer />
      </Fragment>
    )
  }

}

export default ServicesPageItem;
