import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SlickImg } from '../components/Slick';
import NextArrow from '../components/NextArrow';
import PrevArrow from '../components/NextArrow';

const TEAMMATES = [
  { position: 'Руководитель студии', name: "Алексей", img: '1-rukovoditel-studii-lovko.jpg' },
  { position: 'Маркетолог', name: "Ольга", img: '2-marketolog-lovko.jpg' },
  { position: 'Отделочник', name: "Дмитрий", img: '3-otdelochnik-lovko.jpg' },
  { position: 'Отделочник', name: "Евгений", img: '4-otdelochnik-lovko.jpg' },
  { position: 'Отделочник', name: "Александр", img: '6-otdelochnik-lovko.jpg' },
  { position: 'Подсобник', name: "Андрей", img: '7-podsobnik-lovko.jpg' }
];

const SLICK_SETTING = {
  infinite: true,
  speed: 500,
  lazyLoad: 'progressive',
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <NextArrow addClass="next-arrow-custom" />,
  prevArrow: <PrevArrow addClass="prev-arrow-custom" />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const TeamSlider = () => {
  return (
    <section className="team-slider" id="komanda">
      <Container>
        <Row>
          <Col>
            <h2 className="text-gray f-w-400 text-center text-uppercase h2-small mb-4">
              Наша команда
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="slick-with-img-wrapper">
              <SlickImg setting={SLICK_SETTING} items={TEAMMATES} withInfo />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TeamSlider;
