import React from 'react';
import { Nav } from 'react-bootstrap';

const TabsNav = props => {
  const { tabselems, whiteTab } = props;
  return (
    <Nav className="tabs-nav" variant="tabs">
      <div
        className={
          whiteTab
            ? 'tabs-wrapper tabs-wrapper-white box-shadow-half '
            : 'tabs-wrapper box-shadow-half'
        }
      >
        {tabselems.map(item => (
          <Nav.Item key={item.id}>
            <Nav.Link eventKey={item.id}>{item.text}</Nav.Link>
          </Nav.Item>
        ))}
      </div>
    </Nav>
  );
};

export default TabsNav;
