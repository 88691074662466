import React, { Component } from 'react';
import { Container, Row, Col, Tab } from 'react-bootstrap';
import Button from '../components/Button';
import TabsNav from '../components/TabsNav';
import IdeasTabContent from '../components/IdeasTabContent';
import { Link } from 'react-router-dom';
import {
  TABS_NAV_ITEMS,
  MAIN_PAGE_IDEAS_CONTENT
} from '../lovkoContent/Ideas/ideas';
import { R } from '../Routes';

class Ideas extends Component {
  state = {
    ideasCount: null,
    showButton: true
  };
  componentDidMount() {
    const { ideasCount, ideasPage } = this.props;
    ideasPage && ideasCount > MAIN_PAGE_IDEAS_CONTENT.length
      ? this.setState({ ideasCount: this.props.ideasCount, showButton: false })
      : this.setState({ ideasCount: this.props.ideasCount });
  }

  changeIdeasCount = () => {
    const { ideasCount } = this.state;
    if (ideasCount < MAIN_PAGE_IDEAS_CONTENT.length + 12) {
      this.setState({
        ideasCount: MAIN_PAGE_IDEAS_CONTENT.length,
        showButton: false
      });
    } else if (ideasCount > MAIN_PAGE_IDEAS_CONTENT.length + 12) {
      this.setState({ ideasCount: ideasCount + 12 });
    }
  };

  render() {
    const { withTitle, noTopPadiing, whiteTab, ideasPage } = this.props;
    const { ideasCount, showButton } = this.state;
    return (
      <section
        style={noTopPadiing ? { paddingTop: 0 } : null}
        className="ideas hide-on-mobile"
      >
        <Container>
          {withTitle && (
            <Row>
              <Col>
                <h2 className="text-gray f-w-400 text-center text-uppercase h2-small mb-3">
                  Идеи для вдохновения
                </h2>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Tab.Container id="section-ideas" defaultActiveKey="all">
                <Row>
                  <Col className="mb-4 mb-md-5 ">
                    <TabsNav whiteTab={whiteTab} tabselems={TABS_NAV_ITEMS} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <IdeasTabContent ideasCount={ideasCount} />
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          {showButton && (
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  className="flex-grow-1 flex-md-grow-0"
                  size="small"
                  blue
                  boxshadow="half"
                >
                  {ideasPage ? (
                    <span onClick={this.changeIdeasCount}>больше идей</span>
                  ) : (
                    <Link to={R.ideas}>больше идей</Link>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    );
  }
}

export default Ideas;
