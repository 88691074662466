import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CombinedSlick } from '../components/CombinedSlick';
import NextArrow from '../components/NextArrow';
import PrevArrow from '../components/NextArrow';

const SLICK_SETTING = {
  infinite: false,
  speed: 500,
  lazyLoad: 'progressive',
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <NextArrow addClass="next-arrow-custom next-arrow-custom-medium" />
  ),
  prevArrow: <PrevArrow addClass="prev-arrow-custom next-arrow-custom-medium" />
};

const HISTORY_SLIDER = [
  {
    id: 1,
    title: 'А лифт-то маловат…',
    text:
      'На этом объекте укладывали плитку 2600*1200. И …. она в лифт, конечно, не поместилась. Поэтому поднимали вчетвером пешком по лестнице на девятый этаж. Лестничные пролеты были узкие, плитку просовывали между лестничных перил. И чтобы плитка прошла и не сбились углы, создавали специальный каркас.',
    img: ['stories/1/1-istori-remonta-lovkoremont.jpg', 'stories/1/2-istori-remonta-lovkoremont.jpg', 'stories/1/3-istori-remonta-lovkoremont.jpg'],
  }
];

const HistorySlider = () => {
  return (
    <section className="history-slider" id="history-slider">
      <h2 className="history-slider-title">
        У каждого ремонта <span className="f-w-600">своя история</span>
      </h2>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 7, offset: 5 }}>
            <div className="history-slick-slider-wrapper">
              <div className="history-slick-slider">
                <CombinedSlick setting={SLICK_SETTING} items={HISTORY_SLIDER} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HistorySlider;
