import React from 'react';
import { withRouter } from 'react-router';
import { Tab, Row, Col } from 'react-bootstrap';
import {
  TABS_NAV_ITEMS,
  MAIN_PAGE_IDEAS_CONTENT
} from '../lovkoContent/Ideas/ideas';
import { MAIN_PAGE_SERVICES_CONTENT } from '../lovkoContent/services/services';
import { url_slug } from 'cyrillic-slug';
import { R } from '../Routes';

const IdeasTabContent = props => {
  const pushToIdea = id => props.history.push(`${R.ideas}/${id}`);
  const pushToServiceItem = serviceId => {
    const nav = MAIN_PAGE_SERVICES_CONTENT.find(
        el => el.id === serviceId
    )

    const link = url_slug(nav.title.toLowerCase(), '_');
    return props.history.push(`${R.services}/${link}`)
  };
  const { ideasCount } = props;
  return (
    <Tab.Content>
      {TABS_NAV_ITEMS.map(item => (
        <Tab.Pane key={item.id} eventKey={item.id}>
          <Row>
            {MAIN_PAGE_IDEAS_CONTENT.reverse()
              .filter(idea =>
                item.id === 'all' ? idea : idea.type === item.id
              )
              .slice(0, ideasCount)
              .map(elem => (
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  key={elem.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (elem.hasOwnProperty('serviceId'))
                      pushToServiceItem(elem.serviceId)
                    else
                      pushToIdea(
                        url_slug(
                          elem.title.toLowerCase(),
                          '_'
                        )
                      )
                    }
                  }
                >
                  <div
                    className="project-image-item img-container mb-3"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ideas/${elem.id}/${elem.image})`
                    }}
                  />

                  <h5 className="text-uppercase">{elem.title}</h5>
                  <p className="text-small">{elem.text}</p>
                </Col>
              ))}
          </Row>
        </Tab.Pane>
      ))}
    </Tab.Content>
  );
};

export default withRouter(IdeasTabContent);
