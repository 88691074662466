import React, {useState} from 'react';
import { PRICES_ITEMS } from '../constants';
import { MAIN_PAGE_IDEAS_CONTENT } from '../lovkoContent/Ideas/ideas'
import Button from './Button';
import { HashLink as Link } from 'react-router-hash-link';
import { R } from '../Routes';
import { COMPLETED_PROJECTS } from '../lovkoContent/completedProjects';
import CompleteProjectElementModal from './CompleteProjectElementModal';

export const InfoBlockItemCost = ({ blueHeader }) => {
  return (
    <div className="infoblock-item">
      <Link to={R.prices}>
        <h5
          className="infoblock-item-header mb-4 box-shadow-half"
          style={{
            color: blueHeader ? 'white' : '#727271',
            backgroundColor: blueHeader ? '#25BDFF' : '#e2e3eb'
          }}
        >
          Стоимость ремонта
        </h5>
      </Link>
      <table className="mb-3">
        <tbody>
          {PRICES_ITEMS.map((item, index) => (
            <tr key={index}>
              <td>{item.title}</td>
              <td className="text-right">
                <b>от {item.price} у.е.</b>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        boxshadow="half"
        blue={blueHeader ? false : true}
        gray={blueHeader ? true : false}
        size="small"
        className="align-self-end mt-auto"
      >
        <Link to={R.prices}>подробнее</Link>
      </Button>
    </div>
  );
};

export const InfoBlockItemAboutCompany = ({ blueHeader }) => {
  return (
    <div className="infoblock-item">
      <Link to={R.about}>
        <h5
          className="infoblock-item-header mb-4 box-shadow-half"
          style={{
            color: blueHeader ? 'white' : '#727271',
            backgroundColor: blueHeader ? '#25BDFF' : '#e2e3eb'
          }}
        >
          О компании
        </h5>
      </Link>
      <div className="infoblock-item-about mb-3 ">
        <div
          className="img-container fixed-info-mw-150 mr-4"
          style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/boss.jpg")` }}
        />
        <div className="infoblock-item-about-list ">
          <ul>
            <li>
              <Link to={`${R.about}#komanda`}>Команда</Link>
            </li>
            <li>
                <Link to={`${R.about}#otzyvy`}>Отзывы</Link>
            </li>
            <li>
                <Link to={`${R.about}#lovko-remont`}>lovko ремонт</Link>
            </li>
          </ul>
        </div>
      </div>
      <Button
        boxshadow="half"
        blue={blueHeader ? false : true}
        gray={blueHeader ? true : false}
        size="small"
        className="align-self-end mt-auto"
      >
        <Link to={R.about}>подробнее</Link>
      </Button>
    </div>
  );
};

export const InfoBlockItemIdeas = ({ blueHeader }) => {
  return (
    <div className="infoblock-item">
      <Link to={R.ideas}>
        <h5
          className="infoblock-item-header mb-4 box-shadow-half"
          style={{
            color: blueHeader ? 'white' : '#727271',
            backgroundColor: blueHeader ? '#25BDFF' : '#e2e3eb'
          }}
        >
          Идеи для вдохновения
        </h5>
      </Link>
        {[MAIN_PAGE_IDEAS_CONTENT[Math.floor(Math.random()*MAIN_PAGE_IDEAS_CONTENT.length)]]
          .map((idea) => (
            <div key={idea.id} className="infoblock-item-about mb-3 ">
              <div
                className="img-container fixed-info-mw-150 mr-4"
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/ideas/${idea.id}/${idea.image}")` }}
              />
              <div className="infoblock-item-about-list">
                  <p>{idea.title}</p>
                  {idea.title.length > 15 && idea.title.length < 30 && <p className='infoblock-item-about-list-announce'>{idea.text.substring(0, 35)}</p>}
                  {idea.title.length <= 15 && <p className='infoblock-item-about-list-announce'>{idea.text.substring(0, 50)}</p>}
              </div>
            </div>
          ))}
      <Button
        boxshadow="half"
        blue={blueHeader ? false : true}
        gray={blueHeader ? true : false}
        size="small"
        className="align-self-end mt-auto"
      >
        <Link to={R.ideas}>подробнее</Link>
      </Button>
    </div>
  );
};

export const InfoBlockItemPromo = ({ blueHeader }) => {
  return (
    <div className="infoblock-item">
      <Link
        to={`${R.prices}${R.promotions}`}
        scroll={el =>
          el.scrollIntoView({
            behavior: 'instant',
            block: 'center'
          })
        }
      >
        <h5
          className="infoblock-item-header mb-4 box-shadow-half"
          style={{
            color: blueHeader ? 'white' : '#727271',
            backgroundColor: blueHeader ? '#25BDFF' : '#e2e3eb'
          }}
        >
          Акции
        </h5>
      </Link>
      <div className="infoblock-item-about mb-3 ">
        <div
          className="img-container fixed-info-mw-150 mr-4"
          style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/karcher-sc-2.jpg")` }}
        />
        <div className="infoblock-item-about-list line-height-small">
          <p>
            Пароочиститель <b>Karcher в подарок</b>
          </p>
          <p>
            <b>Скидка 5%</b> при заказе с сайта
          </p>
        </div>
      </div>
      <Button
        boxshadow="half"
        blue={blueHeader ? false : true}
        gray={blueHeader ? true : false}
        size="small"
        className="align-self-end mt-auto"
      >
        <Link
          to={`${R.prices}${R.promotions}`}
          scroll={el =>
            el.scrollIntoView({
              behavior: 'instant',
              block: 'center'
            })
          }
        >
          подробнее
        </Link>
      </Button>
    </div>
  );
};

export const InfoBlockItemProjects = ({ blueHeader }) => {
  const [modalProjectBlock, changeProjectModalVisibility] = useState(null);
  let projectModalClose = () => changeProjectModalVisibility(null);

  return (
    <div className="infoblock-item">
      <Link to={R.projects}>
        <h5
          className="infoblock-item-header mb-4 box-shadow-half"
          style={{
            color: blueHeader ? 'white' : '#727271',
            backgroundColor: blueHeader ? '#25BDFF' : '#e2e3eb'
          }}
        >
          Реализованные проекты
        </h5>
      </Link>
      <div className="infoblock-item-about mb-3 d-flex justify-content-around">
        {COMPLETED_PROJECTS
          .filter(el => el.smallImg !== undefined)
          .reverse()
          .slice(0, 2)
          .map((project) => (
            <div key={project.id}>
              <div
                onClick={() => {
                  changeProjectModalVisibility(project.id);
                }}
                className="img-container fixed-info-mw-150"
                style={{
                  backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/projects/${project.id}/${project.smallImg}")`,
                  cursor: 'pointer'
                }}
              >
              </div>
              <CompleteProjectElementModal
                project={project}
                show={modalProjectBlock === project.id ? true : false}
                onHide={projectModalClose}
              />
            </div>
          ))}
      </div>

      <Button
        boxshadow="half"
        blue={blueHeader ? false : true}
        gray={blueHeader ? true : false}
        size="small"
        className="align-self-end mt-auto"
      >
        <Link to={R.projects}>подробнее</Link>
      </Button>
    </div>
  );
};

export const InfoBlockItemAboutServices = ({ blueHeader }) => {
  return (
    <div className="infoblock-item">
      <Link to={R.services}>
        <h5
          className="infoblock-item-header mb-4 box-shadow-half"
          style={{
            color: blueHeader ? 'white' : '#727271',
            backgroundColor: blueHeader ? '#25BDFF' : '#e2e3eb'
          }}
        >
          Услуги
        </h5>
      </Link>
      <div className="infoblock-item-about mb-3 ">
        <div className="infoblock-item-about-list w-100">
          <ul>
            <li>
              <Link
                to={{
                  pathname: R.services,
                  hash: '#root',
                  state: {
                    servicesType: 'renovationOfApartments',
                    servicesTypeSecondary: undefined
                  }
                }}
              >
                Ремонт квартир
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: R.services,
                  hash: '#root',
                  state: {
                    servicesType: 'renovationOfСottage',
                    servicesTypeSecondary: undefined
                  }
                }}
              >
                Ремонт коттеджей
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: R.services,
                  hash: '#root',
                  state: {
                    servicesType: 'acceptanceOfOffice',
                    servicesTypeSecondary: undefined
                  }
                }}
              >
                Ремонт офисов, кафе
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Button
        boxshadow="half"
        blue={blueHeader ? false : true}
        gray={blueHeader ? true : false}
        size="small"
        className="align-self-end mt-auto"
      >
        <Link to={R.services}>подробнее</Link>
      </Button>
    </div>
  );
};
