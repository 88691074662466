import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from '../components/Button';
import {
  toggleModalAcceptanceOfApartments,
  toggleModalThanks,
  changePhone,
  changeName
} from '../actions.js';
import { Picture } from "react-responsive-picture";

class ModalAcceptanceOfApartments extends React.Component {
  constructor(props) {
    super(props);
    this.sendToViber = this.sendToViber.bind(this);
  }

  sendToViber(event) {
    event.preventDefault();
    let formData  = new FormData();

    formData.append('form', '"Заказать приемку квартиры"');
    for (let name in this.props.startRepairFormData) {
      formData.append(name, this.props.startRepairFormData[name]);
    }

    this.submitButton = event.target;
    this.sendForm = this.submitButton.parentNode;
    let sorry = document.createElement('div');
    sorry.classList.add('alert');
    sorry.classList.add('alert-danger');
    let pleaseText = document.createTextNode('Мы приносим свои извинения. Из-за технической проблемы введенная Вами информация не дошла до нас. Алексей будет рад помочь Вам, если Вы позвоните по номеру +375 (29) 561 02 88. Спасибо!');
    sorry.appendChild(pleaseText);
    this.sorry = sorry;

    fetch('/api/message', {
      'method': 'POST',
      'body': formData
    })
    .then(response => {
      if (response.ok) {
        this.props.toggleModalAcceptanceOfApartments(false);
        this.props.toggleModalThanks(true);
      } else {
        this.submitButton.innerHTML = 'заказать приемку квартиры';
        this.submitButton.disabled = false;
        this.sendForm.parentNode.insertBefore(this.sorry, this.sendForm);
        console.log(response);
      }
    })
    .catch(err => {
      this.submitButton.innerHTML = 'заказать приемку квартиры';
      this.submitButton.disabled = false;
      this.sendForm.parentNode.insertBefore(this.sorry, this.sendForm);
      console.log(err);
    });

    event.target.innerHTML = 'отправка';
    event.target.disabled = true;
    let spinner = document.createElement('span');
    spinner.classList.add('spinner-grow-sm');
    spinner.classList.add('spinner-grow');
    spinner.setAttribute('role', 'status');
    spinner.setAttribute('aria-hidden', true);
    event.target.appendChild(spinner);
  }

  render() {
    const {
      showModalAcceptanceOfApartments,
      toggleModalAcceptanceOfApartments,
      changePhone,
      changeName
    } = this.props;
    return (
      <Modal
        onHide={() => toggleModalAcceptanceOfApartments(false)}
        className="small-modal"
        show={showModalAcceptanceOfApartments}
        size="lg"
        centered
        aria-labelledby="Приемка квартиры"
      >
        <Modal.Header closeButton>
          <h3 className="text-center flex-grow-1 f-w-600 text-white mb-0">
            Заявка на приемку квартиры
          </h3>
        </Modal.Header>

        <Modal.Body className="text-gray">
          <p className="mb-0 text-size-18">
            Мы поможем Вам принять квартиру у застройщика (бесплатно).
          </p>
          <p className=" text-size-18">
            Услуга приемки квартиры включает комплексное обследование объекта
            опытным строителем и составления перечня дефектов
          </p>
          <h4 className="f-w-600 mb-3">
            Введите, пожалуйста, Ваши контактные данные:
          </h4>
          <Form className="form-fifth-step">
            <Form.Group controlId="name">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                onChange={e => changeName(e.target.value)}
                type="text"
                placeholder="Как Вас зовут"
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
                onChange={e => changePhone(e.target.value)}
                type="tel"
                placeholder="+375 (29) --- -- --"
              />
            </Form.Group>
            <Button
              onClick={this.sendToViber}
              style={{ padding: '10px 10px' }}
              className="text-right align-self-end line-height-1 flex-grow-1 box-shadow-half"
              size="small"
              blue
              boxshadow="half"
            >
              заказать приемку квартиры
            </Button>
          </Form>
          <div className="finish-step">
            <div className="finish-step-boss">
              <Picture
                srcSet={process.env.PUBLIC_URL + '/assets/images/boss.jpg'}
                sources={[
                  {srcSet: process.env.PUBLIC_URL + '/assets/images/boss.jpf', type: "image/jpm"},
                  {srcSet: process.env.PUBLIC_URL + '/assets/images/boss.webp', type: "image/webp"}
                  ]}
                alt="boss" />
            </div>
            <div className="finish-step-text">
              <p className="text-size-20 text-gray mb-3">
                Я <span className="f-w-600">позвоню Вам</span> в ближайшее
                время,
                <br />
                мы <span className="f-w-600">обсудим все вопросы</span> <br />и
                наши <span className="f-w-600">дальнейшие действия</span>
              </p>
              <div className="finish-step-footer text-right">
                <p>Алексей Бузо</p>
                <p>+375 (29) 561 02 88</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModalAcceptanceOfApartments: boolean => {
    dispatch(toggleModalAcceptanceOfApartments(boolean));
  },
  toggleModalThanks: boolean => {
    dispatch(toggleModalThanks(boolean));
  },
  changePhone: string => {
    dispatch(changePhone(string));
  },
  changeName: string => {
    dispatch(changeName(string));
  }
});

const mapStateToProps = state => ({
  showModalAcceptanceOfApartments: state.showModalAcceptanceOfApartments,
  startRepairFormData: state.startRepairFormData
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModalAcceptanceOfApartments);
