export const IDEA_7 = [
  {
    type: 'idea_with_img',
    img: '1-stil-provans.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Прованс: берег мечты.'
  },
  {
    type: 'idea_with_title',
    title: 'ОСОБЕННОСТИ',
    text: [
      'Во второй половине XVII века во Франции, в местности, которая так и называется Прованс, зародился этот уютный стиль. В те времена в городах у знати во всю господствовал ампир, классицизм. Прованс был своего рода противовесом этому.',
      'Прованс очень гармоничный стиль, он сочетает в себе простоту деревни и галантное изящество, романтизм и практичность с хозяйственной смекалкой, полет фантазии и достаточно скромную отделку.',
      'Этот стиль стал олицетворением мечты о беззаботности, спокойствии в деревне на берегу прекрасного моря.',
      'Прованс характеризуется спокойными, нежными цветами, балками на потолках, состаренной мебелью из дерева, применением натуральных тканей цветочных мотивов, и, конечно, всевозможными букетами.',
      'Для уставших горожан «прованский» интерьер – это оазис спокойствия и отдыха.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-stil-provans.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ЦВЕТА',
    text: [
      'Можно применять разнообразные цвета, однако они должны быть спокойными и приглушенными (оттенки молочного, белого, бежевого, оливкового, конечно же лавандового, цвета морских волн, охры, терракоты). Сочетают нежно-голубой с пастельно-желтым цветом, такое сочетание передает луга местности, в которой он возник. Также обязательно нужно отразить в таком интерьере присутствие моря…'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-stil-provans.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Для прованса характерно использование камня, натуральных тканей и дерева (дуб, каштан, орех).'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-stil-provans.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'Эти уютные дома и их убранство радовало не одно поколение. И каждое поколение добавляло что-то свое, но при этом и бережно относились ко всему, что им передали. Поэтому создавая интерьер в этом стиле нужно использовать «состаренную», крашенную мебель, чтобы подчеркнуть, что она служила многим поколениям.',
      'Это могут быть стулья с резными ножками, комоды и шкафе для посуды, плетеная мебель из лозы. В спальне же можно расположить изящную кровать из метала с кованными украшениями.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5-stil-provans.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОКНА и ОСВЕЩЕНИЕ',
    text: [
      'Прованс характеризуется хорошим освещением, в таких интерьерах должно быть светло и уютно. Хорошо, если окна будут максимально большими с белоснежными рамами. Шторы на окна делают небольшими и легкими (можно делать из хлопка, льна, ситца, батиста).',
      'Нужно предусмотреть, чтобы бала возможность по вечерам включать мягкий, рассеянный свет, который будет помогать настраиваться на расслабление и умиротворение. В качестве светильников можно применять люстры с кованными элементами. Лампочки-свечи добавят стилю аутентичности.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '6-stil-provans.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ, СТЕНЫ И ПОТОЛОК',
    text: [
      'На стены наносят штукатурку и красят ее в пастельные цвета (это могут быть оттенки молочного, карамельного, медового, кукурузного цвета). При этом покраска должна быть не идеально ровная, а имитирующая трещины и щербины. Для отделки выбирайте такие материалы как: камень, кирпич, дерево и обои с цветочными узорами.',
      'На потолок прикрепляют деревянные балки.',
      'Пол устилают досками, на них укладывают шерстяные ковры и тканые половики.',
      'На кухню и санузел укладывают крупную «состаренную» плитку светлых оттенков.',
      'Двери нужно делать светлыми с «присутствием времени».'
    ]
  },
  {
    type: 'idea_with_img',
    img: '7-stil-provans.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'Цветочки, цветочки, цветочки…. Для декорирования интерьера используют подушечки, чехлы на стулья, скатерти, пледы, покрывала – они могут быть однотонными или с мелким изображением цветов, животных, вышивкой. В керамических изделиях также присутствуют цветочные узоры. Применяют и кованные изделия, и элементы с утонченной ковкой (ковка может присутствовать в спинке кровати, вешалках для одежды, цветочных кашпо и т.д.)',
      'Камин прекрасно впишется в этот интерьер, его можно декорировать песчаником или сделать в тон стен.',
      'В этом интерьере приветствуется большое количество старых предметов быта: глиняная посуда, угольные утюги, наборы для рукоделия, птичьи клетки, подсвечники, горшки с лавандой, изделия выполненные в технике декупаж и многое другое. И, конечно, же фото родственников, бабушек и дедушек.',
      'А еще в вазах должны стоят живые цветы.'
    ]
  },
];
