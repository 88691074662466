import React from 'react';
import { connect } from 'react-redux';
import Button from '../components/Button';
import { toggleModal } from '../actions';

const OfferMobile = (props) => {
  return (
    <section className="offer-mobile">
      <div className="offer-mobile-blue-wrapper">
        <div className="offer-mobile-blue-text text-white text-right">
          <p style={{ fontSize: 26 }} className=" mb-0 line-height-small">РЕМНОНТ «под ключ»</p>
          <p style={{ fontSize: 20 }} className="mb-0 line-height-small">квартир, коттеджей, офисов</p>
        </div>
        <div className="d-flex mt-3">
          <Button
            className="ml-auto"
            boxshadow="full"
            size="large"
            onClick={() => props.toggleModal(true)}
          >
            НАЧАТЬ РЕМОНТ
          </Button>
        </div>
      </div>
    </section>
  )
}

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  },
});

export default connect(
  null,
  mapDispachToProps
)(OfferMobile);