import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toggleMobileNav } from '../actions';
import { HashLink as Link } from 'react-router-hash-link';

const R = {
  prices: '/stoimost-remonta',
  services: '/uslugi',
  about: '/o-kompanii',
  ideas: '/poleznye-stati',
  projects: '/realizovannye-proekty',
  promotions: '#akcii'
};

const MOBILE_NAV = [
  { text: 'Главная', to: '/' },
  { text: 'Реализованные проекты', to: `${R.projects}` },
  { text: 'Услуги', to: R.services },
  { text: 'Стоимость ремонта', to: R.prices },
  { text: 'Акции', to: `${R.prices}${R.promotions}` },
  { text: 'О комании', to: R.about },
  { text: 'Контакты', to: '#kontakty' }
];

const ModalMobileNav = props => {
  const { showMobileNav } = props;
  const [modalClass, changeClass] = useState(false);
  return (
    <Modal
      animation={false}
      className={'modal-header-nav ' + (modalClass ? 'animated' : null)}
      show={showMobileNav}
      onHide={() => {
        changeClass(false);
        setTimeout(() => props.toggleMobileNav(false), 200);
      }}
      backdropClassName={
        'modal-header-nav-backdrop ' + (modalClass ? 'animated' : null)
      }
      onShow={() => changeClass(true)}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="img-container pt-3 pl-3">
          <img src={process.env.PUBLIC_URL + '/assets/images/logo_header.png'} alt="header-logo" style={{ maxWidth: '75%' }} />
        </div>
        <ul className="modal-nav-mobil-list">
          {MOBILE_NAV.map((item, index) => (
            <li key={index}>
              <Link
                onClick={() => {
                  changeClass(false);
                  setTimeout(() => props.toggleMobileNav(false), 200);
                }}
                key={index}
                to={item.to}
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
        <div className="pt-3 justify-content-center f-w-600 d-flex align-items-center">
          <img src={process.env.PUBLIC_URL  + '/assets/images/phone-call.svg'} alt="phone" style={{ maxWidth: 30 }} />
          <a className="phone-link " href="tel:+375295610288">
            +375 (29) 561 02 88{' '}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  showMobileNav: state.showMobileNav
});

const mapDispachToProps = dispatch => ({
  toggleMobileNav: boolean => {
    dispatch(toggleMobileNav(boolean));
  }
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModalMobileNav);
