import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from '../components/Button';
import {
  toggleModalContactWithUs,
  toggleModalThanks,
  changePhone,
  changeName
} from '../actions.js';
import { Picture } from "react-responsive-picture";

class ModalContantWithUs extends React.Component {
  constructor(props) {
    super(props);
    this.sendContact = this.sendContact.bind(this);
  }

  sendContact(event) {
    event.preventDefault();
    var formData  = new FormData();

    formData.append('form', '"Связаться" и хочет получить консультацию');
    for (var name in this.props.startRepairFormData) {
        formData.append(name, this.props.startRepairFormData[name]);
    }

    this.submitButton = event.target;
    this.sendForm = this.submitButton.parentNode;
    var sorry = document.createElement('div');
    sorry.classList.add('alert');
    sorry.classList.add('alert-danger');
    var pleaseText = document.createTextNode('Мы приносим свои извинения. Из-за технической проблемы введенная Вами информация не дошла до нас. Алексей будет рад помочь Вам, если Вы позвоните по номеру +375 (29) 561 02 88. Спасибо!');
    sorry.appendChild(pleaseText);
    this.sorry = sorry;

    fetch('/api/message', {
      'method': 'POST',
      'body': formData
    })
    .then(response => {
      if (response.ok) {
          this.props.toggleModalContactWithUs(false);
          this.props.toggleModalThanks(true);
      } else {
          this.submitButton.innerHTML = 'связаться';
          this.submitButton.disabled = false;
          this.sendForm.parentNode.insertBefore(this.sorry, this.sendForm);
          console.log(response);
      }
    })
    .catch(err => {
      this.submitButton.innerHTML = 'связаться';
      this.submitButton.disabled = false;
      this.sendForm.parentNode.insertBefore(this.sorry, this.sendForm);
      console.log(err);
    });

    event.target.innerHTML = 'отправка';
    event.target.disabled = true;
    var spinner = document.createElement('span');
    spinner.classList.add('spinner-grow-sm');
    spinner.classList.add('spinner-grow');
    spinner.setAttribute('role', 'status');
    spinner.setAttribute('aria-hidden', true);
    event.target.appendChild(spinner);
  }

  render() {
    const {
      showModalContactWithUs,
      toggleModalContactWithUs,
      changePhone,
      changeName
    } = this.props;
    return (
      <Modal
        onHide={() => toggleModalContactWithUs(false)}
        className="small-modal"
        show={showModalContactWithUs}
        size="lg"
        centered
        aria-labelledby="Приемка квартиры"
      >
        <Modal.Header closeButton>
          <h3 className="text-center flex-grow-1 f-w-600 text-white mb-0">
            Консультация профессионального строителя
          </h3>
        </Modal.Header>
        <Modal.Body className="text-gray">
          <h4 className="f-w-600 mb-3">
            Введите, пожалуйста, Ваши контактные данные:
          </h4>
          <Form className="form-fifth-step">
            <Form.Group controlId="name">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                onChange={e => changeName(e.target.value)}
                type="text"
                placeholder="Как Вас зовут"
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
                onChange={e => changePhone(e.target.value)}
                type="tel"
                placeholder="+375 (29) --- -- --"
              />
            </Form.Group>
            <Button
              onClick={this.sendContact}
              type='submit'
              style={{ padding: '10px 10px' }}
              className="text-center align-self-end line-height-1 flex-grow-1"
              size="small"
              blue
              boxshasow="half"
            >
              связаться
            </Button>
          </Form>
          <div className="finish-step">
            <div className="finish-step-boss">
              <Picture
                  srcSet= {process.env.PUBLIC_URL + '/assets/images/boss.jpg'}
                  sources={[
                    {srcSet: process.env.PUBLIC_URL + '/assets/images/boss.jpf', type: "image/jpm"},
                    {srcSet: process.env.PUBLIC_URL + '/assets/images/boss.webp', type: "image/webp"}
                  ]}
                  alt="boss" />
            </div>
            <div className="finish-step-text">
              <p className="text-size-20 text-gray mb-3">
                Я <span className="f-w-600">позвоню Вам</span> в ближайшее
                время,
                <br />
                мы <span className="f-w-600">обсудим все вопросы</span> <br />и
                наши <span className="f-w-600">дальнейшие действия</span>
              </p>
              <div className="finish-step-footer text-right">
                <p>Алексей Бузо</p>
                <p>+375 (29) 561 02 88</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModalContactWithUs: boolean => {
    dispatch(toggleModalContactWithUs(boolean));
  },
  toggleModalThanks: boolean => {
    dispatch(toggleModalThanks(boolean));
  },
  changePhone: string => {
    dispatch(changePhone(string));
  },
  changeName: string => {
    dispatch(changeName(string));
  }
});

const mapStateToProps = state => ({
  showModalContactWithUs: state.showModalContactWithUs,
  startRepairFormData: state.startRepairFormData
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModalContantWithUs);
