import { ServicesElems as S } from './constants';

export const ELECTROMONTAZH = [
  {
    type: S.withImg,
    img: 'electomon-1.jpg'
  },
  {
    type: S.withLine,
    text:
      'Электромонтажные работы являются комплексом строительных мероприятий, которые проводятся во время строительства или ремонта. Начинаются данные мероприятия работой над проектом электрификации помещений, а заканчиваются установкой выключателей и светильников. От того, на сколько качественно будут проведены электромонтажные работы, зависит безопасность, долговечность и бесперебойное функционирование электроприборов.'
  },
  {
    type: S.withList,
    title: 'Составляющие части электрической сети:',
    list: [
      'Автоматические аппараты защиты, находящиеся в электрощите. От качества данных устройств и от их правильного монтажа зависит надежность работы всей техники в доме и безопасность жильцов.',
      'Провода, кабеля с качественной изоляцией и правильно подобранным сечением.',
      'Выключатели и розетки с безопасными корпусами и с контактами хорошего качества.'
    ]
  },
  {
    type: S.withList,
    title:
      'Проведение комплексного ремонта предусматривает выполнение следующих электромонтажных работ:',
    list: [
      'монтаж электроточки;',
      'монтаж распределительных коробок;',
      'монтаж электрического щитка и его подключение;',
      'прокладывание UTP, телефонного, силового и антенного кабелей;',
      'монтаж слаботочного щитка;',
      'установка теплых полов;',
      'установка выключателей/розеток;',
      'установка светильников;',
      'установка системы «Умный дом».'
    ]
  },
  {
    type: S.withTitle,
    title: 'МОНТАЖ ЭЛЕКТРОТОЧКИ',
    text:
      'Электроточка — это обширное понятие, и оно является ключевым во время планирования, а также устройства электрики в помещении любого типа. Под электроточкой имеется ввиду то место, в котором будет установлен выключатель или розетка. Однако, к примеру, если в помещении планируется установка 4-х выключателей или розеток, тогда нужно произвести подготовку 3-х электроточек. Электроточками могут быть разные объекты: люстры, розетки, точечные светильники, выключатели и т.д. Если обобщить, то электроточка - это любой объект, который передает электрическую энергию или потребляет ее. Электроточки — это внутренние элементы. Устройство электроточки, чаще всего, производится на бетонную или кирпичную стену (также возможна установка на стену из гипсокартона).'
  },
  {
    type: S.withImg,
    img: 'electomon-2.jpg'
  },
  {
    type: S.withTitle,
    title: 'МОНТАЖ РАСПРЕДЕЛИТЕЛЬНЫХ КОРОБОК',
    text:
      'Данные электромонтажные изделия выполняют важную функцию. С их помощью обеспечивается распределение электропроводов между розетками, приборами освещения и выключателями. '
  },
  {
    type: S.withImg,
    img: 'electomon-3.jpg'
  },
  {
    type: S.withTitle,
    title: 'МОНТАЖ ЭЛЕКТРИЧЕСКОГО ЩИТКА И ЕГО ПОДКЛЮЧЕНИЕ',
    text:
      'Электрощиток — это устройство, распределяющее электрическую энергию по всему дому. Электрическим щитком выполняется большое количество разных функций, отвечающих за безопасность, защиту электрической проводки от перегрузки, а также распределение электричества по потребителям. '
  },
  {
    type: S.withList,
    title:
      'Установка электрического щитка может быть произведена 2-мя способами:',
    list: ['установка в стене;', 'крепление на стену.']
  },
  {
    type: S.withImg,
    img: 'electomon-4.jpg'
  },
  {
    type: S.withTitle,
    title: 'МОНТАЖ СЛАБОТОЧНОГО ЩИТКА',
    text:
      'Мультимедийные или слаботочные щитки предназначены для инсталляции разных типов сетевого оборудования в небольших офисах, коттеджах/частных домах или квартирах.'
  },
  {
    type: S.withList,
    title: 'В слаботочном щитке происходит подключение:',
    list: [
      'wi-fi-точек: роутер, контрооллер;',
      'точек интернет-провайдера: роутер провайдера, витая пара, оптоволокно;',
      'компьютерной сети: роутер, коммутатор;',
      'точек подключения провайдеров кабельного ТВ: разветвители, коаксиальный кабель;',
      'системы «Умный дом»: управляющие устройства;',
      'домофона: подключение видеодомофона и подъездного домофона;',
      'охранной сигнализации: кабели подключения датчиков, блок охранной сигнализации;',
      'системы видеонаблюдения: роутер или регистратор и витая пара камер наблюдения.'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Слаботочные щитки дают возможность систематизированно разместить сетевые соединители, модем, wi-fi-роутер и др. в одном месте. Защитить это оборудование от детей, домашних питомцев, а также от любого внешнего воздействия. Также с их помощью можно оперативно добавлять новые линии или менять назначение уже существующих.'
  },
  {
    type: S.withImg,
    img: 'electomon-5.jpg'
  },
  {
    type: S.withTitle,
    title: 'УСТАНОВКА ТЕПЛЫХ ПОЛОВ',
    text:
      '<p>Использование теплого пола дает возможность удерживать максимально комфортную температуру, изменяя показатели по своему усмотрению. Обогрев дома при помощи теплого пола — это отличная альтернатива радиаторному отоплению, благодаря которой минимизируются расходы на энергоресурсы. Еще одним «плюсом» данной системы обогрева является большой выбор методов ее реализации.</p><p>Но независимо от метода реализации, а также используемого материала, принцип функционирования ТП будет одинаковым. Теплый пол постепенно нагревает воздух в нижней части комнаты по всей ее площади, в результате чего он начинает подниматься вверх и отапливает все помещение.</p>'
  },
  {
    type: S.withQuote,
    text: 'Теплые полы делятся на два типа: водяные и электрические.'
  },
  {
    type: S.withTitle,
    text:
      '<p>Нагревающим элементом в водяных ТП является вода, которая течет по трубам. Однако, чтобы нагреть воду необходим котел, а также насос, который заставит воду циркулировать в системе.</p><p>Электрические ТП преобразуют электроэнергию в тепло при помощи одного из видов нагревательных устройств. Данные устройства по принципу работы делятся на 2 типа: ИК нагреватели и греющие кабели.</p><p>В квартире легче всего установить ИК пленочный обогрев. В коттедже или загородном доме можно использовать любой вариант. Все варианты можно совмещать с «традиционными» радиаторами отопления.</p><p>Если Вы желаете установить у себя теплый пол, наши квалифицированные специалисты помогут Вам в этом и произведут все необходимые электромонтажные работы (Беларусь), используя современное оборудование и качественные материалы.</p>'
  },
  {
    type: S.withImg,
    img: 'electomon-6.jpg'
  },
  {
    type: S.withTitle,
    title: 'УСТАНОВКА ВЫКЛЮЧАТЕЛЕЙ / РОЗЕТОК / СВЕТИЛЬНИКОВ',
    text:
      '<p>Электрические выключатели и розетки являются обязательными элементами в любом доме. От их правильной установки зависит безопасность функционирования электрических приборов, надежность и долговечность. Электромонтажные работы в Минске выполняются специалистами нашей компании быстро и качественно. Мастера произведут подключение выключателей и розеток придерживаясь всех правил безопасности, а также учитывая Ваши пожелания.</p><p>Процесс установки светильников зависит от способа их монтажа. По данному признаку все светильники делятся на: напольные, встраиваемые, настенные и потолочные.</p>'
  },
  {
    type: S.withImg,
    img: 'electomon-7.jpg'
  },
  {
    type: S.withTitle,
    title: 'СИСТЕМА «УМНЫЙ ДОМ»',
    text:
      'Электрические выключатели и розетки являются обязательными элементами в любом доме. От их правильной установки зависит безопасность функционирования электрических приборов, надежность и долговечность. Электромонтажные работы в Минске выполняются специалистами нашей компании быстро и качественно. Мастера произведут подключение выключателей и розеток придерживаясь всех правил безопасности, а также учитывая Ваши пожелания.Процесс установки светильников зависит от способа их монтажа. По данному признаку все светильники делятся на: напольные, встраиваемые, настенные и потолочные.'
  },
  {
    type: S.withList,
    title:
      'Данная система домашней автоматизации состоит их 3-х основных элементов:',
    list: [
      'датчики, получающие информацию;',
      'центральный контроллер, обрабатывающий полученные данные и принимающий решение;',
      'приборы, выполняющие разные задачи и облегчающие быт человека.  '
    ]
  },
  {
    type: S.withImg,
    img: 'electomon-8.jpg'
  },
  {
    type: S.withTitle,
    text:
      ' Приведем еще несколько примеров электромонтажных работ студии ремонта lovko:'
  },
  {
    type: S.withImg,
    img: 'electomon-9.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-10.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-11.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-12.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-13.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-14.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-15.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-16.jpeg'
  },
  {
    type: S.withImg,
    img: 'electomon-17.jpg'
  },
  {
    type: S.withImg,
    img: 'electomon-18.jpeg'
  }
];
