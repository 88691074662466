import React from 'react';
import {
  toggleModal,
  changeRepair,
  changeObjectArea,
  changeDesignProject,
  changeDeadline,
  changePhone,
  changeName
} from '../actions.js';
import { connect } from 'react-redux';
import { Modal, ProgressBar } from 'react-bootstrap';
import Button from '../components/Button';
import StepperForm from '../components/StepperForm';

class Stepper extends React.Component {
  state = { step: 1 };
  changeStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };
  constructor(props) {
    super(props);
    this.sendToViber = this.sendToViber.bind(this);
  }

  sendToViber(event) {
    event.preventDefault();
    let formData  = new FormData();

    formData.append('form', '"Начать ремонт"');
    for (let name in this.props.startRepairFormData) {
      formData.append(name, this.props.startRepairFormData[name]);
    }

    this.submitButton = event.target;
    this.sendForm = this.submitButton.parentNode;
    let sorry = document.createElement('div');
    sorry.classList.add('alert');
    sorry.classList.add('alert-danger');
    let pleaseText = document.createTextNode('Мы приносим свои извинения. Из-за технической проблемы введенная Вами информация не дошла до нас. Алексей будет рад помочь Вам, если Вы позвоните по номеру +375 (29) 561 02 88. Спасибо!');
    sorry.appendChild(pleaseText);
    this.sorry = sorry;

    fetch('/api/message', {
      'method': 'POST',
      'body': formData
    })
    .then(response => {
      if (response.ok) {
        const { step } = this.state;
        this.setState({ step: step + 1 });
      } else {
        this.submitButton.innerHTML = 'отправить';
        this.submitButton.disabled = false;
        this.sendForm.parentNode.insertBefore(this.sorry, this.sendForm);
        console.log(response);
      }
    })
    .catch(err => {
      this.submitButton.innerHTML = 'отправить';
      this.submitButton.disabled = false;
      this.sendForm.parentNode.insertBefore(this.sorry, this.sendForm);
      console.log(err);
    });

    event.target.innerHTML = 'отправка';
    event.target.disabled = true;
    let spinner = document.createElement('span');
    spinner.classList.add('spinner-grow-sm');
    spinner.classList.add('spinner-grow');
    spinner.setAttribute('role', 'status');
    spinner.setAttribute('aria-hidden', true);
    event.target.appendChild(spinner);
  }

  render() {
    const {
      showModalStartRepair,
      toggleModal,
      changeRepair,
      changeObjectArea,
      changeDesignProject,
      changeDeadline,
      changePhone,
      changeName
    } = this.props;
    const { step } = this.state;
    return (
      <Modal
        onHide={() => toggleModal(false)}
        className="modal-stepper"
        show={showModalStartRepair}
        size="lg"
        aria-labelledby="Начать ремонт"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="modal-body-left text-white">
            {step < 5 && (
              <p className="text-size-18 line-height-small mb-auto">
                С каждым <br className="d-none d-md-block" /> новым ответом
                <br className="d-none d-md-block" /> Ваша
                <br />{' '}
                <span className="f-w-600 text-size-24">скидка растет!</span>
              </p>
            )}
            {step === 6 && (
              <div className="img-container text-center mb-auto">
                <img
                  style={{ maxWidth: '80%', marginTop: 20 }}
                  src={process.env.PUBLIC_URL + '/assets/images/logo_white.png'}
                  alt="Логотип компании"
                />
              </div>
            )}
            <p className="text-uppercase text-size-24 text-center mb-1 d-none d-md-flex">
              Ваша скидка :
            </p>
            <p
              style={{
                letterSpacing: step !== 6 ? 8 : 4,
                fontSize: step !== 6 ? 80 : 90
              }}
              className="f-w-700 text-size-80 mb-0 line-height-1 text-center"
            >
              +{step !== 6 ? step : 5}%
            </p>
          </div>
          <div className="modal-body-right text-gray">
            {step !== 6 && (
              <div className="modal-body-right-header ">
                {step === 1 && (
                  <div className="modal-body-right-header-text  text-size-24">
                    <p
                      style={{ lineHeight: 1.4 }}
                      className="text-uppercase mb-0 text-right"
                    >
                      Давайте начнем
                    </p>
                    <p style={{ lineHeight: 1.4 }} className="mb-0  text-right">
                      Ваш первый
                    </p>
                  </div>
                )}
                <div className="modal-body-right-header-logo">
                  <img src={process.env.PUBLIC_URL + '/assets/images/lovko_remont.svg'} alt="lovko ремонт" />
                </div>
              </div>
            )}
            {step !== 6 && (
              <p className="mb-md-4 mb-2">
                Ответьте, пожалуйста, на 5{' '}
                <span className="f-w-600">вопросов</span> и мы{' '}
                <span className="f-w-600">свяжемся с Вами</span> в ближайшее
                время
              </p>
            )}
            {step !== 6 && <ProgressBar now={20 * step} />}

            <StepperForm
              changeRepair={changeRepair}
              changeObjectArea={changeObjectArea}
              changeDesignProject={changeDesignProject}
              changeDeadline={changeDeadline}
              changePhone={changePhone}
              changeName={changeName}
              className="mb-3"
              step={step}
            />
            <div className="ml-auto mt-auto">
              {step !== 6 && (
                <Button
                  blue
                  size="small"
                  boxshadow="half"
                  onClick={(step !== 5 && this.changeStep ) || this.sendToViber}
                >
                    {step === 5 && 'отправить'}
                    {step !== 5 && 'далее'}
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showModalStartRepair: state.showModalStartRepair,
  startRepairFormData: state.startRepairFormData
});

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  },
  changeRepair: string => {
    dispatch(changeRepair(string));
  },
  changeObjectArea: string => {
    dispatch(changeObjectArea(string));
  },
  changeDesignProject: string => {
    dispatch(changeDesignProject(string));
  },
  changeDeadline: string => {
    dispatch(changeDeadline(string));
  },
  changePhone: string => {
    dispatch(changePhone(string));
  },
  changeName: string => {
    dispatch(changeName(string));
  }
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(Stepper);
