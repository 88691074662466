import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toggleModal, toggleModalAcceptanceOfApartments } from '../actions';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Banner from '../containers/Banner';
import Ideas from '../containers/Ideas';
import History from '../containers/History';
import InstagramMobile from '../containers/InstagramMobile';
import Ceo from '../containers/Ceo';
import Promotions from '../containers/Promotions';
import Prices from '../containers/Prices';
import Projects from '../containers/Projects';
import VideoBlock from '../containers/VideoBlock';
import OfferMobile from '../containers/OfferMobile';
import Services from '../containers/Services';
import Dropdowns from '../containers/Dropdowns';
import Button from '../components/Button';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';

const CeoText = (
  <React.Fragment>
    <p className="mb-0 mt-3 mt-md-0  text-md-left text-gray text-small-14">
      Алексей Бузо
    </p>
    <p className="mb-3 mb-md-5 text-gray  text-md-left text-small-14">
      Основатель и руководитель студии ремонта «lovko»
    </p>
    <div className="ceo-main-text block-quote">
      <p className="mb-0 text-gray">
        Занимаюсь ремонтами уже <b>более 12 лет.</b>
      </p>
      <p className="mb-3 text-gray">
        В своей работе основной акцент делаю на <br />
        <b>клиентский сервис, качество</b> и{' '}
        <b>эффективную организацию процесса</b> ремонтно-отделочных работ.
      </p>
      <p className="mb-0 text-gray">
        Считаю, что
        <b> ремонт - это интересно!</b>
      </p>
      <p className="mb-0 text-gray">
        Мне нравится видеть как дизайн-проект «оживает» в реальной квартире и
        радует наших Клиентов.
      </p>
    </div>
  </React.Fragment>
);
class Main extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TitleAndDesc.mainPage.title}</title>
          <meta name="description" content={TitleAndDesc.mainPage.desc} />
        </Helmet>
        <Header />
        <VideoBlock />
        <OfferMobile />
        <Dropdowns hidemobile />
        <Banner className="d-none d-md-block">
          <div className="text-center text-md-left">
            <h3 className="text-white">
              <span className="f-w-300">Поможем Вам</span> принять квартиру
              <br />
              <span className="f-w-300">у застройщика (бесплатно)</span>
            </h3>
          </div>
          <Button
            onClick={() => this.props.toggleModalAcceptanceOfApartments(true)}
            boxshadow="full"
            size="large"
            className="flex-grow-1 flex-grow-md-0"
          >
            заказать приемку квартиры
          </Button>
        </Banner>
        <Services />
        <Projects withTitle projectsCount={9} />
        <Prices />
        <Promotions />
        <Banner className="d-none d-md-flex">
          <div>
            <h3 className="text-white">
              <span className="f-w-300">Заказать</span> ремонт <br />
              <span className="f-w-300">и получить</span> скидку 5%{' '}
              <span className="f-w-400">на наши услуги </span>?
            </h3>
          </div>
          <Button
            className="flex-grow-1 flex-md-grow-0 mt-2 mt-md-0"
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModal(true)}
          >
            НАЧАТЬ РЕМОНТ
          </Button>
        </Banner>
        <Ceo text={CeoText} />
        <InstagramMobile />
        <History historyclass="history-mainpage d-none d-md-flex">
          <h2 className="h2-large text-uppercase text-right text-white mb-0">
            <span className="f-w-400">У каждого ремонта</span>
            <br />
            своя история
          </h2>
        </History>
        <Ideas withTitle ideasCount={4} />
        <Banner className="d-none d-md-flex">
          <div>
            <h3 className="text-white">
              Готовы начать <span className="f-w-300">свой первый</span> lovko
              ремонт <br />
              <span className="f-w-400">и получить </span>
              скидку 5%?
            </h3>
          </div>
          <Button
            className="flex-grow-1 flex-md-grow-0 mt-2 mt-md-0"
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModal(true)}
          >
            НАЧАТЬ РЕМОНТ
          </Button>
        </Banner>
        <Footer />
      </Fragment>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  },
  toggleModalAcceptanceOfApartments: boolean => {
    dispatch(toggleModalAcceptanceOfApartments(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(withRouter(Main));
