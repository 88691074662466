import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/Button';
import { PRICES_ITEMS } from '../constants';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { toggleModal } from '../actions';
import { R } from '../Routes';

const Prices = props => {
  return (
    <section className="prices">
      <Container>
        <Row>
          <Col>
            <div className="prices-header">
              <h2 className="text-gray f-w-400 text-center text-uppercase h2-small mb-1">
                Стоимость ремонта
              </h2>
              <p className="text-blue f-w-400 text-size-20 text-center ">
                Расcчитывается за м<sup>2</sup>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="prices-items">
          {PRICES_ITEMS.map(item => (
            <Col key={item.id} sm={12}>
              <div className="prices-item">
                <div
                  className="prices-item-attr"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${item.addImg}.png)`
                  }}
                />
                <div className="prices-item-main">
                  <div
                    className="prices-item-main-img"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${item.mainImg})`
                    }}
                  />
                  <div className="prices-item-main-description">
                    <h4 className="text-uppercase text-gray f-w-400 mb-1">
                      {item.title}
                    </h4>
                    <p className="prices-item-main-description-list text-small-14 text-gray">
                      {item.text}
                    </p>
                  </div>
                </div>
                <div className="prices-item-button">
                  <Button size="small" boxshadow="full" blue>
                    <Link
                      to={{
                        pathname: R.services,
                        state: {
                          servicesType: 'renovationOfApartments',
                          servicesTypeSecondary: item.route
                        }
                      }}
                    >
                      от {item.price} у.е./м<sup>2</sup>
                    </Link>
                  </Button>
                </div>
              </div>
            </Col>
          ))}
          <Col sm={12}>
            <div className="prices-button">
              <Button
                onClick={() => props.toggleModal(true)}
                boxshadow="full"
                size="medium"
              >
                НАЧАТЬ РЕМОНТ
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(Prices);
