export const IDEA_9 = [
  {
    type: 'idea_with_img',
    img: '1-indukcionnaya-pilta.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Индукционная плита - это кухонная электрическая плита, которая нагревает металлическую посуду индукционными вихревыми токами, которые создаются высокочастотным магнитным полем. Его частота от 20 до 100 килогерц.'
  },
  {
    type: 'idea_with_title',
    title: 'ПОСУДА ДЛЯ ИНДУКЦИОННЫХ ПЛИТ',
    text: [
      'При покупке индукционной плиты нужно быть готовым к покупке и подходящей для этого посуды.',
      'Рекомендуется выбирать ту посуду, на которой есть специальный знак (таким знаком будет спираль, которая состоит из небольших петелек и слово Induction), говорящий о том, что посуда подходит для приготовления пищи на плите с индукцией.'
    ]
  },
  {
    type: 'idea_with_list',
    title: 'От выбора материала посуды зависят не только вкусовые качества, но и эксплуатационные характеристики:',
    list: [
      'Нержавеющая сталь. Преимущества: устойчива к окислению (благодаря чему пища, приготовленная в такой посуде, сохраняет свои полезные свойства и ее можно хранить в холодильнике в кастрюле, в которой готовили), прочный материал, малый вес, дизайн. Среди недостатков нет антипригарного слоя, в процессе приготовления появляются царапины. Обращаем Ваше внимание, что не все образцы из нержавейки обладают ферромагнитными свойствами, поэтому для определения подходит ли такая посуда для индукционной плиты, нужно выяснить данной свойство.',
      'Чугунные сковородки. Достоинства: быстро и равномерно нагревается, пища не пригорает, сковородки прочные и долговечные; хорошо удерживают тепло, материал химически инертный (это очень важно для здоровья человека), благодаря чему он не вступает в контакт с любыми продуктами и, как следствие, не вызывает неприятных последствий. Среди минусов: большой вес (при случайном падении на стеклокерамическую поверхность может ее повредить), незащищенные стенки впитывают запахи еды и постепенно подвергаются коррозийным явлениям; несмотря на массивный вид, такая посуда достаточно хрупка. Однако чугун является хорошим материалом, из которого делают посуду для индукционных плит.',
      'Металлические с эмалью. При выборе кастрюль можно обратить внимание на металлическую с покрытием из эмали. У нее такие достоинства: долговечность и прочность, невысокая цена, дизайн.'
    ]
  },
  {
    type: 'idea_with_text',
    text: [
      'Выбирая посуду для индукционки нужно смотреть и на дно посуды. У некоторых кастрюль оно может быть округлой формы, это создаст возвышение и при нагреве можно будет услышать шум. Посуда, предназначенная для использования на таких плитах, должна быть с плоским дном. А еще оно должно быть с минимальной толщиной 3 мм (чем толще дно, тем быстрее нагревается кастрюля).',
      'Еще одним существенным фактором является то, что диаметр кастрюле должен соответствовать диаметру конфорки (эти размеры вы можете узнать в информации от производителя).'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-indukcionnaya-pilta.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПРЕИМУЩЕСТВА ИНДУКЦИОННЫХ ПЛИТ',
    text: []
  },
  {
    type: 'idea_with_list',
    list: [
      'Плита нагревает только посуду и быстро остывает, поэтому снижается риск получения ожогов в результате прикосновения к поверхности плиты,',
      'Чистить поверхность индукционной плиты легко и ее можно начать сразу после окончания приготовления,',
      'Нагревание посуды начинает происходить сразу с момента включения плиты и заданным уровнем мощности,',
      'Коэффициент полезно действия индукционных плит 90 процентов (у электрических - 70%, у газовых – 50%),',
      '«интеллектуальная» особенность – при снимании с поверхности плиты посуды, она автоматически выключается,',
      'Индукционные плиты нагревают посуду, но не повышают при этом температуру воздуха в помещении,',
      'При приготовлении пищи на такой плите возможно точное соблюдение температурного режима,',
      'Не зависит от мощности напряжения в электросети,',
      'Электроемкость меньше, чем у электрических плит.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-indukcionnaya-pilta.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'НЕДОСТАТКИ ИНДУКЦИОННЫХ ПЛИТ',
    text: []
  },
  {
    type: 'idea_with_list',
    list: [
      'Людям с кардиостимуляторами, нужно быть осторожными и внимательными с данным прибором, поскольку генерируемые поля могут повлиять на работу дефибриллятора и кардиостимулятора и стать причиной сбоев в его работе. Специалисты обозначают расстояние в 50 сантиметров минимальным и оптимальным для людей с вживленными кардиостимуляторами.',
      'Излучение не влияет на организм, только если посуда полностью перекрывает площадь конфорки и безопасным является расстояние 30 см от варочной панели. Т.е. такие варочные панели не подходят для готовки детьми, людьми небольшого роста и беременными. В случае несоблюдения мер безопасности наведенные токи проникают в организм и это отрицательно влияет на нервную систему.',
      'Противники индукционных плит утверждают, что мясо, приготовленное с помощью индукции, полностью лишается тиамина – одного из своих главных компонентов. В свежих фруктах и овощах остается только 25% полезных витаминов. Хотя официальных данных по этому поводу нет. Те кто за индукционные плиты утверждают, что магнитные волны индукционной плиты воздействуют только на посуду, а в микроволновке, например, непосредственно на разогреваемые продукты.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-indukcionnaya-pilta.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ВРЕДНЫ ЛИ ИНДУКЦИОННЫЕ ПЛИТЫ',
    text: [
      'По поводу пользы и вреда индукционных плит есть много мнений и споров.',
      'В ходе тестов ученые, в том числе работающие в ВОЗ (Всемирная организация здравоохранения), пришли к выводу, что несмотря на то, что было проведено мало исследований индукционные плиты не наносят существенный вред здоровью.'
    ]
  },
  {
    type: 'idea_with_list',
    title: 'Такой вывод был сделан на основании следующего:',
    list: [
      'Использование неподходящей посуды влияет на повышение уровня излучения, а также ее смещение относительно центра конфорки,',
      'На расстоянии 30 см от плиты уровень излучения снижается до 0, т.е. на таком расстоянии устройство может причинить возможный вред только при длительном нахождении от плиты (более 2 часов) детям, беременным, людям невысокого роста,',
      'Индукция может оказывать влияние на работу находящихся поблизости электронных устройств'
    ]
  },
  {
    type: 'idea_with_quote',
    text: 'Важно! Максимальное безопасное время пребывания у индукционной плиты составляет 2 часа.'
  },
  {
    type: 'idea_with_list',
    title: 'Швейцарские исследователи из Федерального центра общественного здоровья (FOPH) установили, что при работе портативной индукционной плиты излучение ведет себя таким образом:',
    list: [
      'Постоянно превышает нормы на расстоянии 1 см,',
      'При использовании неподходящей посуды – на расстоянии 20 см,',
      'При смещении дна посуды относительно центра конфорки – на расстоянии 12 см.'
    ]
  },
  {
    type: 'idea_with_text',
    text: [
      'Есть большое количество форумов и видео, где спорят по поводу вредности индукции. Однако очевидно, что нужны более обширные независимые научные исследования.',
      'В данный момент каждый потенциальный пользователь должен сам решать, покупать или нет ему индукционную плиту, путем взвешивания всех за и против. Как говорят сторонники индукционных плит, если придерживаться правил эксплуатации плиты, то воздействие электромагнитного поля будет небольшим. Эти дозы могут либо не оказывать существенного влияния на человеческий организм, либо дать о себе знать через довольно большой промежуток времени, когда установить связь явлений уже будет невозможно.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5-indukcionnaya-pilta.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ТЕХНИКА БЕЗОПАСНОСТИ ПРИ ЭКСПЛУАТАЦИИ ПЛИТЫ',
    text: [
      'После того как появились исследования о том, что электромагнитное поле влияет на тело человека, производители индукционных плит начали разрабатывать новые способы, которые позволят уменьшить это негативное влияние. Такими мерами стали, датчики (устанавливаются в плиты), которые не дадут плите начать работать, без посуды на конфорке, либо если она сдвинута от центра. Еще теперь индукционные катушки делают со специальным слоем графитовым, который экранирует создаваемые токи.'
    ]
  },
  {
    type: 'idea_with_list',
    title: 'Но самым важным в пользовании индукционными плитами является соблюдении правил безопасности:',
    list: [
      'Перед подключением плиты нужно ознакомиться с инструкцией и правилами безопасной установки и использования,',
      'Правильно выбирать посуду для готовки на таких плитах: идеальный вариант – отмеченная специальным знаком, которая по размерам соответствует диаметру конфорки и имеет ровно (без изъянов) дно.',
      'Воздержаться от использования металлических предметов для перемешивания пищи во время приготовления,',
      'Не следует находиться у работающей плиты более 2 часов,',
      'Из-за малого количества исследований беременность может быть фактором, ограничивающим время пребывания у плиты на расстоянии менее 30 см.',
      'Безопасное расстояние от плиты для людей с кардиостимуляторами или дефибрилляторами 50 см, однако во избежание сбоев в работе имплантированных в организм устройств лучше воздержаться от покупки подобного прибора.'
    ]
  },
];
