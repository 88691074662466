export const IDEA_1 = [
  {
    type: 'idea_with_img',
    img: '1_antichnyi-stil.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Античный интерьер называют "вечным". Древний Рим и Эллада с их историей и культурой воплощаются в этом интерьере.'
  },
  {
    type: 'idea_with_title',
    title: 'ОТЛИЧИТЕЛЬНЫЕ ОСОБЕННОСТИ',
    text: [
      'Чем же характеризуется этот интерьер? Арками, греческими колоннами, фресками, росписью стен, антикварной мебелью, столами (ножки в них делают на подобие лап животных или изображений химер), натуральными материалами, мраморными скульптурами, гобеленами.',
      'Что касается арок и колонн, то их можно делать несущими, либо же декоративными, нарисованными.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2_antichnyi-stil.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Если говорить про отделочные материалы, то самый важный фактор заключается в том, что они должны быть натуральными. Широко применяются в создании таких интерьеidea_1.jsров камень (натуральный и искусственный), древесина разных видов дерева, глина, гипс, мрамор, слоновая кость, текстиль высокого качества (лен, шелк).'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3_antichnyi-stil.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'Если говорить о мебели, то здесь часто встречаются настоящие произведения искусства. Она выполняется из древесины и украшается резьбой, инкрустируется золотом и слоновой костью.',
      'В этом стилистическом решении уместно использование церемониальных стульев, скамеек, кресел и стульев с необычными ножками, корзин, тумб, маленьких сундуков.',
      'Антикварная мебель с "историей" будет как нельзя кстати в современном античном интерьере.',
      'В гостиной хорошо бы разместить стол с ножками в форме звериных лап или фигур грифонов.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ОСВЕЩЕНИЕ',
    text: [
      'Еще одной важной характеристикой этого стиля являются высокие окна, декорированные шторами до пола из тяжелых тканей с красивой драпировкой.',
      'Что же касается светильников, то выбирайте те, которые имитируют факелы, здесь они будут идеальны.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4_antichnyi-stil.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ И СТЕНЫ',
    text: [
      'Стены и потолки хорошо декорировать лепниной.',
      'Колонны и лепнину красят в однотонные пастельные цвета: белый, бежевый, цвет слоновой кости, светло-серый), возможно использование сусального золота (с эффектом состаривания).',
      'Актуальна фресковая живопись, ею украшают стены гостиной, кухни, спальни. В рисунках изображают сцены из древнеримских и древнегреческих мифов, также будет уместна историческая, военная, природная тематика.',
      'Отличным решением для пола будет использование плитки теплых тонов с декоративными вставками.',
      'В жилых комнатах уместно сделать наборный паркет.',
      'На стены наносят декоративную штукатурку или красят краской (цвет может быть золотистый, карамельный, песочный, оливковый).'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'Интерьер в этом стиле нужно делать очень гармоничным, стоит продумать каждую деталь. Он диктует применение умеренного количества украшений и декоративных элементов. Уместно наполнить пространство мраморными скульптурами, бюстами, гобеленами с сюжетами древних мифов, фонтанами, картинами в позолоченных рамах, высокими вазами с рисунками на природную и морскую тему.'
    ]
  }
];
