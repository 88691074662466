import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/Button';

const InstagramMobile = () => {
  return (
    <section className="instagram-mobile">
      <Container>
        <Row>
          <Col>
            <div className="instagram-mobile-bg">
              <div className="instagram-mobile-text">
                <span className="text-uppercase line-height-small text-size-26">Более 100 работ</span>
                <span className="line-height-small text-size-24 mb-3">в нашем инстаграме!</span>
                <span className="line-height-small text-size-18  mb-3">Подписывайтесь на нас!</span>
                <Button gray boxshadow="full" small className="d-flex">
                  <a className="w-100" target="_blank"
                    rel="noopener noreferrer"
                    aria-labelledby="instagram" href="https://www.instagram.com/lovko.remont_minsk/">ПЕРЕЙТИ</a>
                </Button>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default InstagramMobile;