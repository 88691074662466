import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { R } from '../Routes';

const History = props => {
  return (
    <section className={`history ${props.historyclass}`}>
      <Container fluid={props.historyclass === 'history-aboutpage'}>
        <Row>
          <Col xs={12} md={6} />
          <Col xs={12} md={6}>
            <Link
              to={`${R.about}#history-slider`}
              scroll={el =>
                el.scrollIntoView({
                  behavior: 'instant',
                  block: 'center'
                })
              }
              className="history-text"
            >
              {props.children}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default History;
