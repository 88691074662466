import React from 'react';
import PageTitle from '../components/PageTitle';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import { MAIN_PAGE_IDEAS_CONTENT } from '../lovkoContent/Ideas/ideas';
import { R } from '../Routes';
import {Picture} from "react-responsive-picture";

const path = require('path');

const IdeasWithText = props => (
    <div className="ideas-with-text">
        {props.text.map((paragraph) => (
            <p className="mb-0">{paragraph}</p>
        ))}
    </div>
);

const IdeasWithLine = props => (
  <div className="ideas-with-line">
    <p className="text-size-18 mb-0">{props.text}</p>
  </div>
);

const IdeasWithImage = props => (
  <div className="ideas-with-image">
      <Picture
          srcSet={process.env.PUBLIC_URL + '/assets/images/ideas/' + props.idea_id + '/' + props.img}
          sources={[
              {srcSet: process.env.PUBLIC_URL + '/assets/images/ideas/' + props.idea_id + '/' + path.dirname(props.img) + '/' + path.basename(props.img, path.extname(props.img)) + '.jpf', type: "image/jpm"},
              {srcSet: process.env.PUBLIC_URL + '/assets/images/ideas/' + props.idea_id + '/' + path.dirname(props.img) + '/' + path.basename(props.img, path.extname(props.img)) + '.webp', type: "image/webp"},
          ]}
          alt={props.img}
      />
  </div>
);

const IdeasWithList = props => (
  <div className="ideas-with-list">
    {props.title &&
      <h5 className="f-w-600 mb-3">{props.title}:</h5>
    }
    <ol>
      {props.list.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ol>
  </div>
);

const IdeasWithTitle = props => (
  <div className="ideas-with-title">
    {props.title && (
      <h3 className="text-uppercase text-blue f-w-600 mb-4">{props.title}</h3>
    )}
    {props.text.map((paragraph) => (
      <p className="mb-0">{paragraph}</p>
    ))}
  </div>
);

const IdeasWithQuote = props => (
  <div className="ideas-with-quote">
    <p className="text-size-20 mb-0">{props.text}</p>
  </div>
);

const IdeasItem = props => {
  const { idea } = props;
  const pushToIdea = id => props.history.push(`${R.ideas}/${id}`);
  return (
    <section className="ideas-item">
      <Container>
        <Row>
          <Col>
            <PageTitle title={idea.title} />
          </Col>
        </Row>
        <Row>
          <Col className="text-gray">
            {idea.data.length &&
              idea.data.map((item, index) => {
                switch (item.type) {
                  case 'idea_with_text':
                    return <IdeasWithText key={index} text={item.text} />;
                  case 'idea_with_line':
                    return <IdeasWithLine key={index} text={item.text} />;
                  case 'idea_with_list':
                    return (
                      <IdeasWithList
                        key={index}
                        title={item.title}
                        list={item.list}
                      />
                    );
                  case 'idea_with_title':
                    return (
                      <IdeasWithTitle
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    );
                  case 'idea_with_quote':
                    return <IdeasWithQuote key={index} text={item.text} />;
                  case 'idea_with_img':
                    return <IdeasWithImage key={index} img={item.img} idea_id={idea.id}/>;
                  default:
                    return null;
                }
              })}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <h2 className="text-gray f-w-400 text-center text-uppercase h2-small mb-4">
                  Смотрите также
                </h2>
              </Col>
            </Row>
            <Row>
              {MAIN_PAGE_IDEAS_CONTENT.reverse()
                .slice(0, 4)
                .map(elem => (
                  <Col
                    xs={12}
                    md={4}
                    lg={3}
                    key={elem.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => pushToIdea(elem.id)}
                  >
                    <div
                      className="project-image-item img-container mb-3"
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ideas/${elem.id}/${elem.image})`
                      }}
                    />

                    <h5 className="text-uppercase">{elem.title}</h5>
                    <p className="text-small">{elem.text}</p>
                  </Col>
                ))}
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  className="flex-grow-1 flex-md-grow-0"
                  size="small"
                  blue
                  boxshadow="half"
                >
                  <Link to={R.ideas}>больше идей</Link>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(IdeasItem);
