import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import {
  TABS_NAV_ITEMS,
  COMPLETED_PROJECTS
} from '../lovkoContent/completedProjects';
import Button from '../components/Button';
import CompleteProjectElementModal from '../components/CompleteProjectElementModal';
import {Picture} from "react-responsive-picture";

const path = require('path');

const Estimate = ({ backgroundColor }) => {
  const [modal, changeModalVisibility] = useState(null);
  let modalClose = () => changeModalVisibility(null);

  const [modalEstimate, changeModalEstimateVisibility] = useState(null);
  let modalEstimateClose = () => changeModalEstimateVisibility(null);

  return (
    <section className='estimate' style={{ backgroundColor }} id='estimate'>
      <Container>
        <Row className='mb-2 mb-md-4'>
          <Col>
            <h2 className='text-gray h2-small'>
              Вы можете ОЗНАКОМИТЬСЯ <br />с предварительными СМЕТАМИ РЕМОНТА
              квартир
            </h2>
          </Col>
        </Row>
        <Row>
          {COMPLETED_PROJECTS.filter(el => el.estImg !== undefined)
              .sort(function(a, b) {
                if (a.tab === b.tab) return 0;
                else if (a.tab === 'designer') return -1;
                else if (a.tab === 'cosmetic') return 1;
                else if (b.tab === 'cosmetic') return -1;
                else if (b.tab === 'designer') return 1;
                return 0
              })
              .map(item => (
            <Col xs={12} md={3} key={item.id}>
              <div className='estimate-item'>
                <div
                  onClick={() => {
                    changeModalVisibility(item.id);
                  }}
                  className='estimate-item-img mb-3'
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/projects/${item.id}/${item.smallImg})`,
                    cursor: 'pointer'
                  }}
                />
                <div className='estimate-item-info'>
                  <p>
                    <b>Объект: </b>
                    <span>{item.info.type}</span>
                  </p>
                  <p>
                    <b>Площадь: </b>
                    <span>
                      {item.info.area} м<sup>2</sup>
                    </span>
                  </p>
                  <p>
                    <b>Тип ремонта: </b>
                    <span>{TABS_NAV_ITEMS.find(function(type) { return type.id === item.tab}).text}</span>
                  </p>
                </div>
                <div className='estimate-item-button d-flex mt-1 mt-md-3'>
                  <Button
                    size='small'
                    boxshadow='half'
                    className='ml-auto'
                    onClick={() => {
                      changeModalEstimateVisibility(item.id);
                    }}
                    gray={!backgroundColor}
                    style={{
                      color: !backgroundColor ? null : '#757575',
                      overflow: 'hidden'
                    }}
                  >
                    смета
                  </Button>
                </div>
              </div>
              <CompleteProjectElementModal
                project={item}
                show={modal === item.id ? true : false}
                onHide={modalClose}
              />
              <Modal
                show={modalEstimate === item.id ? true : false}
                onHide={modalEstimateClose}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                size='lg'
              >
                <Modal.Header closeButton />
                <Modal.Body>
                  <Picture
                      style={{ maxWidth: '100%' }}
                      className='estimate-large-image'
                      srcSet={`${process.env.PUBLIC_URL}/assets/images/projects/${item.id}/${item.estImg}`}
                      sources={[
                        {srcSet: process.env.PUBLIC_URL + '/assets/images/projects/' + item.id + '/' + path.basename(item.estImg, path.extname(item.estImg)) + '.jpf', type: "image/jpm"},
                        {srcSet: process.env.PUBLIC_URL + '/assets/images/projects/' + item.id + '/' + path.basename(item.estImg, path.extname(item.estImg)) + '.webp', type: "image/webp"},
                      ]}
                      alt={'Смета ' + item.info.mainTitle}
                  />
                </Modal.Body>
              </Modal>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Estimate;
