export const IDEA_2 = [
  {
    type: 'idea_with_img',
    img: '1-romanskii-stil.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Романский стиль: приют для рыцаря.'
  },
  {
    type: 'idea_with_title',
    title: 'ОСОБЕННОСТИ',
    text: [
      'Этот стиль своими корнями уходит за толстые стены замков во времена средневековья.',
      'Если коротко, то его можно описать так: мощный, фундаментальный. Однако есть во нем какая-то особенная романтика. Его часто используют в интерьере кафе. «Мой дом – моя крепость» - это выражение хорошо характеризует строения того времени. Стилизация этого направления сделает квартиру интересной и фундаментальной. Он хорошо подойдет интровертам и всем, кто любит романтику, благородство, особый шарм рыцарства, магию и волшебство.',
      'Этот стиль характеризуется полукруглыми окнами, тяжелыми сводами, полуциркульными арками, амбарными дверями, массивными колоннами, росписью стен с религиозной или бытовой тематикой, каменным полом и зеркалами из бронзы.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-romanskii-stil.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ЦВЕТА',
    text: [
      'Цветовая гамма разнообразна, преобладают не пёстрые тона, как правило может доминировать один оттенок, например, коричневый, приглушенно-желтый, постельный серый.',
      'Характерно применение мозаик, фресок, витражей. Они добавят в ваш интерьер более яркие цвета, такие как зеленый, синий, лимонный и другие. Можно сказать, что это пуританский стиль, поэтому каких-то ярких и кричащих акцентов лучше не использовать, все ключевые акценты должны гармонично вписываться в интерьер, не брать на себя много внимания.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Ценится, когда на стенах и потолке одинаковые материалы (получается имитация свода), ими могут быть камень или штукатурка. В качестве декора могут выполняться фрески.',
      'На пол укладывают камень или мозаику (каменную), либо же покрытие из грубого дерева темных цветов.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-romanskii-stil.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'У этих интерьеров есть свой особый шарм брутальности и простоты. Хочется назвать его «с мужским характером».',
      'В романском стиле нельзя забывать про камин, ведь огонь был так важен в те времена.',
      'Во времена средневековья стул был статусным предметом, и он предназначался только для главы семейства. Их делали высокими с гордой спинкой, а перед ним располагалась скамейка для ног.',
      'Шкафы начали делать позднее, в то же время широко использовали сундуки. Их старались украшать различными фигурами, например, грифонами, розетками, обшивали железной окантовкой. А шкаф на самом деле – это перевернутый сундук.',
      'Над кроватью в спальне будет отлично смотреться балдахин.',
      'В кухне прекрасно будут смотреться грубый стол и лавки, табуреты, медная посуда, которую можно подвешивать в рабочей кухонной зоне.',
      'Для детской в этом стиле поистине огромный задел для полета фантазии. Здесь могут быть пологи с рыцарскими флажками, шкафы в форме замков и башен, текстиль и ковры могут быть с драконами и рыцарями. Детям такой интерьер придется явно по душе.',
      'Круглый сосуд, напоминающий каменную чашу, может быть вместо ванны, вдобавок можно использовать медную или бронзовую сантехнику.',
      'В настоящее время можно подобрать больше количество достаточно комфортных кроватей и диванов, которые стилизованы под средневековую мебель. Важно придерживаться главных правил: большие размеры, простота линий, высокие спинки у изголовий, резьба, ковка, деревянные подставки под ноги.',
      'Хоть и кажется, что мебель ожидается использовать простую, но она должна делаться из качественных благородных пород дерева и быть красиво украшена (резьбой с интересными растительными орнаментами). В основном мебель для таких интерьеров делают из темного дерева.',
      'Обратим Ваше внимание, что витражи появились в средневековье. Для декорирования и создания зрительных иллюзий использовали цветное стекло. Благодаря игре света и цветного стекла комната окрашивалась в разные оттенки.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ОКНА и ОСВЕЩЕНИЕ',
    text: [
      'Характерны небольшие окна (бойницы, но более изящные). В качестве штор могут применяться плотный холст или гобелен, потому что примерно тогда окна стали закрывать тканью (в современном понимании – это шторы). Также тяжелой тканью можно завесить и дверные проемы.',
      'Настенные бра лучше выбирать стилизованные под свечи. А вот центральная люстра должна быть массивная, похожая на свечи в канделябрах с цепями.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-romanskii-stil.jpeg'
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ, СТЕНЫ, ПОТОЛОК',
    text: [
      'Интерьер этого стиля должен быть мощным, создавать неприступное ощущение (раньше романские дома могли выдерживать нападение и защищали свои хозяев). Поэтому в его отделке используют плитку, которая имитирует камень, натуральный и искусственный камень, однотонную декоративную штукатурку приглушенных тонов, а также темную состаренную древесину.',
      'Здесь будут уместны кессонные потолки, массивные деревянные балки, роспись. Напоминаем, что будет хорошо, если он будет сливаться (плавно переходить) со стенами, таким образом получится как бы однородный свод.',
      'Умеренно может применять резьба простых форм.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5-romanskii-stil.jpeg'
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'Что же касается декора, то здесь плодородная почва для полета дизайнерской мысли. Здесь можно применить рыцарскую тематику с использованием шлемов, лат, гербов, копьев, трофеев охоты). Для кухонной утвари можно повесить на деревянных балках жбаны и сковородки.',
      'Будут актуальны статуэтки в виде единорогов, монахов, трубодуров, сделанные из бронзы, керамики, серебра.',
      'Важно – не забыть про сундук, ведь в средневековье в них хранили практически все, на них даже сидели и спали. Первые сундуки делали из стволов деревьев (просто вырубали из них сундуки), позже появились у сундуков крышки, ножки, а позднее и резьба, фигурная ковка. Поэтому очень рекомендуем при создании интерьера в романском стиле добавлять в убранство квартиры сундуки и ларцы.',
      'Ну и, конечно, в жилище «рыцаря» должен быть камин как центр дома.',
      'Еще рыцарский антураж интерьеру придадут доспехи, кубки, оружие, факелы.'
    ]
  },
];
