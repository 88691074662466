import React, { Fragment } from 'react';
import Header from '../containers/Header';
import { Helmet } from 'react-helmet';
import Footer from '../containers/Footer';
import InfoBlock from '../containers/InfoBlock';
import Projects from '../containers/Projects';
import {
  InfoBlockItemCost,
  InfoBlockItemAboutCompany,
  InfoBlockItemPromo
} from '../components/InfoBlockItem';
import InstagramMobile from '../containers/InstagramMobile';
import PageTitle from '../components/PageTitle';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';

class ProjectsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TitleAndDesc.projectsPage.title}</title>
          <meta name="description" content={TitleAndDesc.projectsPage.desc} />
        </Helmet>
        <Header />
        <PageTitle title="РЕАЛИЗОВАННЫЕ ПРОЕКТЫ" />
        <Projects noTopPadiing whiteTab projectPage projectsCount={9} />
        <InstagramMobile />
        <InfoBlock>
          <InfoBlockItemCost />
          <InfoBlockItemPromo />
          <InfoBlockItemAboutCompany />
        </InfoBlock>
        <Footer />
      </Fragment>
    );
  }
}

export default ProjectsPage;
