import React from 'react';
import { Form, Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import Button from '../components/Button';
import {
    toggleModalReviewThanks,
    changeReviewName,
    changeReviewText,
    changeReviewFiles
} from '../actions.js';
import {CombinedSlick} from "../components/CombinedSlick";
import NextArrow from "../components/NextArrow";
import PrevArrow from '../components/NextArrow';
import Dropzone from 'react-dropzone';

const SLICK_SETTING = {
    infinite: false,
    speed: 500,
    lazyLoad: 'progressive',
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
        <NextArrow addClass="next-arrow-custom next-arrow-custom-medium" />
    ),
    prevArrow: <PrevArrow addClass="prev-arrow-custom next-arrow-custom-medium" />
};

const HISTORY_SLIDER = [
    {
        id: 0,
        text:
            'Студия ремонта lovko делала нам ремонт под ключ 3-комнатной квартиры. Ребята очень профессионально выполнили свою работу. С вами приятно иметь дело, желаю вам побольше заказов. Буду рекомендовать вас знакомым и друзьям.',
        img: ['reviews/1/1_otzyv-remont-kvartity-Leonida-Bedy.jpg', 'reviews/1/2_otzyv-remont-kvartity-Leonida-Bedy.jpg', 'reviews/1/3_otzyv-remont-kvartity-Leonida-Bedy.jpg', 'reviews/1/4_otzyv-remont-kvartity-Leonida-Bedy.jpg'],
        addInfo: {
            name: 'Ирина',
            address: 'Минск, ул. Леонида Беды'
        }
    }
];

class Reviews extends React.Component {
    constructor(props) {
        super(props);
        this.sendToViber = this.sendToViber.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    onDrop = (acceptedFiles) => {
        this.props.changeReviewFiles(acceptedFiles);
        for (let index in acceptedFiles) {
            let fileName = document.createElement('div');
            fileName.innerHTML = acceptedFiles[index].name;
            document.getElementsByClassName('review-upload').item(0).firstChild.appendChild(fileName);
        };
    }

    sendToViber(event) {
        event.preventDefault();
        let formData = new FormData();

        for (var name in this.props.reviewFormData) {
            if (name === 'files' && null !== this.props.reviewFormData[name]) {
                this.props.reviewFormData[name].map((file, index) => {
                    return formData.append(`files[${index}]`, file);
                });
            } else {
                formData.append(name, this.props.reviewFormData[name]);
            }
        }

        this.submitButton = event.target;
        this.sendForm = this.submitButton.parentNode.parentNode;
        let sorry = document.createElement('div');
        sorry.classList.add('alert');
        sorry.classList.add('alert-danger');
        let pleaseText = document.createTextNode('Мы приносим свои извинения. Из-за технической проблемы введенная Вами информация не дошла до нас. Алексей будет рад помочь Вам, если Вы позвоните по номеру +375 (29) 561 02 88. Спасибо!');
        sorry.appendChild(pleaseText);
        this.sorry = sorry;

        fetch('/api/review', {
            'method': 'POST',
            'body': formData
        })
            .then(response => {
                this.submitButton.innerHTML = 'отправить отзыв';
                this.submitButton.disabled = false;
                if (response.ok) {
                    this.props.toggleModalReviewThanks(true);
                    this.props.changeReviewName(null);
                    this.props.changeReviewText(null);
                    this.props.changeReviewFiles(null);
                    this.sendForm.reset();
                    let fileNameContainer = this.sendForm.getElementsByTagName('div')[0].firstChild;
                    let uploadedFileNames = fileNameContainer.getElementsByTagName('div');
                    while (uploadedFileNames[0]) {
                        uploadedFileNames[0].parentNode.removeChild(uploadedFileNames[0]);
                    }
                } else {
                    this.sendForm.appendChild(this.sorry);
                    console.log(response);
                }
            })
            .catch(err => {
                this.submitButton.innerHTML = 'отправить отзыв';
                this.submitButton.disabled = false;
                this.sendForm.appendChild(this.sorry);
                console.log(err);
            });

        event.target.innerHTML = 'отправка';
        event.target.disabled = true;
        let spinner = document.createElement('span');
        spinner.classList.add('spinner-grow-sm');
        spinner.classList.add('spinner-grow');
        spinner.setAttribute('role', 'status');
        spinner.setAttribute('aria-hidden', true);
        event.target.appendChild(spinner);
    }

    render() {
        const {
            changeReviewName,
            changeReviewText,
            changeReviewFiles
        } = this.props;
        return (
            <section className="reviews" id="otzyvy">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="text-white f-w-400 text-center text-uppercase h2-small mb-1 ">
                                Отзывы
                            </h2>
                            <p className="text-blue f-w-400 text-size-20 text-center mb-4">
                                Что наши Клиенты говорят о нас
                            </p>
                        </Col>
                    </Row>
                    <Row className="flex-md-row flex-column-reverse">
                        <Col xs={12} md={5}>
                            <div className="review-form-wrapper">
                                <Form className="review-form">
                                    <Form.Control
                                        onChange={e => changeReviewName(e.target.value)}
                                        id='name'
                                        type="text"
                                        placeholder="Ваше имя"
                                    />
                                    <Form.Group
                                        controlId="upload-review-foto"
                                        className="review-upload form-control"
                                    >
                                        <Dropzone
                                            onDrop={this.onDrop}
                                            minSize={0}
                                            maxSize={10485760}
                                            multiple
                                            onChange={e => changeReviewFiles(e.target.value)}
                                        >
                                            {({getRootProps, getInputProps, isDragActive, rejectedFiles}) => {
                                                const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > 10485760;
                                                return (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {!isDragActive && 'Загрузить фото'}
                                                        {isDragActive && "Бросай сюда!"}
                                                        {isFileTooLarge && (
                                                            <div className="text-danger mt-2">
                                                                Файл слишком большой.
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            }}
                                        </Dropzone>
                                    </Form.Group>
                                    <Form.Control
                                        onChange={e => changeReviewText(e.target.value)}
                                        as="textarea"
                                        rows="5"
                                        name="text"
                                        placeholder="Текст отзыва"
                                    />
                                    <div className="review-button-wrapper">
                                        <Button
                                            onClick={this.sendToViber}
                                            size="small"
                                            boxshadow="full"
                                            blue
                                            className="ml-auto"
                                        >
                                            отправить отзыв
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={12} md={7}>
                            <div className="history-slick-slider-wrapper ">
                                <div className="history-slick-slider">
                                    <CombinedSlick setting={SLICK_SETTING} items={HISTORY_SLIDER}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

const mapDispachToProps = dispatch => ({
    toggleModalReviewThanks: boolean => {
        dispatch(toggleModalReviewThanks(boolean));
    },
    changeReviewName: string => {
        dispatch(changeReviewName(string));
    },
    changeReviewText: string => {
        dispatch(changeReviewText(string));
    },
    changeReviewFiles: array => {
        dispatch(changeReviewFiles(array));
    }
});

const mapStateToProps = state => ({
    reviewFormData: state.reviewFormData
});

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Reviews);
