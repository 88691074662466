import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { CombinedSlick } from './Slick';
import Button from './Button';
import { HashLink as Link } from 'react-router-hash-link';
import { toggleModalContactWithUs } from '../actions.js';
import { R } from '../Routes';

class CompleteProjectElementModal extends React.PureComponent {
  render() {
    const { show, onHide, toggleModalContactWithUs } = this.props;
    const {
      images,
      info: { area, mainTitle, period, type, workPerformed, features }
    } = this.props.project;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="complete-project-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="complete-project-element">
            <Container>
              <Row>
                <Col xs={12} md={8}>
                  <CombinedSlick sliderImg={images} />
                </Col>
                <Col xs={12} md={4}>
                  <div className="complete-project-elem-text">
                    <div className="complete-project-elem-text-main">
                      <h4 className="f-w-600 text-blue mb-1 mb-md-3">
                        {mainTitle}
                      </h4>
                      <p className="mb-1 text-gray">
                        <b>Тип: </b> {type}
                      </p>
                      <p className="mb-1 text-gray">
                        <b>Площадь: </b> {area}м<sup>2</sup>
                      </p>
                      {period &&
                        <p className="mb-2 text-gray">
                          <b>Срок выполнения ремонта: </b> {period}
                        </p>
                      }
                      <p className="mb-2 text-gray">
                        <b>Выполненные работы: </b> {workPerformed.title}
                      </p>
                      {workPerformed.elems &&
                        <ul className="text-gray">
                          {workPerformed.elems.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      }
                      {features &&
                        <p className="mb-2 text-gray">
                          <b>Особенности объекта: </b> {features.title}
                        </p>
                      }
                      {features && features.elems &&
                        <ul className="text-gray">
                          {features.elems.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      }
                      <div className="d-flex flex-column mt-4 flex-grow-1">
                        <Button gray size="small" boxshadow="half">
                          <Link onClick={onHide} to={R.projects}>
                            Смотреть больше проектов
                          </Link>
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex flex-column complete-project-elem-text-button">
                      <Button
                        blue
                        size="small"
                        boxshadow="half"
                        onClick={() => {
                          onHide();
                          toggleModalContactWithUs(true);
                        }}
                      >
                        <p className="m-0 font-weight-bold">
                          Получить консультацию
                        </p>
                        <p className="m-0 font-weight-bold">
                          +375 (29) 561 02 88
                        </p>
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModalContactWithUs: boolean => {
    dispatch(toggleModalContactWithUs(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(CompleteProjectElementModal);
