import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import InfoBlock from '../containers/InfoBlock';
import Banner from '../containers/Banner';
import Button from '../components/Button';
import { toggleModal } from '../actions.js';
import { connect } from 'react-redux';
import {
  InfoBlockItemProjects,
  InfoBlockItemAboutCompany,
  InfoBlockItemPromo
} from '../components/InfoBlockItem';
import Ideas from '../containers/Ideas';
import PageTitle from '../components/PageTitle';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';
class IdeasPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TitleAndDesc.ideasPage.title}</title>
          <meta name="description" content={TitleAndDesc.ideasPage.desc} />
        </Helmet>
        <Header />
        <PageTitle title="ИДЕИ ДЛЯ ВДОХНОВЕНИЯ" />
        <Ideas noTopPadiing whiteTab ideasPage ideasCount={12} />
        <Banner>
          <div>
            <h3 className="text-white">
              <span className="f-w-600">Вдохновились?</span>
              <br />А теперь давайте начнем ваш
              <span className="f-w-600"> lovko ремонт</span>
            </h3>
          </div>
          <Button
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModal(true)}
          >
            НАЧАТЬ РЕМОНТ
          </Button>
        </Banner>
        <InfoBlock>
          <InfoBlockItemProjects />
          <InfoBlockItemAboutCompany />
          <InfoBlockItemPromo />
        </InfoBlock>
        <Footer />
      </Fragment>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(IdeasPage);
