import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import ImageZoom from 'react-medium-image-zoom';
import { isMobile } from 'react-device-detect';
import {Picture} from "react-responsive-picture";
const path = require('path');

export const SlickImg = props => {
  const { setting, items, withInfo, certificates } = props;
  return (
    <Slider {...setting} swipe={certificates ? false : true}>
      {items.map(item => (
        <div key={item} className="slick-img-item-wrapper">
          {certificates ? (
            <div>
              <ImageZoom
                shouldReplaceImage={false}
                image={{
                  src: `/assets/images/certificates/${item.img}`,
                  alt: 'image',
                  style: { height: '230px', margin: '0 auto' }
                }}
                defaultStyles={{
                  overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                  }
                }}
                zoomImage={{
                  src: `/assets/images/certificates/${item.img}`,
                  alt: 'image'
                }}
                zoomMargin={isMobile ? 40 : 150}
              />
            </div>
          ) : (
              <div
                className="slick-img-item"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team/${item.img})`
                }}
              >
                {withInfo ? (
                  <div className="slick-img-team-info text-white">
                    <h4 className="slick-img-team-info-name">{item.name}</h4>
                    <p className="slick-img-team-info-position">
                      {item.position}
                    </p>
                  </div>
                ) : null}
              </div>
            )}
        </div>
      ))}
    </Slider>
  );
};

const COMBINED_SLICK_OPTIONS = {
  lazyLoad: 'progressive',
  slidesToShow: 5,
  swipeToSlide: true,
  focusOnSelect: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2
      }
    }
  ]
};

const COMBINED_SLICK_OPTIONS_IMG = {
  arrows: true,
  lazyLoad: 'progressive'
};

export class CombinedSlick extends Component {
  state = {
    nav1: null,
    nav2: null
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const { sliderImg } = this.props;
    return (
      <Fragment>
        <div className="combined-slick-main">
          <Slider
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            {...COMBINED_SLICK_OPTIONS_IMG}
          >
            {sliderImg &&
              sliderImg.map(img => (
                <div key={img}>
                  <Picture
                      className={'combined-slick-main-img'}
                      srcSet={process.env.PUBLIC_URL + '/assets/images/' + img}
                      sources={[
                        {srcSet: process.env.PUBLIC_URL + '/assets/images/' + path.dirname(img) + '/' + path.basename(img, path.extname(img)) + '.jpf', type: "image/jpm"},
                        {srcSet: process.env.PUBLIC_URL + '/assets/images/' + path.dirname(img) + '/' + path.basename(img, path.extname(img)) + '.webp', type: "image/webp"},
                      ]}
                      alt={img}
                  />
                </div>
              ))}
          </Slider>
        </div>
        <div className="combined-slick-dot">
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            {...COMBINED_SLICK_OPTIONS}
          >
            {sliderImg &&
              sliderImg.map(img => (
                <div key={img}>
                  <Picture
                      className={'combined-slick-dot-img'}
                      srcSet={process.env.PUBLIC_URL + '/assets/images/' + img}
                      sources={[
                        {srcSet: process.env.PUBLIC_URL + '/assets/images/' + path.dirname(img) + '/' + path.basename(img, path.extname(img)) + '.jpf', type: "image/jpm"},
                        {srcSet: process.env.PUBLIC_URL + '/assets/images/' + path.dirname(img) + '/' + path.basename(img, path.extname(img)) + '.webp', type: "image/webp"},
                      ]}
                      alt={img}
                  />
                </div>
              ))}
          </Slider>
        </div>
      </Fragment>
    );
  }
}
