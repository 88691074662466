import React from 'react';
import Slider from 'react-slick';
import { Row, Col } from 'react-bootstrap';
import NextArrow from './NextArrow';
import PrevArrow from './NextArrow';

const SLICK_IMG_SETTINGS = {
  infinite: true,
  speed: 500,
  vertical: true,
  lazyLoad: 'progressive',
  slidesToShow: 2,
  nextArrow: (
    <NextArrow addClass="next-arrow-custom next-arrow-custom-vertical" />
  ),
  prevArrow: (
    <PrevArrow addClass="prev-arrow-custom next-arrow-custom-vertical" />
  )
};

const AddInfo = (props) => (
  <div className="combined-slick-item-add-info">
    <p className="mb-0 text-right">{props.name}</p>
    <p className="mb-0 text-right">{props.address}</p>
  </div>
)

export const CombinedSlick = props => {
  const { setting, items } = props;
  return (
    <Slider {...setting}>
      {items.map(item => (
        <div key={item.id} className="combined-slick-item-wrapper">
          <div className="combined-slick-item">
            <Row>
              <Col xs={12} md={4}>
                <div className="combined-slick-item-img-slider">
                  <Slider {...SLICK_IMG_SETTINGS}>
                    {item.img.map(img => (
                      <div
                        key={img}
                        className="combined-slick-item-img-slider-img-wrapper"
                      >
                        <div
                          className="combined-slick-item-img-slider-img"
                          style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${img})`
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
              <Col xs={12} md={8}>
                {item.title && <h4 className="text-blue">{item.title}</h4>}
                {item.title && item.addInfo && <p className="combined-slick-item-main-text">{item.text}</p>}
                {item.title && !item.addInfo && <p className="combined-slick-item-main-text-no-add-info">{item.text}</p>}
                {!item.title && item.addInfo && <p className="combined-slick-item-main-text-no-title">{item.text}</p>}

                {item.addInfo && <AddInfo name={item.addInfo.name} address={item.addInfo.address} />}
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </Slider>
  );
};
