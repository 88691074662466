import { ServicesElems as S } from './constants';
export const REMONT_NEZHILYH = [
  {
    type: S.withImg,
    img: 'remont-nezhilyh-1.jpg'
  },
  {
    type: S.withLine,
    text:
      'Отделка нежилых помещений – это реконструкция и отделка зданий и помещений разного назначения. Мы делаем ремонт нежилых помещений «под ключ» в Минске и Минском районе.'
  },
  {
    type: S.withTitle,
    text:
      '<p>От того как оборудовано коммерческое помещение с эргономической и эстетической точки зрения часто зависит эффективность эксплуатации помещения, качество работы персонала, имидж компании, поток клиентов и т.д.</p><p>В рамках комплексного ремонта нежилых помещений мы производим реконструкцию фасадов и кровли, утепление, сантехнические и электромонтажные работы, делаем тепло-, звуко-, гидроизоляцию, демонтаж и монтаж перегородок, монтаж водопровода, вентиляции, отопительной и канализационной системы, отделку стен и потолков, укладку плитки.</p>'
  },
]