import React, { useState, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MAIN_PAGE_DROPDOWNS } from '../constants';
import Collapse, { Panel } from 'rc-collapse';
import ReactHtmlParser from 'react-html-parser';
import 'rc-collapse/assets/index.css';
import {Picture} from "react-responsive-picture";

const path = require('path');

const PanelHeader = props => (
  <Fragment>
    <div
      className="svg-wrapper"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/dropdowns/${props.img})`
      }}
    />
    <span className="text-gray">{props.text}</span>
  </Fragment>
);

const Dropdowns = ({ backgroundColor, hidemobile }) => {
  const [changeActiveDrowdow] = useState('0');
  return (
    <section
      className={`dropdowns ${hidemobile ? 'd-none d-md-block' : ''}`}
      style={{ backgroundColor }}
      id="lovko-remont"
    >
      <Container>
        <Row>
          <Col>
            <div className="img-container dropdowns-svg-container">
              <h5 className="text-uppercase text-gray mb-0">Вы получаете</h5>
              <img src={process.env.PUBLIC_URL + '/assets/images/lovko_remont.svg'} alt="lovko_remont" />
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '0 -15px' }} noGutters>
          <Col>
            <Collapse
              accordion={true}
              onChange={key => changeActiveDrowdow(key)}
            >
              {MAIN_PAGE_DROPDOWNS.map(item => (
                <Panel
                  header={<PanelHeader img={item.img} text={item.title} />}
                  showArrow={false}
                  key={item.id.toString()}
                >
                  {item.add_img && (
                    <div className="dropdowns-add-img mb-3">
                      <Picture
                          srcSet={`${process.env.PUBLIC_URL}/assets/images/dropdowns/addImg/${item.add_img}`}
                          sources={[
                            {srcSet: process.env.PUBLIC_URL + '/assets/images/dropdowns/addImg/' + path.basename(item.add_img, path.extname(item.add_img)) + '.jpf', type: "image/jpm"},
                            {srcSet: process.env.PUBLIC_URL + '/assets/images/dropdowns/addImg/' + path.basename(item.add_img, path.extname(item.add_img)) + '.webp', type: "image/webp"},
                          ]}
                          alt={item.title}
                      />
                    </div>
                  )}
                  <div className="text-gray">{ReactHtmlParser(item.text)}</div>
                </Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Dropdowns;
