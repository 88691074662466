import React, { Fragment } from 'react';

const Instagram = props => {
  const { instagram } = props;
  return (
    <Fragment>
      {instagram.data.slice(0, 10).map(item => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/lovko.remont_minsk/"
          key={item.id}
          className="instagram-item"
        >
          <img src={item.images.thumbnail.url} alt={item.user.full_name} />
        </a>
      ))}
    </Fragment>
  );
};

export default Instagram;
