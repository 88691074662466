import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const InfoBlock = props => {
  return (
    <section className="infoblock">
      <Container>
        <Row>
          {props.children.map((item, index) => (
            <Col key={index} xs={12} md={4}>
              {item}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default InfoBlock;
