import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { HashLink as Link } from 'react-router-hash-link';
import { R } from '../Routes';

const VideoBlock = () => {
  const [video, changeVideoPlay] = useState(0);
  return (
    <section className="video-block">
      <Container>
        <Row>
          <Col xs={12} md={7}>
            <div className="video-block-button-wrapper">
              <Link to={R.services} className="video-block-button-text">
                <h3
                  style={{ fontSize: '32px' }}
                  className="text-white f-w-400 mb-4 text-uppercase  text-right line-height-small"
                >
                  Хотите
                  <br /> качественный ремонт
                  <br /> по честной цене ?
                </h3>
                <p
                  style={{ color: '#575454' }}
                  className="text-gray text-size-20 line-height-small text-right m-0"
                >
                  Давайте начнем Ваш <br /> первый <b>lovko ремонт</b>
                </p>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <div className="video-block-media-wrapper">
              <div className="mr-2">
                <div className="video-block-media-item mb-2">
                  <ReactPlayer
                    playing={video === 0 ? true : false}
                    muted
                    width="200px"
                    height="200px"
                    url={process.env.PUBLIC_URL + '/assets/videos/lovko-shpatlyem.mp4'}
                    onEnded={() => changeVideoPlay(1)}
                  />
                </div>
                <div className="video-block-media-item ">
                  <ReactPlayer
                    playing={video === 2 ? true : false}
                    muted
                    width="200px"
                    height="200px"
                    url={process.env.PUBLIC_URL+'/assets/videos/lovko-krasim.mp4'}
                    onEnded={() => changeVideoPlay(3)}
                  />
                </div>
              </div>
              <div>
                <div className="video-block-media-item mb-2">
                  <ReactPlayer
                    playing={video === 1 ? true : false}
                    muted
                    className="react-player"
                    width="200px"
                    height="200px"
                    url={process.env.PUBLIC_URL + '/assets/videos/lovko-remontiruem-kvartiry.mp4'}
                    onEnded={() => changeVideoPlay(2)}
                  />
                </div>
                <div className="video-block-media-item">
                  <ReactPlayer
                    playing={video === 3 ? true : false}
                    muted
                    width="200px"
                    height="200px"
                    url={process.env.PUBLIC_URL + '/assets/videos/lovko-studia-remonta.mp4'}
                    onEnded={() => changeVideoPlay(0)}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VideoBlock;
