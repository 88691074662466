export const IDEA_5 = [
  {
    type: 'idea_with_img',
    img: '1-stil-ampir.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Ампир: императорский масштаб.'
  },
  {
    type: 'idea_with_title',
    title: 'ОСОБЕННОСТИ',
    text: [
      'Франция, Наполеон Бонапарт, период величия и могущества – из этого всего вырос стиль Ампир.',
      'Для этого стиля характерна роскошь, величественная парадность, симметрия и строгие формы, интеллигентная утонченность, многообразие декоративных элементов, применение позолоты и резьбы. Этот стиль отличается наличием античных элементов (колонн, карнизов, фресок, арок, барельефов, лепнины, фризов). Поскольку корни этого стиля уходят к Наполеону, то здесь актуальны элементы военной тематики, хорошо применение щитов, шпаг, доспехов. Все это будет говорить о доблести и величии. Семейные традиции и преемственность поколений отражаются в фамильных гербах.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-stil-ampir.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ЦВЕТА',
    text: [
      'Золотой цвет является основным цветом данного стиля, поскольку он подчеркивает могущество обстановки, делает отсылку к императорским покоям. Добавление белого делает интерьер нарядным и утонченным. А силу и статусность передает красный. Для будуаров или гостиных применяли благородный синий.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Для ампира, конечно, характерна отделка дорогостоящими натуральными материалами, которые подчеркивают высокий достаток и великолепие: натуральным камнем, ценными породами древесины, с применением позолоты, серебра, бронзы, хрусталя.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-stil-ampir.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'Хорошо смотрится здесь антикварная мебель, она должна быть массивной, из благородной древесины (хорошо подойдет красное дерево, карельская береза и др.), с изысканным декоративными элементами. Для этого стиля характерно то, что на первом месте стоит величественность и солидность, а не комфортабельность.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-stil-ampir.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОКНА',
    text: [
      'Убранство окон характеризуются кованными карнизами, гардинами из струящихся тканей, тяжелыми, в несколько слоев, красиво драпирующимися портьерами (часто красного императорского цвета) с ламбрекенами, кистями и бахромой.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5-stil-ampir.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОСВЕЩЕНИЕ',
    text: [
      'В центре потолка вешают большую, роскошную хрустальную люстру с замысловатыми светильниками.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ И СТЕНЫ',
    text: [
      'Стены в данном случае – это как драгоценная огранка интерьера. На них наносят лепнину, золотые узоры, декорируют шелком и парчой. В настоящее время возможна поклейка фактурных обоев, которые будут имитировать дорогие ткани.',
      'На пол укладывают паркет с замысловатым рисунком из дорогой древесины, мраморную мозаику или плитку из натурального камня.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '6-stil-ampir.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'К выбору декоративных элементов нужно подходить серьёзно, поскольку они является такими же важными как отделка и мебель, поскольку они завершаут общее впечатление и помогают стать интерьеру завершенным и гармоничным. Декоративные предметы нужно выбирать роскошные и великолепные, созвучные императорскому стилю.',
      'Приветствуются антиквариат, полотна художников в массивных рамах с позолотой, шкатулки и подсвечники (материалы: позолота, серебро, мрамор), бронзовые или деревянные часы, изящные вазы, античные статуэтки, посуда из фарфора, ковры и шкуры животных. Подчеркнуть великолепие обстановки и блеск поверхностей помогут зеркала больших размеров в объемных рамах.',
      'На картинах как правило отражаются важные события истории или сцены битв.'
    ]
  },
];
