import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Promotions = () => {
  return (
    <section className="promotions" id="akcii">
      <Container>
        <Row>
          <Col>
            <div className="promotions-header">
              <h2 className="text-white f-w-400 text-center text-uppercase h2-small mb-1">
                Акции
              </h2>
              <p className="text-blue f-w-400 text-size-20 text-center ">
                Мы любим радовать наших клиентов
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <div className="promotions-item">
              <p className="promotions-item-text text-center text-white text-size-26">
                <span>Поможем</span>
                <b>принять квартиру</b>
                <span>у застройщика</span>
                <span>(бесплатно)</span>
              </p>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="promotions-item">
              <p className="promotions-item-text text-center text-white text-size-26">
                <span>до 31.12.21</span>
                <b>Karcher</b>
                <span>пароочиститель</span>
                <span>в подарок</span>
              </p>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="promotions-item">
              <p className="promotions-item-text text-center text-white text-size-26">
                <span>cкидка</span>
                <b>5%</b>
                <span>на наши услуги</span>
                <span>при заказе с сайта</span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Promotions;
