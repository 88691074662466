import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import History from '../containers/History';
import Dropdowns from '../containers/Dropdowns';
import TeamSlider from '../containers/TeamSlider';
// import Certificates from '../containers/Certificates';
import InfoBlock from '../containers/InfoBlock';
import HistorySlider from '../containers/HistorySlider';
import Ceo from '../containers/Ceo';
import {
  InfoBlockItemProjects,
  InfoBlockItemCost,
  InfoBlockItemPromo
} from '../components/InfoBlockItem';
import PageTitle from '../components/PageTitle';
import Reviews from '../containers/Reviews';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';

const CeoText = (
  <React.Fragment>
    <p className="mb-0 mt-3 mt-md-0  text-md-left text-gray text-small-14">
      Алексей Бузо
    </p>
    <p className="mb-3 mb-md-5 text-gray  text-md-left text-small-14">
      Основатель и руководитель студии ремонта «lovko»
    </p>
    <div className="ceo-main-text block-quote">
      <p className="mb-3 text-gray">
        Студия ремонта lovko с 2008 года делает
        <b> комплексный ремонт</b> квартир, коттеджей, офисов как по
        дизайн-проектам, так и без них, с полной
        <b> комплектацией отделочными материалами.</b>
      </p>
      <p className="mb-3 text-gray">
        Команда <b>профессионалов</b>, применяющая передовые <b>технологии</b>,
        а также привлекающая <b>надежных партнеров и поставщиков</b>, позволяет
        Вам получать проекты широкого спектра <b>сложности и в срок</b>.
      </p>
      <p className="mb-3 text-gray">
        Над Вашим ремонтом будет трудиться <b>дружный коллектив</b>{' '}
        профессионалов, любящих свою работу.
      </p>
    </div>
  </React.Fragment>
);
class AboutPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TitleAndDesc.aboutPage.title}</title>
          <meta name="description" content={TitleAndDesc.aboutPage.desc} />
        </Helmet>
        <Header />
        <PageTitle title="О СТУДИИ РЕМОНТА" addTitle="lovko" />
        <History historyclass="history-aboutpage">{HistoryChilder}</History>
        <Ceo text={CeoText} />
        <Dropdowns />
        <TeamSlider />
        <Reviews />
        {/*<Certificates />*/}
        <HistorySlider />
        <InfoBlock>
          <InfoBlockItemProjects />
          <InfoBlockItemCost />
          <InfoBlockItemPromo />
        </InfoBlock>
        <Footer />
      </Fragment>
    );
  }
}

export default AboutPage;

const HistoryChilder = (
  <div>
    <p className="text-white text-size-26 mb-0">
      Мы считаем, что для наших{' '}
      <span className="text-uppercase font-weight-bold">
        клиентов процесс ремонта
      </span>{' '}
      должен быть:{' '}
      <span className="text-uppercase font-weight-bold">
        приятным, легким, интересным
      </span>
    </p>
  </div>
);
