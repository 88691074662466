import React from 'react';
import PageTitle from '../components/PageTitle';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { R } from '../Routes';
import { MAIN_PAGE_SERVICES_CONTENT } from '../lovkoContent/services/services';
import { ServicesElems as S } from '../lovkoContent/services/servicesElements/constants';
import ReactHtmlParser from 'react-html-parser';
import { url_slug } from 'cyrillic-slug';

const ServicesWithLine = props => (
  <div className="ideas-with-line">
    <p className="text-size-18 mb-0">{props.text}</p>
  </div>
);

const ServicesWithImage = props => (
  <div className="ideas-with-image">
    <img
      src={`${process.env.PUBLIC_URL}/assets/images/services/${props.img}`}
      alt={props.img}
    />
  </div>
);

const ServicesWithList = props => (
  <div className="ideas-with-list">
    <h5 className="f-w-600 mb-3">{props.title}</h5>
    <ol>
      {props.list.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ol>
  </div>
);

const ServicesWithTitle = props => (
  <div className="ideas-with-title">
    {props.title && (
      <h3 className="text-uppercase text-blue f-w-600 mb-4">{props.title}</h3>
    )}
    {props.text && <div className="mb-0">{ReactHtmlParser(props.text)}</div>}
  </div>
);

const ServicesWithQuote = props => (
  <div className="ideas-with-quote">
    <p className="text-size-20 mb-0">{props.text}</p>
  </div>
);

const ServiceItem = props => {
  const {
    service: { data: serviceData, title, type }
  } = props;
  const pushToServiceItem = nav => {
    const link = url_slug(nav.toLowerCase(), '_');
    return props.history.push(`${R.services}/${link}`)
  };
  return (
    <section className="ideas-item">
      <Container>
        <Row>
          <Col>
            <PageTitle title={title} />
          </Col>
        </Row>
        <Row>
          <Col className="text-gray">
            {serviceData.length &&
              serviceData.map((item, index) => {
                switch (item.type) {
                  case S.withLine:
                    return <ServicesWithLine key={index} text={item.text} />;
                  case S.withList:
                    return (
                      <ServicesWithList
                        key={index}
                        title={item.title}
                        list={item.list}
                      />
                    );
                  case S.withTitle:
                    return (
                      <ServicesWithTitle
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    );
                  case S.withQuote:
                    return <ServicesWithQuote key={index} text={item.text} />;
                  case S.withImg:
                    return <ServicesWithImage key={index} img={item.img} />;
                  default:
                    return null;
                }
              })}
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-gray f-w-400 text-center text-uppercase h2-small mb-4">
              Смотрите также
                </h2>
          </Col>
        </Row>
        <Row>
          {MAIN_PAGE_SERVICES_CONTENT.filter(
            i => i.type === type && i.title !== title
          )
            .sort(() => Math.random() - 0.5)
            .slice(0, 4)
            .map((i, inx) => (
              <Col md={3} key={inx}>
                <div className="services-more" onClick={() => pushToServiceItem(i.title)}>
                  <div className="services-more-image mb-3">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/services/${i.image}`} alt={i.navtext} />
                  </div>
                  <h5 className='text-gray text-center'>{i.navtext}</h5>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(ServiceItem);
