import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/Button';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { toggleModalContactWithUs } from '../actions.js';
import Instagram from './Instagram';
import { R } from '../Routes';

const INST_IMG = [
  'inst1',
  'inst2',
  'inst3',
  'inst4',
  'inst5',
  'inst6',
  'inst7',
  'inst8',
  'inst9',
  'inst10'
];

const InstagramDefault = () => {
  return (
    <Fragment>
      {INST_IMG.map(item => (
        <div key={item} className="instagram-item">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/Instagram/${item}.jpg`}
            alt={item}
          />
        </div>
      ))}
    </Fragment>
  );
};

const Footer = props => {
  const { toggleModalContactWithUs, instagram } = props;
  return (
    <footer className="page-footer bg-blue-bayoux" id="kontakty">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <h2 className="text-uppercase text-white f-w-400">
              Давайте встретимся
            </h2>
            <p className="text-white mb-0">
              Вы можете связаться с нами любым удобным способом:
            </p>
          </Col>
          <Col className="d-none d-md-block" md={6}>
            <div className="footer-logo">
              <img src={process.env.PUBLIC_URL + '/assets/images/logo_white.png'} alt="footer-logo" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                <div className="d-flex flex-column flex-md-row justify-content-between mt-2 mt-md-5">
                  <div>
                    <h4 className="footer-mobile-large-text text-white text-sm-center  text-md-left f-w-600">
                      +375 (29) 561 02 88
                    </h4>
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-strech align-items-md-center">
                      <h4 className="footer-mobile-large-text text-white text-sm-center text-md-left f-w-400 mb-0">
                        Alex.Buzo@lovko.by
                      </h4>
                    </div>
                  </div>
                  <div className="align-self-md-center mt-2 mb-md-0">
                    <Button
                      className="flex-grow-1 flex-md-grow-0 mt-2 mt-md-0 w-100"
                      boxshadow="full"
                      blue
                      size="medium"
                      onClick={() => toggleModalContactWithUs(true)}
                    >
                      СВЯЗАТЬСЯ
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-none d-md-flex mt-2 ">
          <Col xs={12} md={6} className="pr-md-4">
            <div className="footer-nav">
              <div className="footer-nav-item">
                <p>Компания</p>
                <ul className="footer-nav-item-list">
                  <li>
                    <Link to={`${R.about}#root`}>о нас</Link>
                  </li>
                  <li>
                    <Link
                      to={`${R.about}#komanda`}
                      scroll={el =>
                        el.scrollIntoView({
                          behavior: 'instant',
                          block: 'center'
                        })
                      }
                    >
                      команда
                    </Link>
                  </li>
                  {/*<li>
                    <Link
                      to={`${R.about}#certificates`}
                      scroll={el =>
                        el.scrollIntoView({
                          behavior: 'instant',
                          block: 'center'
                        })
                      }
                    >
                      сертификаты
                    </Link>
                  </li>*/}
                  <li>
                    <Link
                      to={`${R.about}#otzyvy`}
                      scroll={el =>
                        el.scrollIntoView({
                          behavior: 'instant',
                          block: 'center'
                        })
                      }
                    >
                      отзывы
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-nav-item">
                <p>Услуги</p>
                <ul className="footer-nav-item-list">
                  <li>
                    <Link
                      to={{
                        pathname: R.services,
                        hash: '#root',
                        state: {
                          servicesType: 'acceptanceOfApartments',
                          servicesTypeSecondary: undefined
                        }
                      }}
                    >
                      приемка квартиры
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: R.services,
                        hash: '#root',
                        state: {
                          servicesType: 'renovationOfApartments',
                          servicesTypeSecondary: undefined
                        }
                      }}
                    >
                      ремонт квартир
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: R.services,
                        hash: '#root',
                        state: {
                          servicesType: 'renovationOfСottage',
                          servicesTypeSecondary: undefined
                        }
                      }}
                    >
                      ремонт коттеджей
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: R.services,
                        hash: '#root',
                        state: {
                          servicesType: 'acceptanceOfOffice',
                          servicesTypeSecondary: undefined
                        }
                      }}
                    >
                      ремонт офисов
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-nav-item">
                <p>Цены</p>
                <ul className="footer-nav-item-list">
                  <li>
                    <Link to={R.prices}>стоимость ремонта</Link>
                  </li>
                  <li>
                    <Link
                      to={`${R.prices}#estimate`}
                      scroll={el =>
                        el.scrollIntoView({
                          behavior: 'instant',
                          block: 'center'
                        })
                      }
                    >
                      образцы смет
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${R.prices}${R.promotions}`}
                      scroll={el =>
                        el.scrollIntoView({
                          behavior: 'instant',
                          block: 'center'
                        })
                      }
                    >
                      акции
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="instagram-wrapper">
              <h5 className="mb-3 text-white">INSTAGRAM</h5>
              <div className="instagram-container">
                {instagram && instagram.data && instagram.dodata.length > 9 ? (
                  <Instagram instagram={instagram} />
                ) : (
                  <InstagramDefault />
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="auto" md={6} className="d-flex">
            <p
              style={{ fontSize: 14 }}
              className=" mb-0 text-white mt-auto mt-md-0"
            >
              ООО "Студия Ловко" УНП 193613041
            </p>
          </Col>
          <Col xs="auto" md={6}>
            <div className="social-block">
              <div className="social-block-text">
                <b>Подписывайтесь</b> на нас в социальных сетях и
                <b> узнавайте первыми</b> о всех акциях и новостях
              </div>
              <div className="social-block-links">
                <div className="social-block-links-item social-block-links-item-inst">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-labelledby="instagram"
                    href="https://www.instagram.com/lovko.remont_minsk/"
                  >
                    instagram
                  </a>
                </div>
                <div className="social-block-links-item social-block-links-item-fb">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-labelledby="facebook"
                    href="https://www.facebook.com/lovkoremont/?eid=ARBzz1wYkbwhQyllTqqkqD2gsi8udRd5Fq0H9hZ6_3W_kqKp-F6sD0_G1c5GAAsyiwISKkZX77i0Wmy1"
                  >
                    facebook
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const mapDispachToProps = dispatch => ({
  toggleModalContactWithUs: boolean => {
    dispatch(toggleModalContactWithUs(boolean));
  }
});

const mapStateToProps = state => ({
  instagram: state.instagram
});

export default connect(
  mapStateToProps,
  mapDispachToProps
)(Footer);
