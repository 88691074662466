import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { toggleMobileNav } from '../actions';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';
import { R } from '../Routes';

const ErrorPage = props => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{TitleAndDesc.errorPage.title}</title>
        <meta name="description" content={TitleAndDesc.errorPage.desc} />
      </Helmet>
      <div className="error-page-wrapper">
        <section className="header">
          <Container>
            <Row>
              <Col
                xs={12}
                md="auto"
                className="d-flex flex-column justify-content-end header-top-wrapper"
              >
                <div className="header-logo img-container">
                  <Link to="/">
                    <img src={process.env.PUBLIC_URL + '/assets/images/logo_white.png'} alt="header-logo" />
                  </Link>
                </div>
                <div
                  className="header-top-wrapper-mobile-nav"
                  onClick={() => props.toggleMobileNav(true)}
                >
                  <img src={process.env.PUBLIC_URL + '/assets/images/menu-button.svg'} alt="mobile-nav" />
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-end h-100">
                  <div className="header-main-wrapper">
                    <p className="mb-0 text-right text-gray line-height-1 ">
                      работаем в Минске и Минской области
                    </p>
                    <h1 className="text-size-30 mb-1 text-gray text-right line-height-small ">
                      Ремонт квартир, коттеджей, офисов
                    </h1>
                    <nav className="header-nav">
                      <ul className="header-nav-list box-shadow-half">
                        <li>
                          <Link to={R.projects}>реализованные проекты</Link>
                        </li>
                        <li className="header-nav-list-with-nav">
                          <Link
                            to={{
                              pathname: R.services,
                              hash: '#root',
                              state: {
                                servicesType: 'renovationOfApartments',
                                servicesTypeSecondary: undefined
                              }
                            }}
                          >
                            услуги
                          </Link>
                          <ul className="header-nav-list-secondary">
                            <li>
                              <Link
                                to={{
                                  pathname: R.services,
                                  hash: '#root',
                                  state: {
                                    servicesType: 'acceptanceOfApartments',
                                    servicesTypeSecondary: undefined
                                  }
                                }}
                              >
                                приемка квартиры
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: R.services,
                                  hash: '#root',
                                  state: {
                                    servicesType: 'renovationOfApartments',
                                    servicesTypeSecondary: undefined
                                  }
                                }}
                              >
                                ремонт квартир
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: R.services,
                                  hash: '#root',
                                  state: {
                                    servicesType: 'renovationOfСottage',
                                    servicesTypeSecondary: undefined
                                  }
                                }}
                              >
                                ремонт коттеджей
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: R.services,
                                  hash: '#root',
                                  state: {
                                    servicesType: 'acceptanceOfOffice',
                                    servicesTypeSecondary: undefined
                                  }
                                }}
                              >
                                ремонт офисов
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: R.prices,
                              state: { toElem: 'bar' }
                            }}
                          >
                            стоимость ремонта
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`${R.prices}${R.promotions}`}
                            scroll={el =>
                              el.scrollIntoView({
                                behavior: 'instant',
                                block: 'center'
                              })
                            }
                          >
                            акции
                          </Link>
                        </li>
                        <li className="header-nav-list-with-nav">
                          <Link to={`${R.about}#root`}>о комании</Link>
                          <ul className="header-nav-list-secondary">
                            <li>
                              <Link to={`${R.about}#root`}>о комании</Link>
                            </li>
                            <li>
                              <Link
                                to={`${R.about}#komanda`}
                                scroll={el =>
                                  el.scrollIntoView({
                                    behavior: 'instant',
                                    block: 'center'
                                  })
                                }
                              >
                                команда
                              </Link>
                            </li>
                            {/*<li>*/}
                            {/*  <Link*/}
                            {/*    to={`${R.about}#certificates`}*/}
                            {/*    scroll={el =>*/}
                            {/*      el.scrollIntoView({*/}
                            {/*        behavior: 'instant',*/}
                            {/*        block: 'center'*/}
                            {/*      })*/}
                            {/*    }*/}
                            {/*  >*/}
                            {/*    сертификаты*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            <li>
                              <Link
                                to={`${R.about}#otzyvy`}
                                scroll={el =>
                                  el.scrollIntoView({
                                    behavior: 'instant',
                                    block: 'center'
                                  })
                                }
                              >
                                отзывы
                              </Link>
                            </li>
                            <li>
                              <Link to={`${R.about}#kontakty`}>
                                контакты
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="error-page-content text-white text-right ">
            <p className="text-size-120 f-w-600 m-0 line-height-1">404</p>
            <h2 className="m-0 f-w-600">Поздравляем!</h2>
            <p className="m-0 text-size-24 line-height-1">
              Вы только что нашли нашу очень секретную страницу сайта!
            </p>
            <p className="text-size-24 line-height-1">
              И получаете дополнительную скидку!
            </p>
            <h2 className="f-w-600 mb-0">Ваша скидка 3%</h2>
            <p className="text-size-24 m-0">
              По всем вопросам звоните: +375 29 561 02 88
            </p>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapDispachToProps = dispatch => ({
  toggleMobileNav: boolean => {
    dispatch(toggleMobileNav(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(ErrorPage);
