export const IDEA_4 = [
  {
    type: 'idea_with_img',
    img: '1-stil-rokoko.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Рококо: редкая жемчужина'
  },
  {
    type: 'idea_with_title',
    title: 'ОСОБЕННОСТИ',
    text: [
      'Роккоко можно назвать утончённым, чувственным, романтичным, королевским стилем. Ему присуща молодость, женственность, иллюзии. Он отражает собой мир веселья, карнавалов, будуаров, флирта, балов. Появился в первой половине XVIII века (поздний барокко) во Франции. От стиля барокко он отличается большей утонченностью и нежностью, в нем больше нюансов. Ему присущи изогнутые формы, завитки и драгоценные украшения, элегантные кривые линии.',
      'Наиболее часто в стиле рококо оформляют коттеджи, поскольку благодаря этому стилю получаются эксклюзивные, элитные интерьеры.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-stil-rokoko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ЦВЕТА',
    text: [
      'Позолота важна для этого стиля, поскольку она свидетельствует о достатке и изысканном вкусе хозяина. Интерьеры жилых комнат в этом стиле делают в мягких, теплых, пастельных тонах (сочетания белого, голубого, желтого, терракотового, розового). В комнатах общего пользования применяют красный, зеленый, синий.' +
      'Светлые тона в сочетании с замысловатыми ажурными формами подарят праздничное настроение.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-stil-rokoko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Основными материалами, применяемыми в данном стиле, являются благородные породы дерева, золото, бронза, шелк, мрамор, гобелены, ткани (в основном шелк).'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-stil-rokoko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'Комнаты, которые напоминают дворцовые покои, обставляют роскошной мебелью из беленого дерева, которую украшают позолотой, виньетками, ювелирной резьбой с изогнутыми ножками.',
      'Секретеры с потайными ящиками, комоды, канапе, изящные туалетные столики – характерная мебель этого стиля. Кровать должна превратиться в царское ложе, можно украсить ее балдахином.',
      'Ну и, конечно, нельзя забывать про камин, он является важным элементом этого стиля, его украшают орнаментом.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ОКНА и ОСВЕЩЕНИЕ',
    text: [
      'Центральным элементом в освещении является элегантная люстра со «свечами», подвесками, золочеными цепями. Окна украшают ламбрекенами и шторами из благородных тканей.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5-stil-rokoko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ, СТЕНЫ, ПОТОЛОК',
    text: [
      'Отделка всех поверхностей является настоящим произведением искусства.',
      'Стены декорируют лепниной, художественной росписью, антикварными картинами (либо написанными в этой стилистике), овальными зеркалами, шелковыми и бумажными элитными обоями. Обычно стену делят на нескольку участков, на каждый из которых наносят свой декор.',
      'Ручная роспись или лепнина предадут особый шарм вашему интерьеру. Потолок, как и стены разделяют на несколько секций и в каждой изображают разные мотивы. Если же делают лепнину, то ее красят в золотой или белый цвет. По центру потолка должна быть большая, изящная люстра на длинном подвесе с большим количеством лампочек.',
      'На пол как правило укладывают художественный паркет из ценных пород дерева, который украшают изящными узорами и орнаментами. На пол можно положить ковры-гобелены.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '6-stil-rokoko.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'В интерьере размещают прекрасные вазы с цветочными орнаментами, статуэтки, шкатулки, позолоченные или бронзовые настенные часы, овальные зеркала в искусных рамах, светильники. Фарфоровая посуда и серебряные столовые приборы помогут воссоздать атмосферу дворцов.'
    ]
  },
];
