export const IDEA_6 = [
  {
    type: 'idea_with_img',
    img: '1-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Классицизм: дух резиденций.'
  },
  {
    type: 'idea_with_title',
    title: 'ОСОБЕННОСТИ',
    text: [
      'Классицизм зародился при правлении Наполеона I Бонапарта. Будучи полководцем, он предпочитал практичные и незамысловатые вещи и интерьеры. Однако он ведь был императором и нужно было, чтобы его резиденции соответствовали его статусу, быть статусными и роскошными. Именно исходя из этих двух моментов и родился классицизм.',
      'Он схож с такими стилями как ампир и барокко, однако он является более аристократичными и менее помпезным.',
      'В настоящее время используют этот стиль для того, чтобы подчеркнуть хороший вкус и высокий статус владельца.',
      'Для него характерна симметрия и правильные формы, в нем все лаконично, практично, функционально, в нем отсутствует излишняя вычурность.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ЦВЕТА',
    text: [
      'Цветовая гамма: бежевая, кремовая, пастельная (с коричневыми и зелеными элементами).'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'МАТЕРИАЛЫ',
    text: [
      'Применяются дорогостоящие материалы, такие как позолота, натуральные качественные ткани (распространено применение атласа, бархата, шелка и других «богатых» тканей), ценные породы древесины, натуральный камень, паркет.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'МЕБЕЛЬ',
    text: [
      'Изящная мебель «без излишеств»: секретер, кресла (с применение позолоты и лампасной обивки), диваны, которые можно обтянуть гобеленами, комоды (на которых могут быть мраморные столешницы). Текстиль для мебели выбирают с цветочными орнаментами.',
      'Камин обкладывают камнем, либо плиткой (имитирующей камень).'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОКНА',
    text: [
      'Большие окна со шторами из дорогостоящих, тяжелых, красиво драпирующихся тканей (шторы можно делать из парчи, тафты, бархата, жаккарда, шелка). А вот гардины следует делать легкими и струящимися (из органзы, шифона, кристаллона, вуали), украсить оконную композицию помогут подвязки с кисточками.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '5-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ОСВЕЩЕНИЕ',
    text: [
      'Делают разные сценарии освещения: центральная, утонченная, хрустальная люстра, настенные светильники-бра, напольные торшеры, которые создадут мягкую зонированную подсветку. Лампочки в этом стилистическом решении идеально подходят в форме свечей.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '6-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПОЛЫ, СТЕНЫ, ПОТОЛОК',
    text: [
      'При оформлении стен, пола, потолка следует придерживаться симметрии, поскольку это основной закон классицизма. Именно исходят из этого при отделке стены, двери и окна, которые располагаются друг напротив друга делать идентичными (либо в одном тоне). В интерьере созданном в этом стиле должна быть гармония.',
      'На пол устилают наборный, мозаичный паркет с орнаментом.',
      'Стены можно обтянуть тканями (либо обоями, имитирующими ткани, из тонкого, натурального волокна), нанести декоративную штукатурку. А вот низ стены можно декорировать панелями из дерева или ткани.',
      'Потолок, как правило, делать простым, одна допустимо украшение медальоном или лепниной по контуру.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '7-stil-klassicizm.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ДЕКОР',
    text: [
      'Не стоит увлекаться большим разнообразием декоративных элементов, их должно быть в меру.',
      'Доминирующим интерьерным элементом может стать большое зеркало в изящной раме.',
      'Вы можете дополнить свой интерьер скульптурами, вазами, картинами, высокими книжными шкафами, камином, антиквариатом, изящной посудой из фарфора. Все элементы декора должны подчеркивать утонченный стиль и аристократизм.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '8-stil-klassicizm.jpg'
  },
];
