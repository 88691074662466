import { ServicesElems as S } from './constants';
export const UKLADKA = [
  {
    type: S.withImg,
    img: 'ukladka-1.jpeg'
  },
  {
    type: S.withLine,
    text:
      'К числу наилучших и самых распространенных напольных покрытий относятся: ламинат, паркет, керамическая плитка, пробковый пол, ковролин, линолеум, массивная доска, керамогранит.'
  },
  {
    type: S.withTitle,
    title: 'ЛАМИНАТ'
  },
  {
    type: S.withList,
    title: 'Преимуществами ламината являются:',
    list: [
      'доступная стоимость;',
      'большой выбор цветов;',
      'простой монтаж;',
      'высокий уровень износостойкости — верхний слой покрытия надежно защищает его от негативного воздействия химических веществ и механических повреждений;',
      'пожаробезопасность;',
      'достаточно прочный материал (устойчив к нагрузкам) – на нем не остается вмятин от тяжелой мебели.'
    ]
  },
  {
    type: S.withImg,
    img: 'ukladka-2.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ПАРКЕТ',
    text:
      'Покрытие из натурального дерева выглядит выигрышно в сравнении с другими видами покрытий. Главные причины, по которым паркет считается отличным напольным покрытием — это его естественность и теплота. Поэтому, планируя устройство полов (Минск), данный вариант стоит рассматривать в первую очередь.'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'износостойкость;',
      'возможность быстро произвести ремонт или замену поврежденного элемента;',
      'прочность;',
      'простота в уходе;',
      'продолжительный срок эксплуатации;',
      'экологичность;',
      'возможность комбинирования;',
      'неповторимый стиль.'
    ]
  },
  {
    type: S.withImg,
    img: 'ukladka-3.jpeg'
  },
  {
    type: S.withTitle,
    title: 'КЕРАМИЧЕСКАЯ ПЛИТКА'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'водонепроницаемость;',
      'простота в уходе;',
      'плитка устойчива к воздействию плесени, гнили, микробов и т.п;',
      'выдерживает высокую нагрузку;',
      'большой выбор размеров, фактур, цветов и дизайнерских решений;',
      'износоустойчивость;',
      'экологичность;',
      'пожаробезопасность.'
    ]
  },
  {
    type: S.withImg,
    img: 'ukladka-4.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ПРОБКА'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'натуральность — это одно из важнейших преимуществ пробкового пола, ведь он производится из пробкового дерева;',
      'эластичность — пробковый пол может восстанавливать свою форму. Если на такой пол поставить тяжелую мебель, а потом убрать ее, на поверхности пола останутся небольшие вмятины. Однако, уже спустя пару дней поверхность снова станет ровной;',
      'на пробковом полу не образуются плесень и грибок, он не скользит, не гниет и не впитывает запахи;',
      'пробковый пол — это отличный тепло- и звукоизолятор;',
      'приятный и легкий цвет пробки делает такие полы оригинальными;',
      'пробковый пол очень приятный и теплый. Дерево передает свою положительную энергию и улучшает настроение и здоровье. Учеными доказано, что благодаря эластичности и упругости пола из пробки, уменьшается давление на суставы и позвоночник;',
      'антистатические свойства пола из пробки обеспечивают надежность, безопасность и прочность покрытия, делая его долговечным.'
    ]
  },
  {
    type: S.withImg,
    img: 'ukladka-5.jpeg'
  },
  {
    type: S.withTitle,
    title: 'КОВРОЛИН',
    text:
      'Данный вид покрытия состоит из основы и ворса. Ковролин имеет законченный рисунок (в отличие от ковра), мелкий узор и пропорциональные размеры. Продается это покрытие в рулонах и может быть натуральным или синтетическим.'
  },
  {
    type: S.withImg,
    img: 'ukladka-6.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ЛИНОЛЕУМ',
    text:
      'Линолеум эластичен и пластичен, что способствует его длительной эксплуатации. Также он является хорошим звукоизоляционным материалом и обладает низкой теплопроводностью. Линолеуму не страшна влага. Приемлемая стоимость линолеума является его преимуществом.'
  },
  {
    type: S.withImg,
    img: 'ukladka-7.jpeg'
  },
  {
    type: S.withTitle,
    title: 'МАССИВНАЯ ДОСКА',
    text:
      'Данный вид покрытия имеет эстетический и презентабельный внешний вид, а также хорошие эксплуатационные характеристики. На параметры износостойкости пола из массивной доски влияют порода дерева, размер доски и ее толщина.'
  },
  {
    type: S.withList,
    title: 'Преимущества:',
    list: [
      'внешний вид;',
      'экологичность;',
      'у покрытия их массивной доски хорошие звуко- и теплоизоляционные свойства;',
      'прочность;',
      'долговечность.'
    ]
  },
  {
    type: S.withImg,
    img: 'ukladka-8.jpeg'
  },
  {
    type: S.withTitle,
    title: 'КЕРАМОГРАНИТ',
    text:
      'Керамогранит — это эстетически привлекательное и практичное покрытие. Одним из важных преимуществ данного вида покрытия является его повышенная прочность и исключительная твердость.Благодаря своей однородной структуре, а также отсутствию пустот внутри, керамогранит обладает очень низким коэффициентом водопоглощения. '
  },
  {
    type: S.withImg,
    img: 'ukladka-9.jpeg'
  },
  {
    type: S.withTitle,
    text:
      'Планируете провести в своем доме или квартире ремонт и заменить напольные покрытия? Наши квалифицированные специалисты быстро и качественно осуществят монтаж полов в Минске и Минской области используя современное оборудование.'
  }
];
