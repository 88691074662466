import React from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { SERVICES_MAIN_TABS_CONTENT, SERVICES_MAIN_TABS } from '../constants';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Popup from 'reactjs-popup';
import { R } from '../Routes';

class Services extends React.Component {
  state = {
    servicesType: undefined,
    servicesTypeSecondary: undefined
  };

  componentDidMount() {
    const { state: routerState } = this.props.location;
    this.setState({
      servicesType: routerState && routerState.servicesType,
      servicesTypeSecondary: routerState && routerState.servicesTypeSecondary
    });
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      servicesType:
        (nextprops.location.state && nextprops.location.state.servicesType) ||
        undefined,
      servicesTypeSecondary:
        (nextprops.location.state &&
          nextprops.location.state.servicesTypeSecondary) ||
        undefined
    });
  }

  changeMainTab = key => this.setState({ servicesType: key });

  changeSecondaryTab = key => this.setState({ servicesTypeSecondary: key });

  render() {
    const { servicesPage } = this.props;
    const {
      servicesType = 'renovationOfApartments',
      servicesTypeSecondary = 'capital'
    } = this.state;
    return (
      <section
        className='services'
        id='services'
        style={servicesPage ? { paddingTop: 0 } : null}
      >
        <Container>
          {servicesPage ? null : (
            <Row>
              <Col>
                <h2 className='text-gray f-w-400 text-center text-uppercase h2-small mb-2'>
                  Сделаем для вас
                </h2>
                <p className='text-blue f-w-400 text-size-20 text-center'>
                  Услуги студии ремонта lovko
                </p>
              </Col>
            </Row>
          )}
          <Tab.Container
            id='services-main-tab'
            defaultActiveKey={servicesType}
            activeKey={servicesType}
            onSelect={key => this.changeMainTab(key)}
          >
            <Row className='row-servises-custom'>
              <Col className='flex-grow-1 flex-md-grow-0 fix-ie-row-servises-custom'>
                <Nav variant='tabs' className='services-main-tabs-wrapper'>
                  {SERVICES_MAIN_TABS.map(item => (
                    <Nav.Item key={item.id} className='services-main-tab-item'>
                      <Nav.Link eventKey={item.activeKey}>
                        <img
                          className='services-main-tab-item-img'
                          src={`${process.env.PUBLIC_URL}/assets/images/services/${item.img}`}
                          alt={item.img}
                        />
                        <div className='services-main-tab-item-text'>
                          <span>{item.title}</span>
                          <span>
                            <b>{item.text}</b>
                          </span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col>
                <Tab.Content className='services-main-tab-content'>
                  {SERVICES_MAIN_TABS_CONTENT.map(item => (
                    <Tab.Pane key={item.eventKey} eventKey={item.eventKey}>
                      <Tab.Container
                        id={`services-secondary-tab-${item.eventKey}`}
                        defaultActiveKey={servicesTypeSecondary}
                        activeKey={servicesTypeSecondary}
                        onSelect={key => this.changeSecondaryTab(key)}
                      >
                        {item.secondaryTabs ? (
                          <Nav
                            variant='tabs'
                            className='services-secondary-tab-nav box-shadow-half mb-3'
                          >
                            {item.secondaryTabs.map(elem => (
                              <Nav.Item key={elem.id}>
                                <Nav.Link eventKey={elem.titleEn}>
                                  {elem.title}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        ) : null}
                        <Tab.Content>
                          {item.secondaryTabs ? (
                            item.secondaryTabs.map(elem => (
                              <Tab.Pane
                                key={elem.titleEn}
                                eventKey={elem.titleEn}
                              >
                                <p className='text-gray text-md-justify text-left'>
                                  {elem.tabtext}
                                </p>
                                <div className='servises-popup-wrapper text-left'>
                                  {elem.tabContent.map((el, i) => (
                                    <div
                                      key={i}
                                      className='servises-popup-item'
                                    >
                                      <Popup
                                        className='popup-styles'
                                        closeOnDocumentClick
                                        trigger={
                                          <span
                                            style={{
                                              cursor: el.text
                                                ? 'pointer'
                                                : 'default'
                                            }}
                                            className='servises-popup-item-title box-shadow-half text-gray '
                                          >
                                            {el.title}
                                          </span>
                                        }
                                        position='bottom center'
                                        closeOnEscape
                                        disabled={!el.text}
                                      >
                                        <div className='servises-popup-item-content'>
                                          {ReactHtmlParser(el.text)}
                                        </div>
                                      </Popup>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${elem.tabImage})`
                                  }}
                                  className='services-main-tabs-image'
                                />
                              </Tab.Pane>
                            ))
                          ) : (
                            <div className='general-tabs-container-without-secondary-tab'>
                              <div className='text-gray text-md-justify text-left'>
                                {ReactHtmlParser(item.text)}
                                <div className='servises-popup-wrapper text-left'>
                                  {item.tabContent.map((el, i) => (
                                    <div
                                      key={i}
                                      className='servises-popup-item full box-shadow-half'
                                    >
                                      <Popup
                                        closeOnDocumentClick
                                        className='popup-styles'
                                        trigger={
                                          <span
                                            style={{
                                              cursor: el.text
                                                ? 'pointer'
                                                : 'default'
                                            }}
                                            className='servises-popup-item-title text-gray '
                                          >
                                            {el.title}
                                          </span>
                                        }
                                        position='bottom center'
                                        closeOnEscape
                                        disabled={!el.text}
                                      >
                                        <div className='servises-popup-item-content'>
                                          {ReactHtmlParser(el.text)}
                                        </div>
                                      </Popup>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${item.tabImage})`
                                }}
                                className='services-main-tabs-image'
                              />
                            </div>
                          )}
                          {servicesPage ? null : (
                            <div className='services-button-wrapper'>
                              <Button
                                size='small'
                                boxshadow='half'
                                blue
                                className='mt-auto'
                              >
                                <Link to={R.services}>больше информации</Link>
                              </Button>
                            </div>
                          )}
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    );
  }
}

export default withRouter(Services);
