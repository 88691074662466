export const TitleAndDesc = {
  mainPage: {
    title: 'Ремонт квартир в Минске! Честные цены, договор, гарантия!',
    desc:
      "Студия ремонта 'lovko' – комплексный ремонт квартир, коттеджей, офисов. Звоните нам, и Вы получите честные цены, индивидуальный подход, оперативность и профессиональную работу!"
  },
  servicesPage: {
    title:
      'Ремонт под ключ в Минске! Комплексный ремонт квартир, коттеджей, офисов!',
    desc:
      'Весь комплекс ремонтно-отделочных работ квартир, коттеджей, офисов. Договор, гарантия. Фиксированная смета по честным ценам! Квалифицированные работники, профессиональный ремонт квартир, коттеджей, офисов в Минске и области. Консультация, бесплатная приемка квартиры, доставка материала, работаем по таймингам!'
  },
  errorPage: {
    title: 'lovko.by - Ремонт квартир в Минске.',
    desc:
      "Студия ремонта 'lovko' – комплексный ремонт квартир, коттеджей, офисов. Звоните нам, и Вы получите честные цены, индивидуальный подход, оперативность и профессиональную работу!"
  },
  aboutPage: {
    title: 'Компания по ремонту квартир, коттеджей, офисов под ключ в Минске',
    desc:
      'Студия ремонта «lovko». Качественный ремонт «под ключ» квартир, коттеджей и офисов в Минске и Минской области. Скидки при заказе ремонта через сайт! Честные цены, поэтапная оплата, индивидуальный подход, оперативность и профессиональная работа!'
  },
  pricesPage: {
    title:
      'Цены на ремонт квартир в Минске. Договор, честные цены, профессиональный ремонт!',
    desc:
      'Каждый ремонт - индивидуальный проект. Мы понимаем желания наших Клиентов и всегда готовы сделать шаг навстречу при определении стоимости ремонта квартир, коттеджей, офисов. Выезд специалиста для составления сметы! Звоните +375 29 561 02 88'
  },
  projectsPage: {
    title: 'Фото ремонта квартир. Ремонт «под ключ» в Минске!',
    desc:
      'Фото выполненных работ по ремонту квартир под ключ. Скидки при заказе ремонта через сайт! Звоните +375 29 561 02 88. Давайте начнем Ваш lovko ремонт!'
  },
  ideasPage: {
    title: 'Полезные статьи про ремонт, отделку и дизайн.',
    desc:
      'Каталог статей по ремонту, отделке и дизайну помещений. Советы по ремонту квартир, коттеджей, офисов от студии ремонта lovko.'
  }
};
