import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { Picture } from "react-responsive-picture";

const TYPE_OF_REPAIRS = [
  { label: 'квартира', name: 'apartment' },
  { label: 'офис', name: 'office' },
  { label: 'кафе', name: 'cafe' },
  { label: 'дом', name: 'house' },
  { label: 'магазин', name: 'shop' },
  { label: 'другое', name: 'another' }
];

const FirstStep = props => {
  const { changeRepair } = props;
  return (
    <div>
      <p className="text-size-20 f-w-600 md-2 mb-md-4">
        В каком помещении Вам необходимо сделать ремонт?{' '}
      </p>
      <div className="stepper-radio-wrapper pb-2">
        {TYPE_OF_REPAIRS.map((item, index) => (
          <Form.Check
            key={index}
            type="radio"
            label={item.label}
            name="type-of-repair"
            id={`${item.label}`}
            onChange={e => changeRepair(e.target.id)}
          />
        ))}
      </div>
    </div>
  );
};

const SecondStep = props => {
  const { changeObjectArea } = props;
  return (
    <div>
      <p className="text-size-20 f-w-600  md-2 mb-md-4">
        Укажите площадь объекта (м<sup>2</sup>)
      </p>
      <Form className="form-fifth-step pb-2">
        <Form.Group controlId="square">
          <Form.Control
            onChange={e => changeObjectArea(e.target.value)}
            type="text"
            placeholder="Введите размеры (м2)"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

const DESIGN_PROJECT = [
  { label: 'да', name: 'yes' },
  { label: 'нет', name: 'no' },
  { label: 'есть представление что будет', name: 'maybe' }
];

const ThirdStep = props => {
  const { changeDesignProject } = props;
  return (
    <div>
      <p className="text-size-20 f-w-600  md-2 mb-md-4">
        Наличие дизайн-проекта:
      </p>
      <div className="stepper-radio-wrapper pb-2">
        {DESIGN_PROJECT.map((item, index) => (
          <Form.Check
            key={index}
            onChange={e => changeDesignProject(e.target.id)}
            type="radio"
            label={item.label}
            name="design-project"
            id={`${item.label}`}
          />
        ))}
      </div>
    </div>
  );
};

const DEADLINE_ITEMS = [
  { label: 'через 1 месяц', name: '1-months' },
  { label: 'через 3 месяца', name: '3-months' },
  { label: 'в течениe 6 месяцев', name: '6-months' },
  { label: 'в течениe 9 месяцев', name: '9-months' },
  { label: 'в течениe года', name: '1-year' }
];

const FourthStep = props => {
  const { changeDeadline } = props;
  return (
    <div>
      <p className="text-size-20 f-w-600  md-2 mb-md-4">
        Когда Вы бы хотели получить готовый ремонт
      </p>
      <div className="stepper-radio-wrapper pb-2">
        {DEADLINE_ITEMS.map((item, index) => (
          <Form.Check
            onChange={e => changeDeadline(e.target.id)}
            key={index}
            type="radio"
            label={item.label}
            name="deadline"
            id={`${item.label}`}
          />
        ))}
      </div>
    </div>
  );
};

const FifthStep = props => {
  const { changePhone, changeName } = props;
  return (
    <div>
      <p className="text-size-20 f-w-600  md-2 mb-md-4">
        Введите, пожалуйста, Ваши контактные данные:
      </p>
      <Form className="form-fifth-step pb-2">
        <Form.Group controlId="name">
          <Form.Label>Имя</Form.Label>
          <Form.Control
            onChange={e => changeName(e.target.value)}
            type="text"
            placeholder="Как Вас зовут"
          />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label>Телефон</Form.Label>
          <Form.Control
            onChange={e => changePhone(e.target.value)}
            type="text"
            placeholder="+375 (29) --- -- --"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

const FinishStep = () => {
  return (
    <div className="finish-step">
      <div className="finish-step-boss">
          <Picture
              srcSet= {process.env.PUBLIC_URL + '/assets/images/boss.jpg'}
              sources={[
                  {srcSet: process.env.PUBLIC_URL + '/assets/images/boss.jpf', type: "image/jpm"},
                  {srcSet: process.env.PUBLIC_URL + '/assets/images/boss.webp', type: "image/webp"}
              ]}
              alt="boss" />
      </div>
      <div className="finish-step-text">
        <p className="text-size-20 text-gray d-none d-md-block">
          Мы рады начать с Вами
        </p>
        <div className="finish-step-text-logo mb-2 mb-md-4">
          <img src= {process.env.PUBLIC_URL + '/assets/images/lovko_remont.svg'} alt="Lovko ремонт логотип" />
        </div>
        <p className="text-size-20 text-gray mb-3">
          Я <span className="f-w-600">позвоню Вам</span> <br />в ближайшее
          время,
          <br />
          мы <span className="f-w-600">обсудим все вопросы</span> <br />и наши{' '}
          <span className="f-w-600">дальнейшие действия</span>
        </p>
        <div className="finish-step-footer text-right">
          <p>Алексей Бузо</p>
          <p>+375 (29) 561 02 88</p>
        </div>
      </div>
    </div>
  );
};

const StepperForm = props => {
  const {
    step,
    changeRepair,
    changeObjectArea,
    changeDesignProject,
    changeDeadline,
    changePhone,
    changeName
  } = props;
  return (
    <Fragment>
      {step === 1 && <FirstStep changeRepair={changeRepair} />}
      {step === 2 && <SecondStep changeObjectArea={changeObjectArea} />}
      {step === 3 && <ThirdStep changeDesignProject={changeDesignProject} />}
      {step === 4 && <FourthStep changeDeadline={changeDeadline} />}
      {step === 5 && (
        <FifthStep changePhone={changePhone} changeName={changeName} />
      )}
      {step === 6 && <FinishStep />}
    </Fragment>
  );
};

export default StepperForm;
