import { ServicesElems as S } from './constants';
export const REMONT_MAGAZINOV = [{
  type: S.withImg,
  img: 'remont_magazinov.jpg'
},
{
  type: S.withLine,
  text:
    'Мы делаем ремонт торговых площадей «под ключ» в Минске и Минском районе. Торговые площади с хорошим, приятным ремонтом помогают привлечь больше покупателей, поэтому ремонт магазинов должен делаться профессионально.'
},
{
  type: S.withList,
  title: 'Мы будем рады выполнить ремонт любых помещений, предназначенных для магазинов:',
  list: ['Любого размера торговых площадей (бутики, магазины на несколько этажей и т.д.)',
    'Магазинов, которые располагаются в отдельностоящих / встроенных помещениях или же находятся в цокольных этажах',
    'Специализированных или комбинированных магазинах',
    'Вновь отрывающихся магазинах или которые существуют давно'
  ]
},
{
  type: S.withTitle,
  text: 'Наши специалисты сделают надежные инженерные системы, которые необходимы для монтажа и функционирования дорогого, современного оборудования. '
},
]