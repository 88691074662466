import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Banner(props) {
  return (
    <section className={`banner ${props.className ? props.className : ""}`}>
      <Container>
        <Row className="flex-wrap flex-lg-nowrap">
          <Col xs={12} md="auto" className="flex-shrink-1">
            <div>{props.children[0]}</div>
          </Col>
          <Col
            xs={12}
            md="auto"
            className="align-self-end flex-shrink-0 ml-auto"
          >
            <div className="d-flex justify-content-end">
              {props.children[1]}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Banner;
