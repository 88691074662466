import { ServicesElems as S } from './constants';

export const POTOLOK = [
  {
    type: S.withImg,
    img: 'potolok-1.jpeg'
  },
  {
    type: S.withLine,
    text:
      'Виды потолков сегодня различаются большим многообразием, что дает возможность подобрать подходящую для Вас конструкцию потолка.'
  },
  {
    type: S.withTitle,
    title: 'ПОДВЕСНЫЕ ПОТОЛКИ'
  },
  {
    type: S.withList,
    title:
      'Делая данный тип потолков можно сделать необыкновенное оформление, тем самым кардинально трансформировав эстетическое восприятие общего дизайна. Эти конструкции характеризуются исключительными декоративными возможностями и делятся на следующие типы:',
    list: [
      'Гипсокартонный потолок — экологичен, пластичен и легок. Используя его, можно создать на потолке необычные фигуры или сделать потолок идеально ровным.',
      'Реечный потолок — прост в установке, легок в уходе, отличается интересным дизайном. Характеризуется хорошими эксплуатационными свойствами и долговечностью.',
      'Грильято — обладает огромным количеством оттенков и имеет оригинальную геометрическую структуру, благодаря чему помещение приобретает оригинальный облик.',
      'Кассетный потолок — это каркас, который заполнен пластинами разной формы и разных видов. За счет нанесения порошковой краски, конструкция потолка приобретает дополнительную крепость.',
      'Армстронг — этот потолок представляет из себя конструкцию из металлических элементов с минеральными плитами. Он не склонен к деформациям, легко устанавливается, надежный и износостойкий. Чаще всего, данный тип потолков устанавливается в офисах.',
      'Панельный потолок. Максимально прост в установке, а благодаря своим продольным полосам дает возможность изменить геометрию помещения и создать в нем пространственные эффекты'
    ]
  },
  {
    type: S.withImg,
    img: 'potolok-2.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-3.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-4.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-5.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-6.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-7.jpg'
  },
  {
    type: S.withTitle,
    title: 'НАТЯЖНЫЕ ПОТОЛКИ',
    text:
      'Данный тип потолков является очень распространенным. За счет эластичности поливинилхлоридной пленки можно скрыть неровности или дефекты на поверхности.'
  },
  {
    type: S.withImg,
    img: 'potolok-8.jpeg'
  },
  {
    type: S.withTitle,
    title: 'КЛЕЕВЫЕ ВАРИАНТЫ ОТДЕЛКИ '
  },
  {
    type: S.withList,
    title:
      'Самыми распространенными типами клеевого оформления потолков являются:',
    list: [
      'Потолочная плитка — это покрытие для потолка из пенополистирола, которое может быть с матовой или ламинированной поверхностью и иметь орнамент, рельеф или абстрактный рисунок.',
      'Обои. Используя потолочные обои, можно сделать обстановку нестандартной и более яркой. Также этот тип отделки может быть использован для зонирования определенной части помещения. '
    ]
  },
  {
    type: S.withImg,
    img: 'potolok-9.jpeg'
  },
  {
    type: S.withTitle,
    title: 'ЧТО ЕЩЕ МОЖНО ИСПОЛЬЗОВАТЬ ДЛЯ ОТДЕЛКИ ПОТОЛКА?'
  },
  {
    type: S.withList,
    title:
      'Самыми распространенными типами клеевого оформления потолков являются:',
    list: [
      'Декоративная штукатурка. Такой потолок будет стильным элементом в интерьере, а еще и хорошо скрывающим дефекты и наличие неровностей, влагостойким, прочным покрытием. Также он может придать помещению более интересный вид.',
      'Зеркальный потолок. Благодаря великолепному отражающему свойству, зеркало наполнит помещение дополнительным светом, а также визуально увеличит его в размерах.',
      'Покраска. Используя креативный подход и большое количество необычных оттенков, можно придать потолку интересный облик.',
      'Кессонный потолок. Это конструкция с большим количеством ячеек и углублений, которые оформлены декоративными балками. Смотрится он достаточно роскошно и привлекательно.',
      'Стекло — этот материал может быть узорчатым, прозрачным, матовым или разноцветным. Такие модели дают возможность создать в интерьере нетипичное световое решение, придав ему максимально эффектный вид.',
      'Деревянный потолок — данный вариант потолочного оформления внесет в обстановку дома особый уют и тепло.',
      'Витражный потолок. Используя в качестве потолочной отделки художественное произведение из стекол разного цвета, обстановке можно придать нестандартности и особой яркости.',
      'Бетонный потолок. Это один из самых бюджетных вариантов. Если такой потолок дополнить различным декором, он приобретает интересный облик.',
      'Потолок из пластиковых панелей. Богатая цветовая гамма панелей из пластика дает возможность выбрать наиболее подходящий цвет, который идеально впишется в интерьер помещения.',
      'Потолок из декоративного камня. Отделка камнем позволяет сделать комнату более выразительной и яркой, максимально функциональной и технологичной. Данный тип отделки отлично подойдет для декорирования практически любого стиля.',
      'Потолок из алюминиевых панелей. Данный тип панелей очень практичен и подойдет для отделки любых помещений. ',
      'МДФ-панели. Эти панели внешне практически не имеют отличий от изделий из дерева. У них разнообразная цветовая гамма и они идеально имитируют древесину.',
      'Пробковый потолок. У пробки есть большое количество преимуществ, что дает возможность создать оригинальный высокоэстетический дизайн.',
      'Вагонка. За счет мягкого древесного цвета, потолочная вагонка создает приятную обстановку и формирует особый микроклимат, который располагает к отдыху.',
      'Паркет / ламинат. Данные материалы обладают эстетичностью, высокой прочностью и большим выбором фактурных и цветных решений. Благодаря данным параметрам этот вид отделки хорошо сочетается со всем декором.',
      'Комбинированный потолок – можно комбинировать всевозможные фактуры, материалы, цвета и сделать интересный и неординарный потолок. '
    ]
  },
  {
    type: S.withImg,
    img: 'potolok-10.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-11.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-12.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-13.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-14.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-15.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-16.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-17.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-18.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-19.jpeg'
  },
  {
    type: S.withImg,
    img: 'potolok-20.jpeg'
  }
];
