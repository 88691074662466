import React, { Component } from 'react';
import { Container, Row, Col, Tab } from 'react-bootstrap';
import TabsNav from '../components/TabsNav';
import ProjectsTabContent from '../components/ProjectsTabContent';
import Button from '../components/Button';
import {
  TABS_NAV_ITEMS,
  COMPLETED_PROJECTS
} from '../lovkoContent/completedProjects';
import { HashLink as Link } from 'react-router-hash-link';
import { R } from '../Routes';

class Projects extends Component {
  state = {
    projectsCount: null,
    showButton: true
  };
  componentDidMount() {
    const { projectsCount, projectPage } = this.props;
    projectPage && projectsCount > COMPLETED_PROJECTS.length
      ? this.setState({
          projectsCount: this.props.projectsCount,
          showButton: false
        })
      : this.setState({ projectsCount: this.props.projectsCount });
  }

  changeProjectsCount = () => {
    const { projectsCount } = this.state;
    if (projectsCount < COMPLETED_PROJECTS.length + 12) {
      this.setState({
        projectsCount: COMPLETED_PROJECTS.length,
        showButton: false
      });
    } else if (projectsCount > COMPLETED_PROJECTS.length + 12) {
      this.setState({ ideasCount: projectsCount + 12 });
    }
  };

  render() {
    const { withTitle, noTopPadiing, whiteTab, projectPage } = this.props;
    const { projectsCount, showButton } = this.state;
    return (
      <section
        style={noTopPadiing ? { paddingTop: 0 } : null}
        className="projects"
      >
        <Container>
          {withTitle && (
            <Row>
              <Col>
                <h2 className="text-gray f-w-400 text-center text-uppercase h2-small mb-3">
                  Реализованные проекты
                </h2>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Tab.Container id="section-projects" defaultActiveKey="all">
                <Row className="mobile-hide">
                  <Col className="mb-5">
                    <TabsNav whiteTab={whiteTab} tabselems={TABS_NAV_ITEMS} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ProjectsTabContent projectsCount={projectsCount} />
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          {showButton && (
            <Row className="mt-3 mt-mb-1">
              <Col className="d-flex justify-content-end">
                <Button
                  size="small"
                  className="flex-grow-1 flex-md-grow-0"
                  blue
                  boxshadow="half"
                  onClick={projectPage ? this.changeProjectsCount : null}
                >
                  {projectPage ? (
                    <span>больше проектов</span>
                  ) : (
                    <Link to={R.projects}>больше проектов</Link>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    );
  }
}

export default Projects;
