import { ServicesElems as S } from './constants';
export const POKRASKA = [
  {
    type: S.withImg,
    img: 'pokraska-1.jpg'
  },
  {
    type: S.withLine,
    text:
      'Окрашивание стен и потолка – это популярный вариант внутренней отделки стен и потолка. Краска для потолков и стен, которую сегодня предлагают производители, способна существенно расширить возможности дизайнеров, во время создания ими необычных дизайнерских проектов. '
  },
  {
    type: S.withList,
    title: 'Данный вид отделки имеет следующие преимущества:',
    list: [
      'Практичность в плане гигиены и чистоты — некоторые краски отлично переносят влажную уборку.',
      'Широкая палитра красок позволяет создать эксклюзивные интерьерные решения в любом стиле.',
      'Возможность обновить интерьер дома или квартиры без серьезных вложений — старый слой краски является хорошей основой для нанесения нового.'
    ]
  },
  {
    type: S.withList,
    title:
      'Основные типы интерьерных красок, а также составов для фасадных (наружных) работ:',
    list: [
      'акриловые;',
      'нитроэмали;',
      'силиконовые;',
      'кремнийорганические лаки;',
      'силикатные;',
      'дисперсии на основе ПВА;',
      'на масляной основе;',
      'алкидные эмали.  '
    ]
  },
  {
    type: S.withTitle,
    text: 'Приведем несколько примеров покрашенных нами стен и потолков:'
  },
  {
    type: S.withImg,
    img: 'pokraska-2.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-3.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-4.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-5.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-6.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-7.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-8.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-9.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-10.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-11.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-12.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-13.jpeg'
  },
  {
    type: S.withImg,
    img: 'pokraska-14.jpeg'
  }
];
