import { ServicesElems as S } from './constants';

export const REMONT_OFISOV = [{
  type: S.withImg,
  img: 'remont-ofisov-1.jpg'
},
{
  type: S.withLine,
  text:
    'Мы выполняем весь комплекс ремонтных работ в любых офисных помещениях (начиная от демонтажа и до индивидуальной конструкции вашего логотипа).'
},
{
  type: S.withTitle,
  text: 'Приведем ниже несколько типов ремонта офисных помещений:'
}, {
  type: S.withTitle,
  title: 'КОСМЕТИЧЕСКИЙ'
},
{
  type: S.withList,
  title: 'В рамках этого вида ремонта сделаем:',
  list: ['Демонтируем старые покрытия',
    'Произведем ремонт сантехнического оборудования',
    'Заменим выключатели, розетки, светильники',
    'Оштукатурим стены по плоскости (углы, линии плинтусов, наличников); произведем шпатлевание стен и потолков',
    'Уложим плитку и напольные покрытия (ламинат, линолеум, ковролин); покрасим стены и потолки; покрасим трубы и радиаторы отопления; поклеим обои; произведем монтаж плинтусов (пол)',
    'Уберем объект'
  ]
}, {
  type: S.withTitle,
  title: 'КАПИТАЛЬНЫЙ'
},
{
  type: S.withList,
  title: 'Этот вид ремонт включает такие работы:',
  list: [
    'Демонтируем перегородки и старые покрытия',
    'Выстроим новые перегородки и дверные проемы',
    'Произведем монтаж водопровода и канализации, установим инсталляцию или унитаз, заменим трубы и радиаторы отопления, установим вентили и смесители',
    'Произведем устройство электроточки, электромонтаж разветвительных коробок, смонтируем электрощиток, проложим силовой, UTP, телефонный кабель; установим вентилятор, розетки, выключатели, светильники',
    'Оштукатурим стены, зашьем стены гипсокартонном, оштукатурим потолки, сделаем потолки из гипсокартона, зашпатлюем потолки под покраску, сделаем стяжку пола',
    'Уложим плитку (на стены и пол), сделаем запил углов плитки под 45 градусов, покрасим стены и потолок, уложим напольные покрытия (паркетную доску, ламинат, кварц винил, ковровые покрытия), декорируем стены, поклеим обои, сделаем подвесные и натяжные потолки, произведем монтаж плинтусов',
    'Уберем объект'

  ]
}, {
  type: S.withTitle,
  title: 'ДИЗАЙНЕРСКИЙ'
},
{
  type: S.withList,
  title: 'При этом виде ремонта сделаем:',
  list: [
    'Демонтируем перегородки и старые покрытия',
    'Выстроим новые перегородки и дверные проемы для стандартных и нестандартных дверей',
    'Произведем монтаж водопровода и канализации, установим инсталляцию и унитаз, заменим трубы и радиаторы отопления, установим вентили и смесители',
    'Произведем устройство электроточки, электромонтаж разветвительных коробок, смонтируем электрощиток, проложим силовой, UTP, телефонный кабель; установим вентилятор, розетки, выключатели, светильники; установим систему «умный дом»',
    'Зашьем стены гипсокартоном, изготовим авторские конструкции, сделаем потолки любых видов, оштукатурим потолки и стены, зашпатлюем потолки под покраску, сделаем стяжку пола, гидроизоляцию',
    'Уложим плитку (разные размеры и класс плитки), запилим углы плитки под 45 градусов, декорируем стены любыми отделочными материалами, покрасим стены и потолок, уложим напольные покрытия (любые), декорируем откосы, поклеим обои, произведем монтаж плинтусов',
    'Уберем объект'
  ]
},
];

