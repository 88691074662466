export const MODAL_TOGGLE = 'MODAL_TOGGLE';
export const CHANGE_REPAIR = 'CHANGE_REPAIR';
export const CHANGE_OBJECT_AREA = 'CHANGE_OBJECT_AREA';
export const CHANGE_DESIGN_PROJECT = 'CHANGE_DESIGN_PROJECT';
export const CHANGE_DEADLINE = 'CHANGE_DEADLINE';
export const CHANGE_PHONE = 'CHANGE_PHONE';
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_REVIEW_NAME = 'CHANGE_REVIEW_NAME';
export const CHANGE_REVIEW_FILES = 'CHANGE_REVIEW_FILES';
export const CHANGE_REVIEW_TEXT = 'CHANGE_REVIEW_TEXT';
export const MODAL_TOGGLE_ACCEPTANCE_OF_APARTMETS =
  'MODAL_TOGGLE_ACCEPTANCE_OF_APARTMETS';
export const MODAL_TOGGLE_CONTACT_WITH_US = 'MODAL_TOGGLE_CONTACT_WITH_US';
export const MODAL_TOGGLE_THANKS = 'MODAL_TOGGLE_THANKS';
export const MODAL_TOGGLE_REVIEW_THANKS = 'MODAL_TOGGLE_REVIEW_THANKS';
export const GET_INSTAGRAM_FOTOS = 'GET_INSTAGRAM_FOTOS';
export const MODAL_TOGGLE_MOBILE_NAV = 'MODAL_TOGGLE_MOBILE_NAV';

export function toggleModal(boolean) {
  return {
    type: MODAL_TOGGLE,
    payload: boolean
  };
}

export function toggleModalAcceptanceOfApartments(boolean) {
  return {
    type: MODAL_TOGGLE_ACCEPTANCE_OF_APARTMETS,
    payload: boolean
  };
}

export function toggleModalContactWithUs(boolean) {
  return {
    type: MODAL_TOGGLE_CONTACT_WITH_US,
    payload: boolean
  };
}

export function toggleModalThanks(boolean) {
    return {
        type: MODAL_TOGGLE_THANKS,
        payload: boolean
    };
}

export function toggleModalReviewThanks(boolean) {
    return {
        type: MODAL_TOGGLE_REVIEW_THANKS,
        payload: boolean
    };
}

export function changeRepair(string) {
  return {
    type: CHANGE_REPAIR,
    payload: string
  };
}

export function changeObjectArea(string) {
  return {
    type: CHANGE_OBJECT_AREA,
    payload: string
  };
}

export function changeDesignProject(string) {
  return {
    type: CHANGE_DESIGN_PROJECT,
    payload: string
  };
}

export function changeDeadline(string) {
  return {
    type: CHANGE_DEADLINE,
    payload: string
  };
}

export function changePhone(string) {
  return {
    type: CHANGE_PHONE,
    payload: string
  };
}

export function changeName(string) {
  return {
    type: CHANGE_NAME,
    payload: string
  };
}

export function changeReviewName(string) {
    return {
        type: CHANGE_REVIEW_NAME,
        payload: string
    };
}

export function changeReviewText(string) {
    return {
        type: CHANGE_REVIEW_TEXT,
        payload: string
    };
}

export function changeReviewFiles(array) {
    return {
        type: CHANGE_REVIEW_FILES,
        payload: array
    };
}

export function getInsagramFotos(array) {
  return {
    type: GET_INSTAGRAM_FOTOS,
    payload: array
  };
}

export function toggleMobileNav(boolean) {
  return {
    type: MODAL_TOGGLE_MOBILE_NAV,
    payload: boolean
  };
}
