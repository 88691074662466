import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
    position:relative;
    background-color: ${props => (props.blue ? '#25BDFF' : '#FFFFFF')};
    color: ${props => (props.blue ? '#FFFFFF' : '#25BDFF')};
    transition-duration:0.3s;

    a{
      color:#ffffff;
      
      &:hover{
        color:#ffffff;
        text-decoration:none;
      }
    }

    &:hover{
      background-color: ${props => (props.blue ? '#16a8e5' : '#FFFFFF')};
    }

    ${props =>
      props.gray === true &&
      `
            background-color: #e2e3eb;
            color:#757575;
            a{
              color:#757575;
              &:hover{
                color:#757575;
              }
            }

            &:hover{
              background-color:#dcdce2;
            }
    `}

    ${props =>
      props.size === 'large' &&
      `
            padding:14px 38px;
            font-size:22px;

            @media (max-width: 768px) {
              padding:10px 30px;
              font-size:18px;
            }
    `}

    ${props =>
      props.size === 'medium' &&
      `
            padding:7px 34px;
            font-size: 20px;

            @media (max-width: 768px) {
              padding:7px 26px;
              font-size:16px;
            }
    `}

    ${props =>
      props.size === 'small' &&
      `
            padding:7px 20px;
            font-size:16px;

            @media (max-width: 768px) {
              padding:7px 16px;
              font-size:14px;
            }
    `}

    ${props =>
      props.boxshadow === 'full' &&
      `
            box-shadow: 2px 4px 6px rgba(0,0,0,0.5);
            &:hover{
                box-shadow: 2px 4px 10px rgba(0,0,0,0.7);
            }

    `}

    ${props =>
      props.boxshadow === 'half' &&
      `
            &:before{
                content: '';
                position: absolute;
                z-index: -1;
                bottom: 14px;
                left: 0;
                display: block;
                width: 50%;
                height: 10px;
                transform: rotate(-2deg);
                transition-duration: .4s;
                box-shadow: 5px 16px 10px #757575;
            }

            &:after{
                content: "";
                position: absolute;
                top: 100%;
                left: 3%;
                width: 94%;
                height: 2px;
                display: block;
                border-bottom-left-radius: 50%;
                border-bottom-right-radius: 50%;
                background-color: rgba(117, 117, 117, 0.3);
            }

            &:hover{
                &:before{
                    width: calc(50% + 2px);
                    box-shadow: 3px 16px 10px #565454;
                }
            }
    `}
`;

const Button = props => {
  const { className, ...rest } = props;
  return (
    <ButtonStyled
      className={className ? 'button ' + className : 'button '}
      {...rest}
    >
      {props.children}
    </ButtonStyled>
  );
};

export default Button;
