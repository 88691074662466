import { ServicesElems as S } from './constants';

export const DEMONTAZH = [
  {
    type: S.withImg,
    img: 'demontazh-1.jpeg'
  },
  {
    type: S.withLine,
    text:
      'Перед тем как приступить к начальному этапу ремонтных работ, необходимо подготовить место, на котором планируется возведение новых конструкций и проведение ремонтно-отделочных работ. Для этого проводят демонтажные работы: убирают старое покрытие, ненужные перегородки, неисправные трубы, старую электрическую проводку.'
  },
  {
    type: S.withList,
    title: 'В данный тип работ входят:',
    list: [
      'Демонтируются перегородки и стены',
      'Сбивается штукатурка',
      'Демонтируется дверной / оконный блок',
      'Демонтируются обои, плитка и т.д. (старые покрытия)',
      'Сбивается стяжка пола'
    ]
  },
  {
    type: S.withTitle,
    title: 'ДЕМОНТИРОВАНИЕ ПЕРЕГОРОДОК, СТЕН'
  },
  {
    type: S.withQuote,
    text:
      'Если при демонтаже стен или перегородок, в конструкцию строения будут внесены изменения, это может спровоцировать повреждение или даже полное разрушение здания. Поэтому, к вопросам, которые связаны с демонтажем стен или перепланировкой, необходимо подходить с максимальной ответственностью.'
  },
  {
    type: S.withList,
    title: 'Стены в помещении делятся на 2 типа:',
    list: [
      'Несущие стены– они являются частью опорной конструкции здания',
      'Не несущие перегородки -  это ограждающие перегородки, не принимающие на себя нагрузку здания.'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Планируете провести перепланировку и демонтаж некоторых стен? Наши квалифицированные специалисты помогут Вам в этом и произведут демонтаж стен в Минске и Минской области, используя современное оборудование и технологии.'
  },
  {
    type: S.withQuote,
    text:
      'Производить полное или частичное снесение несущей стены в помещении категорически запрещено, так как это может спровоцировать полное разрушение дома.'
  },
  {
    type: S.withImg,
    img: 'demontazh-2.jpg'
  },
  {
    type: S.withTitle,
    title: 'СБИВАНИЕ ШТУКАТУРКИ ',
    text:
      'С течением времени или по тем или иным причинам на любой отштукатуренной стене начинают появляться разного рода дефекты (повреждения, трещины и др.).Сбивка штукатурки – это демонтаж старого слоя штукатурки. Перед тем как начать отделку стен, всю поврежденную старую штукатурку необходимо снять, а трещины и любые другие повреждения нужно отремонтировать. '
  },
  {
    type: S.withImg,
    img: 'demontazh-3.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЕМОНТАЖ ДВЕРНОГО/ОКОННОГО БЛОКА',
    text:
      'Перед тем как начать демонтаж дверных блоков, специалистами проводится комплекс подготовительных работ: выбирается оптимальная технология демонтажа, а также необходимые для данной работы инструменты. При выборе технологии демонтажа дверного блока обращают внимание на: способ фиксации дверной коробки, материал, из которого возведены стены, необходимость сохранения дверной коробки и отделки.Если есть возможность демонтировать дверную коробку, не повреждая при этом стены, по желанию Клиента, специалисты могут сохранить ее целостность для повторного использования.Перед началом демонтажа оконного блока, нашими мастерами выбирается самый безопасный вариант проведения работ. При этом обращается внимание на степень износа постройки, на то, в каком состоянии находится оконный блок и на возможные повреждения стен. Оконные блоки демонтируются при помощи специальных инструментов, что позволяет предотвратить повреждение внутренней/внешней отделки, стен, а также строительных конструкций.'
  },
  {
    type: S.withImg,
    img: 'demontazh-4.jpg'
  },
  {
    type: S.withTitle,
    title: 'ДЕМОНТАЖ СТАРОГО ПОКРЫТИЯ'
  },
  {
    type: S.withList,
    title: 'При демонтаже старых покрытий снимается:',
    list: [
      'Побелка не стенах и потолке',
      'Керамическая плитка',
      'Напольные покрытия',
      'Обои',
      'Покраска стен и потолка',
      'и т.д.'
    ]
  },
  {
    type: S.withImg,
    img: 'demontazh-5.jpg'
  },
  {
    type: S.withTitle,
    title: 'СБИВКА СТЯЖКИ НА ПОЛУ',
    text:
      'В качестве основы, на которую укладывается напольное покрытие, выступает стяжка пола. Это такая прослойка, которая находится между самим покрытием и бетонным основанием. Стяжка пола необходима для того, чтобы выровнять его поверхность и защитить перекрытие от возможных негативных воздействий. Также стяжка выполняет важную функцию во время распределения нагрузки на конструкции.'
  },
  {
    type: S.withList,
    title: 'Демонтаж стяжки пола необходим если:',
    list: [
      'нужно произвести замену электропроводки или других коммуникаций, которые находятся под стяжкой',
      'есть определенные сложности в использовании напольного покрытия («бугры», неровности, неравномерная укладка и др.)',
      'уровень пола необходимо опустить'
    ]
  },
  {
    type: S.withImg,
    img: 'demontazh-6.jpg'
  },
  {
    type: S.withList,
    title:
      'Важно! Есть такие работы, которые по законодательству РБ нельзя проводить без согласования',
    list: [
      'Демонтаж несущих стен',
      'Демонтаж вентиляционных и канализационных систем, системы водоотведения, газо- и теплоснабжения, а также общедомовых трубопроводов',
      'Переоборудование лоджий и балконов в помещения с отоплением',
      'Работы, которые могут привести к снижению эксплуатационных характеристик жилого дома'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Снос не несущих стен, пробивание капитальных стен для оконных/дверных проемов, а также демонтаж локальных коммуникаций для их последующего переноса, возможны лишь после согласования проекта. Для этого соответствующие инстанции должны выдать необходимые разрешения.Без согласования можно произвести демонтаж сантехники, снять со стен штукатурку, обои, демонтировать межкомнатные двери, а также произвести демонтаж перегородок Минск, если это не нарушит проектные параметры дома.  '
  }
];
