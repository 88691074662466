import { ServicesElems as S } from './constants';
export const REMONT_SKLADA = [{
  type: S.withImg,
  img: 'remont-sklada.jpg'
},
{
  type: S.withLine,
  text:
    'Складские и промышленные помещения сильно подвержены разрушениям и износу, поскольку на них влияют: вибрации, пыль, механические и ударные нагрузки и т.п. И как следствие им нужен качественный ремонт.'
}, {
  type: S.withTitle,
  text: "Сделаем для Вас комплексный ремонт складских и промышленных помещений, который включает в себя: демонтаж старых покрытий, износившихся систем, перегородок; монтаж новых перегородок, стяжку пола, отделку стен, ремонт кровли, оборудование специальных зон для хранения товаров, покрытие металлических конструкций антикоррозийным составом, монтаж электропроводки, внутренние и наружные малярно-штукатурные работы."
}]