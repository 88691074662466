import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toggleModal, toggleModalAcceptanceOfApartments } from '../actions';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Services from '../containers/Services';
import Banner from '../containers/Banner';
import Dropdowns from '../containers/Dropdowns';
import Promotions from '../containers/Promotions';
import Projects from '../containers/Projects';
import Estimate from '../containers/Estimate';
import InfoBlock from '../containers/InfoBlock';
import PageTitle from '../components/PageTitle';
import {
  InfoBlockItemCost,
  InfoBlockItemAboutCompany,
  InfoBlockItemIdeas
} from '../components/InfoBlockItem';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';
import Button from '../components/Button';

class ServicesPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TitleAndDesc.servicesPage.title}</title>
          <meta name="description" content={TitleAndDesc.servicesPage.desc} />
        </Helmet>
        <Header />
        <PageTitle
          title="УСЛУГИ"
          paragraf="Выбирайте подходящий Вам вид ремонта"
        />
        <Services backgroundColor="#e2e3eb" servicesPage />
        <Banner>
          <div>
            <h3 className="text-white">
              <span className="f-w-300">Поможем Вам</span> принять квартиру
              <br />
              <span className="f-w-300">у застройщика (бесплатно)</span>
            </h3>
          </div>
          <Button
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModalAcceptanceOfApartments(true)}
          >
            заказать приемку квартиры
          </Button>
        </Banner>
        <Dropdowns />
        <Promotions />
        <Projects withTitle projectsCount={9} />
        <Estimate backgroundColor="#E2E3EB" />
        <InfoBlock>
          <InfoBlockItemCost blueHeader />
          <InfoBlockItemAboutCompany blueHeader />
          <InfoBlockItemIdeas blueHeader />
        </InfoBlock>
        <Banner>
          <div>
            <h3 className="text-white">
              Готовы начать <span className="f-w-300">свой первый</span> lovko
              ремонт <br />
              <span className="f-w-400">и получить </span>
              скидку 5%?
            </h3>
          </div>
          <Button
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModal(true)}
          >
            НАЧАТЬ РЕМОНТ
          </Button>
        </Banner>
        <Footer />
      </Fragment>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  },
  toggleModalAcceptanceOfApartments: boolean => {
    dispatch(toggleModalAcceptanceOfApartments(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(ServicesPage);
