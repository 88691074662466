import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Routes from './Routes';
import ModalStepper from './containers/ModalStepper';
import ModalAcceptanceOfApartments from './containers/ModalAcceptanceOfApartments';
import ModalContantWithUs from './containers/ModalContantWithUs';
import lovko from './reducer';
import { animateScroll as scroll } from 'react-scroll';

const store = createStore(
  lovko,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

class App extends Component {
  state = {
    showAnchor: false
  };

  componentDidMount() {
    window.addEventListener('scroll', e => {
      const { showAnchor } = this.state;
      if (window.pageYOffset > 150 && !showAnchor) {
        this.setState({ showAnchor: true });
      } else if (window.pageYOffset < 150 && showAnchor) {
        this.setState({ showAnchor: false });
      }
    });
  }
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    const { showAnchor } = this.state;
    return (
      <Provider store={store}>
        <ModalStepper />
        <ModalAcceptanceOfApartments />
        <ModalContantWithUs />
        <Routes />
        <div
          onClick={this.scrollToTop}
          className={
            (showAnchor ? 'show-scroll-to-top' : '') + ' to-top-scroll '
          }
        />
      </Provider>
    );
  }
}

export default App;
