import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toggleModalContactWithUs, toggleModal } from '../actions';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Prices from '../containers/Prices';
import Estimate from '../containers/Estimate';
import Promotions from '../containers/Promotions';
import Banner from '../containers/Banner';
import Button from '../components/Button';
import Dropdowns from '../containers/Dropdowns';
import InfoBlock from '../containers/InfoBlock';
import {
  InfoBlockItemAboutCompany,
  InfoBlockItemProjects,
  InfoBlockItemAboutServices
} from '../components/InfoBlockItem';
import { TitleAndDesc } from '../lovkoContent/SEO/TitleDesc';

class PricesPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TitleAndDesc.pricesPage.title}</title>
          <meta name="description" content={TitleAndDesc.pricesPage.desc} />
        </Helmet>
        <Header />
        <Prices />
        <Banner>
          <div className="text-white">
            <p className="text-size-24">
              <span className="f-w-300">Хотите</span> ПОЛУЧИТЬ ДЕТАЛЬНУЮ СМЕТУ
              <span className="f-w-300"> Вашего ремонта</span> ?
            </p>
            <p className="text-size-18">Давайте поступим следующим образом:</p>
            <div className="banner-number-wrapper">
              <div className="banner-number-item">
                <div className="banner-number">1</div>
                <p>
                  <span className="text-nowrap">вы высылаете нам </span>
                  дизайн-проект
                </p>
              </div>
              <div className="banner-number-item">
                <div className="banner-number">2</div>
                <p>
                  или/и встречаемся у вас на объекте и вы рассказываете нам, что
                  хотите получить
                </p>
              </div>
            </div>
            <p className="text-size-24 mb-0">
              <span className="f-w-300">На основании этого мы</span> РАССЧИТАЕМ
              <br />
              ДЕТАЛЬНУЮ СМЕТУ{' '}
              <span className="f-w-300">Вашего ремонта (бесплатно)</span>
            </p>
          </div>
          <Button
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModalContactWithUs(true)}
          >
            ПОЛУЧИТЬ СМЕТУ
          </Button>
        </Banner>
        <Promotions />
        <Estimate />
        <Dropdowns backgroundColor="#e2e3eb" />
        <InfoBlock>
          <InfoBlockItemProjects />
          <InfoBlockItemAboutCompany />
          <InfoBlockItemAboutServices />
        </InfoBlock>
        <Banner>
          <div>
            <h3 className="text-white">
              Готовы начать <span className="f-w-300">свой первый</span> lovko
              ремонт <br />
              <span className="f-w-400">и получить </span>
              скидку 5%?
            </h3>
          </div>
          <Button
            boxshadow="full"
            size="large"
            onClick={() => this.props.toggleModal(true)}
          >
            НАЧАТЬ РЕМОНТ
          </Button>
        </Banner>
        <Footer />
      </Fragment>
    );
  }
}

const mapDispachToProps = dispatch => ({
  toggleModalContactWithUs: boolean => {
    dispatch(toggleModalContactWithUs(boolean));
  },
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(PricesPage);
