export const TABS_NAV_ITEMS = [
  { id: 'all', text: 'Все' },
  { id: 'cosmetic', text: 'Косметический' },
  { id: 'major', text: 'Капитальный' },
  { id: 'designer', text: 'Дизайнерский' }
];

export const COMPLETED_PROJECTS = [
  {
    tab: 'cosmetic',
    id: 1,
    title: 'ул. Лапатина',
    smallImg: 'kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
    info: {
      mainTitle: 'Квартира на ул. Лапатина',
      type: 'трехкомнатная квартира',
      area: 78,
      period: '2 месяца',
      workPerformed: {
        elems: [
          'сантехнические и электромонтажные работы',
          'штукатурка стен по плоскости',
          'выравнивание линии плинтусов и наличников',
          'шпатлевка стен под обои',
          'поклейка обоев',
          'шпатлевка и покраска потолка',
          'укладка плитки',
          'укладка ламината',
          'монтаж плинтусов'
        ]
      }
    },
    images: [
      'projects/1/1_kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
      'projects/1/2_kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
      'projects/1/3_kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
      'projects/1/4_kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
      'projects/1/5_kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
      'projects/1/6_kosmeticheskii-remont-kvartiry-Minsk_Lapatina.jpg',
    ]
  },
  {
    tab: 'cosmetic',
    id: 2,
    title: 'ул. Слободская',
    smallImg: 'kosmeticheskii-remont-kvartiry-v-Minske_Slabodskaya.jpg',
    info: {
      mainTitle: 'Квартира по ул. Слободская',
      type: 'трехкомнатная квартира',
      area: 87,
      period: '3 месяца',
      workPerformed: {
        elems: [
          'частично сантехнические и электромонтажные работы',
          'штукатурка стен по плоскости',
          'шпатлевка стен и потолков под покраску',
          'чистовая отделка (укладка плитки, покраска стен и потолка)',
        ]
      }
    },
    images: [
      'projects/2/1_kosmeticheskii-remont-kvartiry-v-Minske_Slabodskaya.jpg',
      'projects/2/2_kosmeticheskii-remont-kvartiry-v-Minske_Slabodskaya.jpg',
      'projects/2/3_kosmeticheskii-remont-kvartiry-v-Minske_Slabodskaya.jpg',
      'projects/2/4_kosmeticheskii-remont-kvartiry-v-Minske_Slabodskaya.jpg',
    ]
  },
  {
    tab: 'cosmetic',
    id: 3,
    title: 'ул. Мирошниченко',
    smallImg: 'stoimost-kosmeticheskogo-reomonta-kvartiry-v-Minske-Miroshnichenko.jpg',
    estImg: 'smeta-kosmiticheskii-remont-Minsk-Miroshnichenko.jpg',
    info: {
      mainTitle: 'Квартира на ул. Мирошниченко',
      type: 'однокомнатная квартира',
      area: 42,
      period: '2 месяца',
      workPerformed: {
        elems: [
          'частично сантехнические и электромонтажные работы',
          'штукатурка стен по плоскости',
          'шпатлевка стен и потолков',
          'чистовая отделка (укладка плитки, покраска потолка, поклейка обоев, монтаж плинтусов)'
        ]
      }
    },
    images: [
      'projects/3/1_kosmeticheskii-reomont-kvartiry-v-Minske_Miroshnichenko.jpg',
      'projects/3/2_kosmeticheskii-reomont-kvartiry-v-Minske_Miroshnichenko.jpg',
      'projects/3/3_kosmeticheskii-reomont-kvartiry-v-Minske_Miroshnichenko.jpg',
      'projects/3/4_kosmeticheskii-reomont-kvartiry-v-Minske_Miroshnichenko.jpg',
      'projects/3/5_kosmeticheskii-reomont-kvartiry-v-Minske_Miroshnichenko.jpg',
      'projects/3/6_kosmeticheskii-reomont-kvartiry-v-Minske_Miroshnichenko.jpg',
    ]
  },
  {
    tab: 'cosmetic',
    id: 4,
    title: 'ул. Васнецова',
    smallImg: 'kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
    info: {
      mainTitle: 'Квартира на ул. Васнецова',
      type: 'трехкомнатная квартира',
      area: 87,
      period: '2 месяца',
      workPerformed: {
        elems: [
          'демонтаж старых покрытий',
          'ремонт сантехнического оборудования',
          'частично электромонтажные работы',
          'черновая отделка (штукатурка стен по плоскости, шпатлевка потолка под покраску, шпатлевка стен под обои)',
          'чистовая отделка (укладка плитки, укладка ламината, покраска потолка и стен, поклейка обоев, монтаж плинтусов)'
        ]
      }
    },
    images: [
      'projects/4/1_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/2_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/3_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/4_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/5_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/6_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/7_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/8_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
      'projects/4/9_kosmeticheskii-remont-rvartiry-v-Minske_Vasnetsova.jpg',
    ]
  },
  {
    tab: 'major',
    id: 5,
    title: 'ул. Скрыганова',
    smallImg: 'kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
    info: {
      mainTitle: 'Квартира на ул. Скрыганова',
      type: 'трехкомнатная квартира',
      area: 101,
      period: '6 месяцев',
      workPerformed: {
        elems: [
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, установка ванны, монтаж бойлера, замена труб, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен, устройство декоративных элементов из гипсокартона, устройство потолков из гипсокартона, шпатлевка потолков под покраску, шпатлевка стен под декоративную штукатурку, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки, запил углов плитки под 45 градусов, покраска потолка, укладка паркета с декоративным рисунком, оклейка обоями, нанесение декоративной штукатурки на стены (Ottocento, Travertino), монтаж плинтусов)'
        ]
      },
      features: {
        title: 'В зоне гостиной был выполнен подиум, возвышенность разделила зону ТВ и отдыха от кухни, коридора, в торце подиума была вмонтирована точечная подсветка. Стены покрывались 2 видами декоративной штукатурки (Ottocento, Travertino). В зоне ТВ была сделана перегородка с подсветкой и декоративной штукатуркой Travertino.',
        elems: []
      }
    },
    images: [
      'projects/5/1_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/2_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/3_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/4_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/5_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/6_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/7_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/8_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/9_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/10_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/11_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/12_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/13_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/14_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
      'projects/5/15_kapitalnyi-remont-kvartir-v-Minske_Skryganova.jpg',
    ]
  },
  {
    tab: 'major',
    id: 6,
    title: 'ул. Камайская',
    smallImg: 'kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
    info: {
      mainTitle: 'Квартира на ул. Камайская',
      type: 'трехкомнатная квартира',
      area: 78,
      period: '6 месяцев',
      workPerformed: {
        elems: [
          'демонтаж (демонтаж перегородок и старых покрытий)',
          'монтаж (перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, замена труб, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки, установка ванны)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)','' +
          'черновая отделка (штукатурка потолков и стен, устройство потолков из гипсокартона, шпатлевка потолков под покраску, шпатлевка стен под обои и декоративную штукатурку, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки (стены, пол), покраска потолка, нанесение декоративной штукатурки на стены DI PERLA, укладка ламината, оклейка обоями, монтаж плинтусов)'
        ]
      }
    },
    images: [
      'projects/6/1_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/2_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/3_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/4_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/5_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/6_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/7_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/8_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/9_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/10_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/11_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/12_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/13_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
      'projects/6/14_kapitalnyi-remont-rvartiry-v-Minske-Kamaiskaya.jpg',
    ]
  },
  {
    tab: 'major',
    id: 7,
    title: 'ул. Леонида Беды',
    smallImg: 'kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
    info: {
      mainTitle: 'Квартира на ул. Леонида Беды',
      type: 'трехкомнатная квартира',
      area: 63,
      period: '4 месяца',
      workPerformed: {
       elems: [
           'демонтаж (демонтаж старых покрытий)',
           'сантехнические работы (замена труб, установка вентилей и смесителей)',
           'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
           'черновая отделка (штукатурка потолков и стен, устройство потолков из гипсокартона, шпатлевка потолков под покраску, шпатлевка стен под обои и декоративную штукатурку, стяжка пола, устройство декоративного элемента на стены и потолок)',
           'чистовая отделка (укладка плитки (стены, пол), покраска потолка, нанесение декоративной штукатурки на стены и потолок, укладка ламината, оклейка обоями, укладка декоративного камня, нанесение декоративной штукатурки DI PERLA, монтаж плинтусов)'
       ]
      },
      features: {
        title: 'Эта квартира была не новостройка, поэтому было проделано много работы по демонтажу всех старых покрытий, полностью переделывалась электропроводка. В отделке зоны ТВ был применен интересный гипсовый камень. Он как будто кружевной, вживую смотрится очень эффектно.',
        elems: []
      }
    },
    images: [
      'projects/7/1_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/2_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/3_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/4_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/5_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/6_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/7_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/8_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/9_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/10_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/11_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/12_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/13_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/14_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/15_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
      'projects/7/16_kapitalnyi-remont-kvartiry-v-Minske-Leonida-Bedy.jpg',
    ]
  },
  {
    tab: 'major',
    id: 8,
    title: 'пр. Дзержинского',
    smallImg: 'kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
    info: {
      mainTitle: 'Квартира на пр. Дзержинского',
      type: 'двухкомнатная квартира',
      area: 68,
      period: '4 месяца',
      workPerformed: {
        title: null,
        elems: [
          'демонтаж (демонтаж перегородок и старых покрытий)',
          'монтаж (перегородок и дверных проемов)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновая отделка (штукатурка потолков и стен, устройство потолков из гипсокартона, шпатлевка потолков под покраску, шпатлевка стен под обои и покраску, стяжка пола)',
          'устройство декоративной ниши, установка скрытого люка)',
          'чистовая отделка (укладка плитки (стены, пол), покраска потолка и стен, укладка ламината, оклейка обоями, монтаж плинтусов)'
        ]
      },
      features: {
        title: 'На этом объекте заказчица сама была дизайнером своей квартиры.',
        elems: []
      }
    },
    images: [
      'projects/8/1_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/2_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/3_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/4_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/5_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/6_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/7_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/8_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/9_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/10_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
      'projects/8/11_kapitalnyi-remont-kvartiry-v-Minske_Dzerzhinskogo.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 9,
    title: 'ул. Жасминовая',
    smallImg: 'dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
    info: {
      mainTitle: 'Квартира на ул. Жасминовой',
      type: 'трехкомнатная квартира',
      area: 160,
      period: '7 месяцев',
      workPerformed: {
        title: '',
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, установка бойлера, монтаж трапа, установка вентилей и смесителей, установка ванны, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, зашивка стен гипсокартонном, устройство стеклянной полоски вверху стены, изготовление гипсокартонных потолков по дизайн-проекту, шпатлевка потолков под покраску, гидроизоляция, установка скрытого люка, стяжка пола)',
          'чистовая отделка (укладка плитки с запилом углов под 45 градусов, покраска стен и потолка, поклейка обоев, укладка паркетной доски, монтаж плинтусов)'
        ]
      },
      features: {
        title: 'На этом объекте делали интересный элемент на стенах – прозрачная полоска возле потолка. Полоска вверху потолка (прозрачная либо покрашенная) была выполнена во всех комнатах. Дизайн-проект этого объекта разрабатывал дизайнер из Литвы.',
        elems: []
      }
    },
    images: [
      'projects/9/1_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/2_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/3_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/4_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/5_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/6_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/7_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/8_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/9_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/10_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/11_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/12_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/13_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/14_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/15_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
      'projects/9/16_dizainerskii-remont-kvartiry-v-Minske-Zhasminovaya.jpg',
    ]
  },
  {
    tab: 'major',
    id: 10,
    title: 'ул. Скрыганова',
    smallImg: 'kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
    info: {
      mainTitle: 'Квартира на ул. Скрыганова',
      type: 'двухкомнатная квартира',
      area: 76,
      period: '5 месяцев',
      workPerformed: {
        title: '',
        elems: [
          'демонтаж перегородок',
          'монтаж новых перегородок и дверных проемов',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, установка ванны, монтаж бойлера, замена труб, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен, устройство потолков из гипсокартона, шпатлевка потолков и стен под покраску, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки, покраска потолка и стен, укладка ламината, поклейка обоев, укладка декоративного камня, монтаж плинтусов)'
        ]
      }
    },
    images: [
      'projects/10/1_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/2_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/3_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/4_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/5_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/6_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/7_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/8_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/9_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/10_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/11_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
      'projects/10/12_kapitalnyi-remont-kvartiry-v-Minske_Skryganova.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 11,
    title: 'пр. Независимости',
    smallImg: 'dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
    info: {
      mainTitle: 'Квартира по пр. Независимости',
      type: 'трехкомнатная квартира',
      area: 118,
      period: '5 месяцев',
      workPerformed: {
          elems: [
              'демонтаж перегородок',
              'монтаж (перегородок и дверных проемов)',
              'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб, установка бойлера, монтаж трапа, установка вентилей и смесителей, установка ванны, подключение стиральной машины, подключение посудомойки)',
              'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
              'черновые работы (штукатурка стен по маякам, устройство гипсокартонного потолка с декоративными элементами, шпатлевка потолков под покраску, гидроизоляция, стяжка пола, установка скрытого люка)',
              'чистовая отделка (укладка плитки с запилом углов под 45 градусов, покраска стен и потолка, укладка декоративного камня, поклейка обоев, укладка паркетной доски, монтаж плинтусов)'
          ]
      }
    },
    images: [
      'projects/11/1_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/2_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/3_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/4_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/5_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/6_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/7_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/8_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/9_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/10_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/11_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/12_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/13_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/14_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/15_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/16_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/17_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/18_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/19_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
      'projects/11/20_dizainerskii-remont-kvartiry-v-Minske-Nezavisimosti.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 12,
    title: 'ул. Туровского',
    smallImg: 'stoimost-dizainerskogo-remonta-v-Minske-Turovskogo.jpg',
    estImg: 'smeta-dizainerskii-remont-Minsk-Turovskogo.jpg',
    info: {
      mainTitle: 'Квартира на ул. Туровского',
      type: 'двухкомнатная квартира',
      area: 66,
      period: '4 месяца',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, установка бойлера, монтаж трапа, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, устройство гипсокартонного потолка с декоративными элементами и деревянными балками, гидроизоляция, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки с запилом углов под 45 градусов, нанесение декоративной штукатурки на стены и потолок, укладка декоративного камня и его стилизация «под старину», укладка паркетной доски, декорирование стены досками)'
        ],
      },
      features: {
        title: 'На потолок и стены была нанесена штукатурка "под бетон". В районе кухни стена покрашена черной, грифельной краской, на которой можно писать мелом. Потолок комбинированный: гипсокартонный с покраской в белый цвет + штукатурка "под бетон" + деревянные балки. Согласно дизайн-проекта кирпичная стена должна была быть из "состаренного" кирпича, поэтому его вручную (но с помощью инструментов, конечно) "состаривали". Деревянная стена из досок разного размера и окрашенных в разные цвета. В санузле одна на одну стену уложен кирпич, скрытый люк делали в кирпичной кладке. А внутри душевой кабины плитка уложена интересным "ступенчатым" способом.',
        elems: []
      }
    },
    images: [
      'projects/12/1_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/2_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/3_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/4_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/5_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/6_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/7_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/8_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/9_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/10_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/11_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/12_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/13_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/14_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/15_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/16_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/17_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/18_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/19_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
      'projects/12/20_dizainerskii-remont-kvartiry-v-Minske_Turovskogo.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 13,
    title: 'пр. Победителей',
    smallImg: 'dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
    info: {
      mainTitle: 'Квартира на пр. Победителей',
      type: 'однокомнатная квартира',
      area: 43,
      period: '5 месяцев',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, установка бойлера, монтаж трапа, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, зашивка стен гипсокартоном согласно дизайн-проекта с установкой скрытой подсветки, устройство гипсокартонного потолка с декоративными элементами и скрытой подсветкой, шпатлевка потолков, гидроизоляция, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки разных размеров, покраска стен и потолка, укладка паркетной доски, монтаж плинтусов)'
        ],
      },
      features: {
        title: 'Главной особенностью этого проекта, конечно, является потолок. Он как бы разрезан на черно-белые треугольники со скрытой подсветкой. В некоторых местах эти треугольники спускаются на стену. На кухне в рабочей зоне и на полу была уложена 3D-плитка, которая создает объем. В гостиной в зоне ТВ на стене плитка большого формата (2600*1000 мм)',
        elems: []
      }
    },
    images: [
      'projects/13/1_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/2_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/3_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/4_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/5_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/6_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/7_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/8_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/9_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/10_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/11_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/12_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/13_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/14_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/15_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/16_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/17_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/18_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/19_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/20_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/21_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/22_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/23_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/13/24_dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
    ]
  },
  {
    tab: 'major',
    id: 14,
    title: 'пр. Дзержинского',
    smallImg: 'stoimost-kapitalnogo-remonta-v-Minske-Dzerzhinskogo.jpg',
    estImg: 'smeta-kapitalnyi-remont-Minsk-Dzerzhinskogo.jpg',
    info: {
      mainTitle: 'Квартира на пр. Дзержинского',
      type: 'трехкомнатная квартира',
      area: 67,
      period: '4 месяца',
      workPerformed: {
        title: '',
        elems: [
          'демонтаж перегородок',
          'монтаж (новых перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, монтаж бойлера, замена труб, установка ванны, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен, штукатурка потолков, устройство гипсокартонных потолков, шпатлевка потолков и стен под покраску, стяжка пола)',
          'чистовая отделка (укладка плитки, запил углов плитки под 45 градусов, покраска потолка и стен, укладка ламината, монтаж плинтусов)'
        ]
      }
    },
    features: {
      title: 'На кухне рабочая зона и мойка перенесены к окну. В коридоре (в районе входной двери) делали укладку черной плитки на стену, в сочетании с белой плиткой пола смотрится очень эффектно',
      elems: []
    },
    images: [
      'projects/14/1_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/2_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/3_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/4_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/5_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/6_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/7_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/8_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/9_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/10_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/11_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/12_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/13_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/14_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/15_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/16_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/17_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/18_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/19_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/20_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/21_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
      'projects/14/22_kapitalnyi-remont-kvartir-v-Minske_Dzerzhinskogo.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 15,
    title: 'ул. Сурганова',
    smallImg: 'dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
    info: {
      mainTitle: 'Квартира на ул. Сурганова',
      type: 'двухкомнатная квартира',
      area: 81,
      period: '5 месяцев',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, установка бойлера, монтаж трапа, установка вентилей и смесителей, установка ванны, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, устройство гипсокартонного потолка с декоративными элементами и скрытой подсветкой, шпатлевка потолков, гидроизоляция, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки, укладка декоративного кирпича, покраска стен и потолка, поклейка фотообоев, укладка паркетной доски, монтаж плинтусов)'
        ],
      },
      features: {
        title: 'Дизайнером этого проекта была сама хозяйка квартиры. В зоне ТВ поклеены фотообои, которые привлекают к себе внимание и оживляют комнату. Возле зоны ТВ расположена зеркальная дверь',
        elems: []
      }
    },
    images: [
      'projects/15/1_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/2_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/3_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/4_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/5_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/6_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/7_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/8_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/9_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/10_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/11_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/12_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/13_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/14_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/15_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/16_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/17_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
      'projects/15/18_dizainerskii-remont-kvartiry-v-Minske_Surganova.jpg',
    ]
  },
  {
    tab: 'major',
    id: 16,
    title: 'ул. Чюрлениса',
    smallImg: 'kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
    info: {
      mainTitle: 'Квартира на ул. Чюрлениса',
      type: 'двухкомнатная квартира',
      area: 74,
      period: '4 месяца',
      workPerformed: {
        title: '',
        elems: [
          'демонтаж перегородок',
          'монтаж (новых перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, монтаж бойлера, замена труб, монтаж трапа, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен, шпатлевка стен под покраску и обои, стяжка пола)',
          'чистовая отделка (укладка плитки, укладка декоративного камня, покраска стен, поклейка обоев, укладка ламината, монтаж плинтусов)'
        ]
      }
    },
    features: {
      title: 'На кухне есть «остров», на который выведены розетки и выключатели',
      elems: []
    },
    images: [
      'projects/16/1_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/2_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/3_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/4_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/5_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/6_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/7_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/8_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/9_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/10_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
      'projects/16/11_kapitalnyi-remont-kvartir-v-Minske-Chyrlenisa.jpg',
    ]
  },
  {
    tab: 'major',
    id: 17,
    title: 'ул. Братская',
    smallImg: 'kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
    info: {
      mainTitle: 'Квартира на ул. Братской',
      type: 'однокомнатная квартира',
      area: 31,
      period: '3 месяца',
      workPerformed: {
        title: '',
        elems: [
          'демонтаж перегородок',
          'монтаж (новых перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, монтаж бойлера, замена труб, установка ванны, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен, устройство потолков из гипсокартона, шпатлевка потолков и стен под покраску, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки, запил углов плитки под 45 градусов, укладка декоративного камня, покраска потолка и стен, укладка ламината, монтаж плинтусов)'
        ]
      }
    },
    features: {
      title: 'Маленькая уютная квартира, в которую вместилась даже гардеробная. Дизайнер проекта отлично подобрал материалы, и она получилась очень гармоничной',
      elems: []
    },
    images: [
      'projects/17/1_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/2_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/3_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/4_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/5_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/6_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/7_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/8_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/9_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/10_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/11_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/12_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/13_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/14_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/15_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/16_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/17_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
      'projects/17/18_kapitalnyi-remont-kvartiry-v-Minske_Bratskaya.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 18,
    title: 'пр. Победителей',
    smallImg: 'dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
    info: {
      mainTitle: 'Квартира на пр. Победителей',
      type: 'трехкомнатная квартира',
      area: 107,
      period: '6 месяцев',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов для нестандартных дверей)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, установка ванны, установка бойлера, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, устройство комбинированного потолка (гипсокартонный, декоративная штукатурка «под бетон», деревянные балки),  шпатлевка потолков, гидроизоляция, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки разных размеров, покраска стен и потолка, укладка декоративного камня, укладка паркетной доски, монтаж плинтусов)'
        ],
      },
      features: {
        title: 'В этой квартире был выполнен интересный потолок – сочетание белого гипсокартонного, деревянных балок и декоративной штукатурки "под бетон". Также привлекает внимание такие дизайнерские элементы как ретро-выключатели, черные радиаторы отопления. Устанавливались двери нестандартных размеров, без наличников. Варочная панель на кухне установлена на "острове"',
        elems: []
      }
    },
    images: [
      'projects/18/1_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/2_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/3_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/4_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/5_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/6_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/7_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/8_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/9_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/10_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/11_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/12_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/13_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/14_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/15_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/16_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/17_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/18_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/19_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/20_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/21_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/22_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/23_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/24_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/25_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/26_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
      'projects/18/27_dizainerskiy-remont-kvartiry-v-Minske-Pobediteley.jpg',
    ]
  },
  {
    tab: 'major',
    id: 19,
    title: 'ул. Ратомская',
    smallImg: 'kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
    info: {
      mainTitle: 'Квартира на ул. Ратомской',
      type: 'двухкомнатная квартира',
      area: 48,
      period: '',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (новых перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, монтаж бойлера, замена труб, установка ванны, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, оптоволоконного кабеля, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен, устройство потолков из гипсокартона и натяжных, шпатлевка потолков и стен под покраску, стяжка пола, установка скрытого люка, утепление балкона)',
          'чистовая отделка (укладка плитки, покраска потолка и стен, укладка ламината, монтаж плинтусов)'
        ],
      },
      features: {
        title: 'В спальне в прикроватной зоне сделали полосы графитового цвета с полукругом в основании. В гостиной был сделан дизайнерский элемент в виде полосы зеленого цвета в тон кухни и круглого светильника. Зона ТВ (в гостиной) оформлена полосой графитового цвета, которая переходит со стены на потолок. Динамичная плитка на полу создаёт настроение.',
        elems: []
      }
    },
    images: [
      'projects/19/1_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/2_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/3_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/4_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/5_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/6_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/8_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/9_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/10_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/11_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/12_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/13_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/14_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/15_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/16_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/17_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/18_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/19_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/20_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/21_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/22_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/23_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
      'projects/19/24_kapitalnyi-remont-kvartiry-v-Minske-Ratomskaya.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 20,
    title: 'ул. Ильянская',
    smallImg: 'dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
    estImg: 'smeta-dizainerskogo-remonta-kvartiry-v-Minske-Iliyanskaya.jpg',
    info: {
      mainTitle: 'Квартира на ул. Ильянской',
      type: 'трехкомнатная квартира',
      area: 92,
      period: '6 месяцев',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, устройство змеевика, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, монтаж трапа, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, оптоволоконного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, устройство гипсокартонного потолка,  шпатлевка потолков, гидроизоляция, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки разных размеров, покраска стен и потолка, нанесение декоративной штукатурки, укладка паркетной доски, поклейка фотообоев, монтаж плинтусов (пол, стены, потолок))'
        ],
      },
      features: {
        title: 'Основной отличительной особенностью этой квартиры являются плинтуса - они располагаются на потолке, стенах, полу. В гостиной в зоне ТВ и соседней стене выполнены полосы с декоративной штукатуркой. Большие зеркала в коридоре выгодно подчеркивают дизайн гостиной и расширяют пространство. В спальне стена в изголовье кровати декорирована мягкими панелями, в зоне ТВ – фотообои.',
        elems: []
      }
    },
    images: [
      'projects/20/1_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/2_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/3_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/4_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/5_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/6_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/7_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/8_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/9_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/10_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/11_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/12_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/13_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/14_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/15_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/16_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/17_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/18_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/19_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/20_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/21_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/22_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/23_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
      'projects/20/24_dizainerskiy-remont-kvartiry-v-Minske-Iliyanskaya.jpg',
    ]
  },
  {
    tab: 'designer',
    id: 21,
    title: 'пр. Победителей',
    smallImg: 'dizainersrii-remont-kvartiry-v-Minske-Pobeditelei.jpg',
    info: {
      mainTitle: 'Квартира на пр. Победителей',
      type: 'трехкомнатная квартира',
      area: 102,
      period: '6 месяцев',
      workPerformed: {
        elems: [
          'демонтаж перегородок',
          'монтаж (перегородок и дверных проемов, в том числе для нестандартных дверей)',
          'сантехнические работы (монтаж водопровода, монтаж канализации, установка инсталляции и унитаза, перенос счетчиков, замена труб и радиаторов отопления, монтаж трапа, установка бойлера, установка вентилей и смесителей, подключение стиральной машины, подключение посудомойки)',
          'электромонтажные работы (электромонтаж разветвительных коробок, монтаж электрощитка с установкой и подключением, прокладывание силового, UTP, антенного и телефонного кабеля, монтаж слаботочного щитка, монтаж теплого пола, установка розеток и выключателей, установка светильников)',
          'черновые работы (штукатурка стен по маякам, устройство гипсокартонного потолка,  шпатлевка потолков, гидроизоляция, стяжка пола, установка скрытого люка)',
          'чистовая отделка (укладка плитки разных размеров, покраска стен и потолка, поклейка обоев, укладка паркетной доски, монтаж плинтусов)'
        ],
      },
      features: {
        title: 'Получилась очень интересная квартира. Из особенностей: в гостиной плитка 2600*1200 (имитирующая камень), графитовая стена с деревянными балками, стена с эксклюзивными обоями и скрытой дверью, потолочный ступенчатый плинтус. В спальне смотрится эффектно декоративный элемент из деревянных реек с подсветкой (в районе изголовья кровати, переходящий на потолок). А еще в спальне располагается большая гардеробная, которая отделяется от спальни перегородкой с ТВ, в проемах чуть позже будут располагаться шторы. В гостевой комнате в зоне ТВ располагается широкая дубовая доска (90 см) на черно-белой стене. В санузле на стенах и «скрытой» двери нанесена декоративная штукатурка.',
        elems: []
      }
    },
    images: [
      'projects/21/1_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/2_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/3_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/4_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/5_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/6_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/7_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/8_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/9_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/10_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/11_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/12_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/13_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/14_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/15_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/16_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/17_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/18_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/19_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/20_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/21_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/22_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/23_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/24_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/25_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/26_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/27_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/28_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/29_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/30_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/31_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/32_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/33_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/34_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
      'projects/21/35_dizainerskiy-remont-kvartiry-v-Minske-Pobeditelei.jpg',
    ]
  },
];
