import { IDEA_1 } from './IdeasElements/idea_1';
import { IDEA_2 } from './IdeasElements/idea_2';
import { IDEA_3 } from './IdeasElements/idea_3';
import { IDEA_4 } from './IdeasElements/idea_4';
import { IDEA_5 } from './IdeasElements/idea_5';
import { IDEA_6 } from './IdeasElements/idea_6';
import { IDEA_7 } from './IdeasElements/idea_7';
import { IDEA_8 } from './IdeasElements/idea_8';
import { IDEA_9 } from './IdeasElements/idea_9';

export const TABS_NAV_ITEMS = [
  { id: 'all', text: 'Все' },
  { id: 'repairs', text: 'Ремонт' },
  { id: 'design', text: 'Дизайн' },
  { id: 'useful', text: 'Полезно' }
];

export const MAIN_PAGE_IDEAS_CONTENT = [
  {
    id: 1,
    type: 'design',
    title: 'Античный стиль',
    text:
      'Культура и искусство Древнего Рима и Эллады отражаются в античном стиле: арки, колонны, фрески, роспись стен, антикварная мебель, скульптуры и гобелены.',
    image: '1_antichnyi-stil-min.jpg',
    data: IDEA_1
  },
  {
    id: 2,
    type: 'design',
    title: 'Романский стиль',
    text:
      'Романский стиль: приют для рыцаря. Этот стиль своими корнями уходит за толстые стены замков во времена средневековья.',
    image: '1-romanskii-stil-min.jpg',
    data: IDEA_2
  },
  {
    id: 3,
    type: 'design',
    title: 'Барокко',
    text:
      'Интерьеры в стиле барокко выглядят по-королевски, пышно, праздно. Характерна симметричность, аристократичность, текстиль с изысканными узорами, антикварная мебель.',
    image: '1_stil-barokko-min.jpg',
    data: IDEA_3
  },
  {
    id: 4,
    type: 'design',
    title: 'Рококо',
    text:
      'Роккоко - утончённый, чувственный, романтичный, королевский. Ему присуща молодость, женственность, иллюзии. Он отражает собой мир веселья, балов, будуаров, флирта.',
    image: '1-stil-rokoko-min.jpg',
    data: IDEA_4
  },
  {
    id: 5,
    type: 'design',
    title: 'Ампир',
    text:
        'Ампир: императорский масштаб. Для этого стиля характерна роскошь, симметрия и строгие формы, интеллигентная утонченность, многообразие декоративных элементов, применение позолоты и резьбы.',
    image: '1-stil-ampir-min.jpg',
    data: IDEA_5
  },
  {
    id: 6,
    type: 'design',
    title: 'Классицизм',
    text:
        'Классицизм: дух резиденций. Этот стиль подчеркивает хороший вкус и высокий статус владельца. Для него характерна симметрия и правильные формы, лаконичность, утонченный шарм.',
    image: '1-stil-klassicizm-min.jpg',
    data: IDEA_6
  },
  {
    id: 7,
    type: 'design',
    title: 'Прованс',
    text:
        'Прованс: берег мечты. Это гармоничный стиль, он сочетает в себе простоту деревни и галантное изящество, романтизм и практичность с хозяйственной смекалкой.',
    image: '1-stil-provans-min.jpg',
    data: IDEA_7
  },
  {
    id: 8,
    type: 'repairs',
    title: 'Перепланировка: возможности и ограничения',
    text:
        'Перепланировка дает возможность расширить площадь комнат, более рационально организовать свое жилье, устроить рабочий кабинет, гардеробную, зоны отдыха и т.д.',
    image: '1-pereplanirovka_Minsk-min.jpg',
    data: IDEA_8
  },
  {
    id: 9,
    type: 'useful',
    title: 'Индукционная плита: за и против',
    text:
        'Индукционная плита - это кухонная электрическая плита, которая нагревает металлическую посуду индукционными вихревыми токами, которые создаются высокочастотным магнитным полем.',
    image: '1-indukcionnaya-pilta-min.jpg',
    data: IDEA_9
  },
  {
    id: 10,
    type: 'repairs',
    title: 'Виды обоев',
    text:
        'Рассмотрим такие виды обоев: бумажные, виниловые, флизелиновые, фотообои, тканевые, джутовые, металлизированные, жидкие, стеклообои, пробковые.',
    image: 'vidy-oboev.jpeg',
    serviceId: 11
  },
  {
    id: 11,
    type: 'repairs',
    title: 'Демонтажные работы',
    text:
        'В начале ремонта, необходимо подготовить место, на котором планируются ремонтно-отделочные работы, для этого проводят демонтажные работы.',
    image: 'demontagnye-raboty-Minsk.jpeg',
    serviceId: 0
  },
  {
    id: 12,
    type: 'repairs',
    title: 'Монтажные работы',
    text:
        'Во время ремонта в квартире, практически всегда происходит перепланировка, для изменения пространства возводят новые межкомнатные перегородки.',
    image: 'montazh-peregorodok-Minsk.jpeg',
    serviceId: 1
  },
  {
    id: 13,
    type: 'repairs',
    title: 'Сантехнические работы',
    text:
        'Система водоснабжения — это соединение запорной арматуры, труб и сантехнических приборов, при помощи которых обеспечивается подключение и подача воды.',
    image: 'santehnicheskie-raboty-v-Minske.jpg',
    serviceId: 2
  },
  {
    id: 14,
    type: 'repairs',
    title: 'Электромонтажные работы',
    text:
        'Электромонтажные работы являются комплексом строительных мероприятий, которые проводятся во время строительства или ремонта.',
    image: 'elektromontazhnye-raboty-Minsk.jpg',
    serviceId: 3
  },
  {
    id: 15,
    type: 'repairs',
    title: 'Малярно-штукатурные работы',
    text:
        'Во время малярно-штукатурных работ происходит устранение разных дефектов на потолке и стенах, а также производится подготовка поверхностей для финишной отделки.',
    image: 'malyarno-shtukaturnye-raboty-v-Minske.jpg',
    serviceId: 4
  },
  {
    id: 16,
    type: 'repairs',
    title: 'Гипсокартонные работы',
    text:
        'Гипсокартон очень популярен при выполнении ремонтных работ, потому что его легко обрабатывать, монтировать, из него можно сделать разнообразные дизайнерские конструкции.',
    image: 'gipsokartonnye-raboty-v-Minske.jpg',
    serviceId: 5
  },
  {
    id: 17,
    type: 'repairs',
    title: 'Укладка плитки',
    text:
        'Плитка является практичным, экологически безопасным и экономически эффективным материалом напольного покрытия.',
    image: 'ukladka-plitki-v-Minske.jpeg',
    serviceId: 6
  },
  {
    id: 18,
    type: 'repairs',
    title: 'Многообразие потолков',
    text:
        'Виды потолков сегодня различаются большим многообразием, что дает возможность подобрать подходящую для Вас конструкцию потолка.',
    image: 'ustroistvo-potolkov-v-Minske.jpeg',
    serviceId: 7
  },
  {
    id: 19,
    type: 'repairs',
    title: 'Покраска стен и потолков',
    text:
        'Окрашивание стен и потолка – это популярный вариант внутренней отделки стен и потолков, поскольку он помогает создать практичные, разнообразные интерьеры.',
    image: 'pokraska-sten.jpg',
    serviceId: 8
  },
  {
    id: 20,
    type: 'repairs',
    title: 'Внутренняя отделка стен',
    text:
        'Рассмотрим наиболее популярные разновидности материалов для отделки, которые применяются для декорирования стен.',
    image: 'vnutrennyay-otdelka-sten-v-Minske.jpg',
    serviceId: 10
  },
  {
    id: 21,
    type: 'repairs',
    title: 'Наружные работы',
    text:
        'Как приятно иметь загородный дом и отдыхать в нем от городского шума и суеты, однако время от времени дом нужно ремонтировать как внутри, так и снаружи.',
    image: 'naruzhnye-raboty-v-dome.jpg',
    serviceId: 12
  },
];
