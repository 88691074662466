import {
    MODAL_TOGGLE,
    CHANGE_REPAIR,
    CHANGE_OBJECT_AREA,
    CHANGE_DESIGN_PROJECT,
    CHANGE_DEADLINE,
    CHANGE_PHONE,
    CHANGE_NAME,
    CHANGE_REVIEW_NAME,
    CHANGE_REVIEW_TEXT,
    CHANGE_REVIEW_FILES,
    MODAL_TOGGLE_ACCEPTANCE_OF_APARTMETS,
    MODAL_TOGGLE_CONTACT_WITH_US,
    MODAL_TOGGLE_THANKS,
    MODAL_TOGGLE_REVIEW_THANKS,
    GET_INSTAGRAM_FOTOS,
    MODAL_TOGGLE_MOBILE_NAV
} from './actions';

const initialState = {
  showModalStartRepair: false,
  showModalAcceptanceOfApartments: false,
  showModalContactWithUs: false,
  showModalThanks: false,
  showModalReviewThanks: false,
  showMobileNav: false,
  startRepairFormData: {
    typeOfRepair: '',
    objectArea: '',
    designProject: '',
    deadline: '',
    name: '',
    phone: ''
  },
  reviewFormData: {
    name: '',
    text: '',
    files: []
  },
  instagram: null
};

const lovko = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODAL_TOGGLE:
      return {
        ...state,
        showModalStartRepair: payload
      };
    case MODAL_TOGGLE_ACCEPTANCE_OF_APARTMETS:
      return {
        ...state,
        showModalAcceptanceOfApartments: payload
      };
    case MODAL_TOGGLE_CONTACT_WITH_US:
      return {
        ...state,
        showModalContactWithUs: payload
      };
    case MODAL_TOGGLE_THANKS:
      return {
          ...state,
          showModalThanks: payload
      };
    case MODAL_TOGGLE_REVIEW_THANKS:
      return {
          ...state,
          showModalReviewThanks: payload
      };
    case CHANGE_REPAIR:
      return {
        ...state,
        startRepairFormData: {
          ...state.startRepairFormData,
          typeOfRepair: payload
        }
      };
    case CHANGE_OBJECT_AREA:
      return {
        ...state,
        startRepairFormData: {
          ...state.startRepairFormData,
          objectArea: payload
        }
      };
    case CHANGE_DESIGN_PROJECT:
      return {
        ...state,
        startRepairFormData: {
          ...state.startRepairFormData,
          designProject: payload
        }
      };
    case CHANGE_DEADLINE:
      return {
        ...state,
        startRepairFormData: {
          ...state.startRepairFormData,
          deadline: payload
        }
      };
    case CHANGE_PHONE:
      return {
        ...state,
        startRepairFormData: {
          ...state.startRepairFormData,
          phone: payload
        }
      };
    case CHANGE_NAME:
      return {
        ...state,
        startRepairFormData: {
          ...state.startRepairFormData,
          name: payload
        }
      };
    case CHANGE_REVIEW_NAME:
      return {
        ...state,
        reviewFormData: {
          ...state.reviewFormData,
          name: payload
        }
      };
    case CHANGE_REVIEW_TEXT:
      return {
        ...state,
        reviewFormData: {
          ...state.reviewFormData,
          text: payload
        }
      };
    case CHANGE_REVIEW_FILES:
      return {
        ...state,
        reviewFormData: {
          ...state.reviewFormData,
          files: payload
        }
      };
    case GET_INSTAGRAM_FOTOS:
      return {
        ...state,
        instagram: payload
      };
    case MODAL_TOGGLE_MOBILE_NAV:
      return {
        ...state,
        showMobileNav: payload
      };
    default:
      return state;
  }
};

export default lovko;
