export const IDEA_8 = [
  {
    type: 'idea_with_img',
    img: '1-pereplanirovka_Minsk.jpg'
  },
  {
    type: 'idea_with_line',
    text:
      'Покупая квартиру, хочется максимально обустроить ее под себя. Перепланировка дает возможность расширить площадь комнат, более рационально организовать свое жилье, устроить рабочий кабинет, гардеробную, зоны отдыха и т.д.'
  },
  {
    type: 'idea_with_list',
    title: 'Итак, какие же работы относятся к перепланировке:',
    list: [
      'первое, что меняют при перепланировке – это количество и площадь комнат за счет демонтажа старых перегородок и возведения новых. Это касается как жилых помещений, так и подсобных, изолированных нежилых.',
      'довольно часто в квартирах меняют коммуникации: устанавливают, переносят, демонтируют, меняют системы водоснабжения (горячего и холодного), отопления, канализации, электро- и газоснабжения, мусоро- и газоудаления, вентиляции, включая электрическое, отопительное, санитарно-техническое, газовое оборудование и отопительные приборы,',
      'также в квартирах проводят работы по паро-, тепло-, звуко- и гидроизоляции,',
      'изменение конструкции пола и несущих конструкций,',
      'перенос полотенцесушителя,',
      'увеличение и устройство новых проемов в перегородках.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ЧТО РАЗРЕШАЕТСЯ ПРИ ПЕРЕПЛАНИРОВКЕ КВАРТИРЫ',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'В соответствии с законодательством Республики Беларусь, при перепланировке квартир разрешается без согласования и не нужен проект на следующие работы:',
    list: [
      'можно смело делать установку (замену) межкомнатных дверей в пределах существующего дверного проема (в том числе изменение направления открывания дверей),',
      'разрешена замена и установка входных дверей в жилое помещение в пределах существующего дверного проема без изменения направления их открывания,',
      'не один вид работ по «обустройству» потолка не потребует от Вас согласования – натяжной, подвесной, окрашенный, оклеенный, побеленный,',
      'можно демонтировать и делать новые шкафы встроенные,',
      'разрешена замена электротехнических изделий (розетки, выключатели, светильники),',
      'можно менять сантехническое оборудование (унитазы, ванны, умывальники, мойки, биде),',
      'разрешена установка вентиляционных приборов (не распространяется на кондиционеры),',
      'можно делать демонтаж ванны и устанавливать душевую кабину,',
      'перенос существующей и (или) установка дополнительной водоразборной арматуры,',
      'можно менять трубопроводы холодного и горячего водоснабжения,',
      'разрешена замена канализации, находящейся в жилых и нежилых помещениях, без изменения месторасположения санитарно-технического оборудования.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '2-pereplanirovka_lovkoremont_Minsk.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'РАБОТЫ, НА КОТОРЫЕ НУЖНО РАЗЕРЕШЕНИЕ, НО НЕ НУЖЕН ПРОЕКТ',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'В нашей стране необходимо получить разрешение администрации (правда не нужно делать проект) чтобы:',
    list: [
      'изменить количество и площадь жилых комнат и подсобных помещений путем демонтажа/монтажа перегородок,',
      'это касается и нежилых помещений – изменения площади и количества помещений за счет манипуляций с перегородками требуют согласования,',
      'если Вы хотите сделать новые проемы в перегородках или увеличить существующие, то Вам нужно будет получить разрешение на эти работы. Если же в местах где планируется делать проемы есть электропроводка, то нужно будет еще и разработать проект по перепланировке и переустройству.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'РАБОТЫ, КОТОРЫЕ НЕ ЯВЛЯЮТСЯ РАБОТАМИ ПО ПЕРЕПЛАНИРОВКЕ, НО НУЖНО СОГЛАСОВАНИЕ',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'Не являются работами по перепланировке, но можно выполнять по согласованию с регламентирующими организациями следующие работы:',
    list: [
      'устанавливать индивидуальные приборы учета воды и менять полотенцесушители в многоквартирных жилых домах можно после согласования с организацией, которая осуществляет эксплуатацию жилищного фонда или предоставляет жилищно-коммунальные услуги,',
      'менять газоиспользующее оборудование (газовую плиту, котел, газовые колонки) в помещении, в котором они установлены, не нарушая первоначальный проект на газоснабжение, плюс устанавливать индивидуальные приборы учета газа – согласование с газоснабжающей организацией,',
      'менять заполнение дверных и оконных проемов фасадов многоквартирных жилых домов – согласовывается с структурным подразделением местного исполнительного и распорядительного органа, имеющим полномочия в области архитектурной, градостроительной, строительной деятельности),',
      'менять направление открывания входных дверей в жилое помещение (в многоэтажках) – требуется согласование с организацией, которая осуществляет эксплуатацию жилищного фонда и предоставляет жилищно-коммунальные услуги. Обращаем Ваше внимание, что в некоторых исполкомах дополнительно запрашивают согласие соседей (радиус открывания вашей входной двери не должен пересекаться с радиусом открывания соседской двери),',
      'Часто в многоквартирных жилых домах хотят сделать неотапливаемые помещения посредством остекления балконов и лоджий – такие работы нужно согласовывать с территориальным подразделением архитектуры и градостроительства и организацией, которая осуществляет эксплуатацию жилищного фонда и предоставляет жилищно-коммунальные услуги.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '3-proekt-pereplanirovki.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'РАБОТЫ, НА КОТОРЫЕ НУЖНЫ РАЗЕРЕШЕНИЕ И РАЗРАБОТКА ПРОЕКТНОЙ ДОКУМЕНТАЦИИ',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'Мы подошли к работам, требования, к которым более строгие. На эти работы нужно как разрешение администрации города, так и нужно будет разработать проектную документацию:',
    list: [
      'произвести изменения в несущих конструкциях здания возможно, но это потребует разработки проектной документации, соблюдения расчетно-проектных норм и согласования в контролирующей организации,',
      'требует согласования и проекта увеличение за счет коридора санузла, ',
      'Вам потребуется проект и согласование, если Вы решите демонтировать, установить, заменить или перенести системы горячего и холодного водо-, электро- и газоснабжения, отопления, вентиляции, канализации, мусоро- и газоудаления, это касается и электрического, сантехнического, отопительного, газового оборудования и отопительных приборов,',
      'внесение изменений и устройство новой гидро-, паро, тепло- и звукоизоляции потребуют согласования и проекта,',
      'устройство новых и увеличение существующих проемов в стенах и перегородках,',
      'внесение изменений в конструкцию пола,',
      'и наиболее часто производимый – перенос полотенцесушителя также требует согласования и проекта, ниже более подробно расскажем почему.'
    ]
  },
  {
    type: 'idea_with_text',
    text: [
      'Рассмотрим более подробно некоторые моменты.'
    ]
  },
  {
    type: 'idea_with_text',
    text: [
      'В ходе проведения перепланировки разрешается переносить и менять полотенцесушитель (он же змеевик), однако на эти работы нужно разработку проекта. Причиной этому служит то, что диаметр трубы змеевика должен быть идентичен тому, который предусмотрен проектом дома. На сегодняшний день достаточно много красивых, дизайнерских полотенцесушителей привозят из-за границы, и они не рассчитаны под давление в системах наших домов и для его монтажа нужно уменьшить диаметр трубы. В этом случае проектом необходимо предусмотреть специальную перемычку (байпас), которая позволит работать системе без снижения давления. Очень часто монтаж современных змеевиков проводят с нарушением и это влечет за собой сбои в функционировании соответствующей коммуникационной системы всего дома.',
      'Обязательной разработки проекта требует и замена радиаторов отопления. Если же новые приборы отопления не сертифицированы на территории РБ, то такую перепланировку узаконить не получится. Обращаем Ваше внимание, что для разработки проекта Вам понадобится узнать точное рабочее давление в системе (эту информацию можно уточнить у главного инженера ЖЭСа или товарищества).',
      'При замене напольного покрытия в жилых помещениях обязательно нужно укладывать слой звукоизоляционного материала. Это послужило причиной почему данный вид работ требует обязательного согласования и проектирования. Если в ходе ремонта не положить звукоизоляционный материал, то такую перепланировку не согласуют.',
      'Очень часто в ходе перепланировки увеличивают санузел за счет площади коридора (или других нежилых помещений) и площади жилых комнат. В ходе выполнения таких работ необходимо обязательно сделать гидроизоляционный слой на всей площади санузла. И еще очень важно знать, что для того, чтобы утвердить впоследствии самовольную перепланировку квартиры, увеличивать санузел за счет площади жилых комнат можно не более чем на 25 процентов. Для того, чтобы посчитать возможную площадь увеличения санузла за счет площади жилой комнаты (при объединении ванной и туалета и части коридора), в первую очередь нужно сложить площади ванной комнаты и туалета, к этой площади добавить площадь перегородки между ними, площадь коридора и площадь демонтируемого участка стены жилой комнаты. Затем разделить полученную площадь на четыре, в результате чего Вы получите ту цифру, превышать которую нельзя. Если же вы превысите эти 25 процентов, то Вам не согласуют такую перепланировку.',
      'В современных квартирах часто объединяют кухню с жилой комнатой. Обращаем Ваше внимание, что вход в санузел со стороны кухни запрещен. Однако, если объединение кухни и жилой комнаты назвать «жилая комната с кухонных оборудованием», то можно будет узаконить такую перепланировку, в случае соблюдения других норм СНБ.'
    ]
  },
  {
    type: 'idea_with_img',
    img: '4-pereplanirovka.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ЧТО ЗАПРЕЩЕНО ПРИ ПЕРЕПЛАНИРОВКЕ КВАРТИРЫ',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'Сейчас рассмотрим, что же категорически запрещено при проведении перепланировки квартиры:',
    list: [
      'запрещено размещать кухни под и над жилыми комнатами,',
      'что касается санузла, то нельзя увеличивать санузел за счет уменьшения площади кухни, и нельзя размещать санузел на месте жилой комнаты,',
      'к сожалению, по нашему законодательству запрещено переустройство лоджий и балконов в отапливаемые помещения, а также присоединение лоджий и балконов к отапливаемому помещению,',
      'изменение архитектурного и цветового решения фасада многоквартирного дома,',
      'запрещено делать жилые комнаты без естественного освещения и монтировать перегородки, которые попадают в оконные проемы,',
      'запрещено проводить работы, которые нарушают строительные, санитарно-эпидемиологические, противопожарные требования, нарушают законодательство об охране историко-культурного наследия, градостроительной, строительной и архитектурной деятельности,',
      'работы, которые снижают несущую способность грунтов оснований фундаментов, конструкций, колонн, стен, простенков, балок, конструкций крыш и кровель, перекрытий и опор, а также работ, которые нарушают гидро-, паро-, звуко-, теплоизоляцию, био- и огнестойкость ограждающих и несущих конструкций,',
      'любые работы, которые приводят к снижению эксплуатационной пригодности конструкций жилого дома, его технической долговечности и сохранности отдельных помещений многоквартирного дома,',
      'еще одним важным моментов является то, что запрещено переустройство вентиляционных шахт и каналов (их уменьшение, демонтаж и другие дествия),',
      'запрещено проводить работы, которые нарушают работу систем газо- и дымоудаления, ухудшают параметры работы инженерно-технического оборудования дома и его отдельных помещений,',
      'нельзя устанавливать дополнительное оборудование центрального отопления, горячего водоснабжения, электротехнического оборудования, если это может привести к превышению проектных инженерных нагрузок на одно помещение,',
      'нельзя проводить никакие работы в доме, если он в установленном порядке признан не соответствующим для проживания и не подлежит восстановлению.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'ПОРЯДОК ПОЛУЧЕНИЯ СОГЛАСОВАНИЯ (РАЗРЕШЕНИЯ) НА ПЕРЕПЛАНИРОВКУ',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'Чтобы получить согласование перепланировки, нужно подать в местный исполнительный и распорядительный орган:',
    list: [
      'заявление',
      'паспорт или иной документ, удостоверяющий личность',
      'письменное согласие всех членов семьи, которые достигли совершеннолетия, а также иных граждан, которые проживают в этой квартире и имеют право владения и пользования, плюс письменное (удостоверенное нотариально) согласие отсутствующих граждан, которые имеют право владения и пользования помещением,',
      'технический паспорт квартиры, а также документ, который подтверждает право собственности на помещение (это касается собственника помещения),',
      'перечень (описание) работ по переустройству / перепланировке помещения, который можно составлять в любой свободной форме,',
      'письменное согласие организации застройщиков в жилых домах этой организации - для члена организации застройщиков, не являющегося собственником помещения.'
    ]
  },
  {
    type: 'idea_with_text',
    text: [
      'Обращаем внимание, что нужно будет предоставить доступ в жилое помещение представителям организации, которая осуществляет эксплуатацию жилищного фонда или предоставляющей жилищно-коммунальные услуги, и местного исполнительного и распорядительного органа.',
      'После осмотра жилого помещения составляется акт технического осмотра, в котором будут указаны сведения о соответствии / несоответствии существующей планировки техническому паспорту жилья, плюс о техническом состоянии конструкций и инженерного оборудования помещения. Этот акт составляется в произвольной форме, подписывается инициатором (т.е. Вами) и уполномоченным должностным лицом и утверждается представителем местного исполнительного и распорядительного органа.',
      'На основании документов, которые предоставил инициатор (Вы), актов технического осмотра жилого помещения местных исполнительный и распорядительный орган (в течении месяца) принимает решение о согласовании перепланировки либо об отказе в согласовании, при этом должен быть указан мотив отказа и сообщено об этом инициатору.'
    ]
  },
  {
    type: 'idea_with_list',
    title: 'Что может быть основанием для отказа в согласовании перепланировки:',
    list: [
      'инициатор может предоставить документы, содержащие недостоверные сведения,',
      'могут быть угрозы обрушения конструкции жилого помещения, в ходе проведения перепланировки,',
      'работы, которые запрещены для перепланировки законодательством.'
    ]
  },
  {
    type: 'idea_with_quote',
    text: 'Инициатор может обжаловать отказ в согласовании перепланировки в вышестоящий орган или в суд.'
  },
  {
    type: 'idea_with_img',
    img: '5-proekt-pereplanirovki.jpg'
  },
  {
    type: 'idea_with_title',
    title: 'ПРОЕКТ ПЕРЕПЛАНИРОВКИ',
    text: [
      'Часто путают дизайн-проект и строительный проект перепланировки квартиры.',
      'Дизайн-проект – это общая концепция интерьера квартиры, в котором отражается как будет располагаться мебель, техника, розетки, выключатели, какое будет покрытие пола и всего другого, что будет располагаться в квартире.',
      'Чем же дизайн-проект отличается от строительного проекта? А тем, что в строительном проекте перепланировки квартиры отражаются строительные решения по перепланировке квартиры и изменению ее инженерных сетей. В нем проектируется демонтаж старых и возведение новых перегородок с отражением узлов примыкания перегородок к стенам, разводка систем водо-, электроснабжения, устройство полов, монтаж металлических конструкций с расчетом нагрузок, проводятся расчеты радиаторов отопления (если планируется поменять) и т.д. Он разрабатывается специализированными организациями, имеющими право на осуществление такой деятельности.'
    ]
  },
  {
    type: 'idea_with_title',
    title: 'САМОВОЛЬНАЯ ПЕРЕПЛАНИРОВКА',
    text: []
  },
  {
    type: 'idea_with_list',
    title: 'Самовольная (или незаконная) перепланировка – перепланировка, выполненная:',
    list: [
      'без разрешения исполкома на проектирование и проведение строительных работ;',
      'с разрешения исполкома, но без разработки и согласования проекта;',
      'если прошло более 2-х лет после получения разрешения и создания проекта;',
      'с нарушением согласованного проекта.'
    ]
  },
  {
    type: 'idea_with_quote',
    text: 'Самовольная перепланировка запрещена.'
  },
  {
    type: 'idea_with_title',
    title: 'КАК ЖЕ УЗАКОНИТЬ САМОВОЛЬНУЮ ПЕРЕПЛАНИРОВКУ',
    text: [
      'Если перепланировка все же произведена, но согласования нет, тогда можно пойти и согласовать самовольную перепланировку. ',
      'Согласование самовольной перепланировки может быть получено, если изменения, которые произведены в квартире не влияют на безопасность жилого дома и соответствуют требованиям технических нормативных правовых актов.',
      'Если же вдруг Вам не согласуют самовольную перепланировку, то нужно будет в установленный распорядительными органами срок привести квартиру в прежнее состояние. ',
      'Информация приведена по состоянию на август 2019 года.',
      'И в завершение: перепланировка и интерьерное решение должна соответствовать Вашим потребностях и нравится Вам.'
    ]
  }
];
