import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/Button';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { toggleModal, toggleMobileNav } from '../actions';
import Sticky from 'react-stickynode';
import { R } from '../Routes';
import { url_slug } from 'cyrillic-slug';
import { MAIN_PAGE_SERVICES_CONTENT, SERVICES_TYPE } from '../lovkoContent/services/services';

const Header = props => {
  return (
    <Sticky enabled={true} innerZ={3} activeClass="active-sticky-header">
      <section className="header">
        <Container>
          <Row>
            <Col
              xs={12}
              md="auto"
              className="d-flex flex-column justify-content-end header-top-wrapper"
            >
              <div className="header-logo img-container">
                <Link to="/">
                  <img src={process.env.PUBLIC_URL + '/assets/images/logo_header.png'} alt="header-logo" />
                </Link>
              </div>
              <div
                className="header-top-wrapper-mobile-nav"
                onClick={() => props.toggleMobileNav(true)}
              >
                <img src={process.env.PUBLIC_URL + '/assets/images/menu-button.svg'} alt="mobile-nav" />
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-center h-100">
                <div className="header-main-wrapper">
                  <p className="mb-0 text-right text-gray line-height-1 ">
                    работаем в Минске и Минской области
                  </p>
                  <h1 className="text-size-30 mb-1 text-gray text-right line-height-small ">
                    Ремонт квартир, коттеджей, офисов
                  </h1>
                  <nav className="header-nav">
                    <ul className="header-nav-list box-shadow-half">
                      <li>
                        <Link to={R.projects}>реализованные проекты</Link>
                      </li>
                      <li className="header-nav-list-with-nav">
                        <Link
                          to={{
                            pathname: R.services,
                            hash: '#root',
                            state: {
                              servicesType: 'renovationOfApartments',
                              servicesTypeSecondary: undefined
                            }
                          }}
                        >
                          услуги
                        </Link>
                        <ul className="header-nav-list-secondary">
                          <li>
                            <Link
                              to={{
                                pathname: R.services,
                                hash: '#root',
                                state: {
                                  servicesType: 'acceptanceOfApartments',
                                  servicesTypeSecondary: undefined
                                }
                              }}
                            >
                              приемка квартиры
                            </Link>
                          </li>
                          <li className="header-nav-list-secondary-menu">
                            <Link
                              to={{
                                pathname: R.services,
                                hash: '#root',
                                state: {
                                  servicesType: 'renovationOfApartments',
                                  servicesTypeSecondary: undefined
                                }
                              }}
                            >
                              ремонт квартир
                            </Link>
                            <ul className="header-nav-list-secondary-menu-list">
                              {MAIN_PAGE_SERVICES_CONTENT
                                .filter(e => e.type === SERVICES_TYPE.R_K)
                                .map(i => (
                                  <li key={url_slug(i.navtext)}>
                                    <Link
                                      to={`${
                                        R.services
                                        }/${url_slug(
                                          i.title.toLowerCase(),
                                          '_'
                                        )}`}
                                    >
                                      {i.navtext}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </li>
                          <li className="header-nav-list-secondary-menu">
                            <Link
                              to={{
                                pathname: R.services,
                                hash: '#root',
                                state: {
                                  servicesType: 'renovationOfСottage',
                                  servicesTypeSecondary: undefined
                                }
                              }}
                            >
                              ремонт коттеджей
                            </Link>
                            <ul className="header-nav-list-secondary-menu-list">
                              {MAIN_PAGE_SERVICES_CONTENT
                                .filter(e => e.type === SERVICES_TYPE.R_C)
                                .map(i => (
                                  <li key={url_slug(i.navtext)} >
                                    <Link
                                      to={`${
                                        R.services
                                        }/${url_slug(
                                          i.title.toLowerCase(),
                                          '_'
                                        )}`}
                                    >
                                      {i.navtext}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </li>
                          <li className="header-nav-list-secondary-menu">
                            <Link
                              to={{
                                pathname: R.services,
                                hash: '#root',
                                state: {
                                  servicesType: 'acceptanceOfOffice',
                                  servicesTypeSecondary: undefined
                                }
                              }}
                            >
                              ремонт офисов
                            </Link>
                            <ul className="header-nav-list-secondary-menu-list">
                              {MAIN_PAGE_SERVICES_CONTENT
                                .filter(e => e.type === SERVICES_TYPE.R_O)
                                .map(i => (
                                  <li key={url_slug(i.navtext)}>
                                    <Link
                                      to={`${
                                        R.services
                                        }/${url_slug(
                                          i.title.toLowerCase(),
                                          '_'
                                        )}`}
                                    >
                                      {i.navtext}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: R.prices,
                            state: { toElem: 'bar' }
                          }}
                        >
                          стоимость ремонта
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${R.prices}${R.promotions}`}
                          scroll={el =>
                            el.scrollIntoView({
                              behavior: 'instant',
                              block: 'center'
                            })
                          }
                        >
                          акции
                        </Link>
                      </li>
                      <li className="header-nav-list-with-nav">
                        <Link to={`${R.about}#root`}>о комании</Link>
                        <ul className="header-nav-list-secondary">
                          <li>
                            <Link to={`${R.about}#root`}>о комании</Link>
                          </li>
                          <li>
                            <Link
                              to={`${R.about}#komanda`}
                              scroll={el =>
                                el.scrollIntoView({
                                  behavior: 'instant',
                                  block: 'center'
                                })
                              }
                            >
                              команда
                            </Link>
                          </li>
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to={`${R.about}#certificates`}*/}
                          {/*    scroll={el =>*/}
                          {/*      el.scrollIntoView({*/}
                          {/*        behavior: 'instant',*/}
                          {/*        block: 'center'*/}
                          {/*      })*/}
                          {/*    }*/}
                          {/*  >*/}
                          {/*    сертификаты*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          <li>
                            <Link
                              to={`${R.about}#otzyvy`}
                              scroll={el =>
                                el.scrollIntoView({
                                  behavior: 'instant',
                                  block: 'center'
                                })
                              }
                            >
                              отзывы
                            </Link>
                          </li>
                          <li>
                            <Link to={`${R.about}#kontakty`}>контакты</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              md="auto"
              className="d-flex flex-column hide-on-mobile"
              style={{ zIndex: 0 }}
            >
              <div>
                <a className="phone-link" href="tel:+375295610288">
                  +375 (29) 561 02 88
                </a>
              </div>
              <Button
                blue
                boxshadow="half"
                size="small"
                className=" pt-pb-button-header mt-1"
                onClick={() => props.toggleModal(true)}
              >
                НАЧАТЬ РЕМОНТ
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Sticky>
  );
};

const mapDispachToProps = dispatch => ({
  toggleModal: boolean => {
    dispatch(toggleModal(boolean));
  },
  toggleMobileNav: boolean => {
    dispatch(toggleMobileNav(boolean));
  }
});

export default connect(
  null,
  mapDispachToProps
)(Header);
