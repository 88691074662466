import { ServicesElems as S } from './constants';

export const SANTECH = [
  {
    type: S.withImg,
    img: 'santeh-1.jpg'
  },
  {
    type: S.withLine,
    text:
      'Ремонт начинается с прокладывания водопроводных и канализационных труб. Система водоснабжения — это соединение запорной арматуры, труб и сантехнических приборов, при помощи которых обеспечивается подключение и подача воды. '
  },
  {
    type: S.withList,
    title:
      'Наша компания выполняет сантехнические работы под ключ. Комплексный ремонт квартиры включает в себя следующие сантехнические работы:',
    list: [
      'монтаж канализации;',
      'монтаж водопровода;',
      'установка унитаза;',
      'установка змеевика;',
      'перенос счетчиков;',
      'замена батарей и труб;',
      'установка инсталляции;',
      'установка ванны;',
      'установка бойлера:',
      'подключение посудомоечной машины;',
      'монтаж трапа;',
      'подключение стиралки;'
    ]
  },
  {
    type: S.withTitle,
    text:
      'Планируете провести ремонт и замену сантехники в квартире? Наши квалифицированные специалисты помогут Вам в этом и проведут сантехнические работы в Минске используя современное оборудование и технологии. Примеры наших работ: '
  },
  {
    type: S.withImg,
    img: 'santeh-2.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-3.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-4.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-5.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-6.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-7.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-8.jpg'
  },
  {
    type: S.withImg,
    img: 'santeh-9.jpg'
  },
  {
    type: S.withImg,
    img: 'santeh-10.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-11.jpeg'
  },
  {
    type: S.withImg,
    img: 'santeh-12.jpg'
  },
  {
    type: S.withImg,
    img: 'santeh-13.jpg'
  }
];
